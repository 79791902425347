import { defineStyleConfig } from "@chakra-ui/react"

const theme = defineStyleConfig({
  baseStyle: {
    color: "navy.400",
    fontWeight: 600,
  },
  sizes: {
    xs: {
      fontSize: "12px",
    },
    sm: {
      fontSize: "14px",
    },
    md: {
      fontSize: "16px",
    },
    lg: {
      fontSize: "18px",
    },
    xl: {
      fontSize: "20px",
    },
    "2xl": {
      fontSize: "24px",
    },
    "3xl": {
      fontSize: "30px",
    },
    "4xl": {
      fontSize: "36px",
    },
    "5xl": {
      fontSize: "48px",
    },
    "6xl": {
      fontSize: "60px",
    },
  },
})

export default theme
