// TODO : keys case

export const QuestionTypes = {
  FreeText: "FreeText",
  SingleSelect: "SingleSelect",
  MultiSelect: "MultiSelect",
  Number: "Number",
  Date: "Date",
} as const

export const QuestionnairesNames = {
  abdominalAorticAneurysm: "abdominalAorticAneurysm",
  breastCancer: "breastCancer",
  cervicalCancer: "cervicalCancer",
  colorectalCancer: "colorectalCancer",
  diabetes: "diabetes",
  b9Supplements: "b9Supplements",
  dyslipidemia: "dyslipidemia",
  nicotineSubstitution: "nicotineSubstitution",
  score2: "score2",
  sti: "sti",
} as const

export type QuestionnaireName = keyof typeof QuestionnairesNames

export enum YesNo {
  YES = "YES",
  NO = "NO",
}

export enum YesNoOther {
  YES = "YES",
  NO = "NO",
  OTHER = "OTHER",
}

export enum AbdominalAorticAneurysmCriteriaOption {
  SMOKER = "SMOKER",
  FAMILY_HISTORY = "FAMILY_HISTORY",
  DOESNT_KNOW = "DOESNT_KNOW",
  NONE = "NONE",
}

export enum BiologicalSexOption {
  MALE = "MALE",
  FEMALE = "FEMALE",
}

export enum B9SupplementsCurrentSituationOption {
  SPINA_BIFIDA = "SPINA_BIFIDA",
  SODIUM_VALPROATE = "SODIUM_VALPROATE",
  NONE = "NONE",
  DOESNT_KNOW = "DOESNT_KNOW",
}

export enum BreastCancerCurrentSituationOption {
  PERSONAL_HISTORY = "PERSONAL_HISTORY",
  FAMILY_HISTORY = "FAMILY_HISTORY",
  GENES_ALTERATION = "GENES_ALTERATION",
  RADIATION_SENSITIVITY = "RADIATION_SENSITIVITY",
  NONE = "NONE",
}

export enum CervicalCancerLastCheckOption {
  NEVER = "NEVER",
  LESS_THAN_A_YEAR = "LESS_THAN_A_YEAR",
  BETWEEN_1_AND_3_YEARS = "BETWEEN_1_AND_3_YEARS",
  BETWEEN_3_AND_5_YEARS = "BETWEEN_3_AND_5_YEARS",
  MORE_THAN_5_YEARS = "MORE_THAN_5_YEARS",
}

export enum CervicalCancerCheckTypeOption {
  CYTOLOGY = "CYTOLOGY",
  HPV = "HPV",
}

export enum CervicalCancerCurrentSituationOption {
  FUNCTIONAL_SIGNS = "FUNCTIONAL_SIGNS",
  CLINICAL_SIGNS = "CLINICAL_SIGNS",
  LESION_ANTECEDENTS = "LESION_ANTECEDENTS",
  HYSTERECTOMY = "HYSTERECTOMY",
  PREGNANCY = "PREGNANCY",
  NONE = "NONE",
}

export enum ColorectalCancerCurrentSituationOption {
  BLOOD_IN_STOOL = "BLOOD_IN_STOOL",
  ABDOMINAL_PAIN = "ABDOMINAL_PAIN",
  TRANSIT_DISORDER = "TRANSIT_DISORDER",
  PERSONAL_HISTORY = "PERSONAL_HISTORY",
  PARENT_HISTORY = "PARENT_HISTORY",
  FAMILY_HISTORY = "FAMILY_HISTORY",
  NONE = "NONE",
}

export enum DiabetesRiskFactorOption {
  ORIGIN = "ORIGIN",
  SEDENTARITY = "SEDENTARITY",
  PERSONAL_HISTORY = "PERSONAL_HISTORY",
  FAMILY_HISTORY = "FAMILY_HISTORY",
  TREATEMENT = "TREATEMENT",
  HYPERTENSION = "HYPERTENSION",
  DYSLIPIDEMIA = "DYSLIPIDEMIA",
  NONE = "NONE",
}

export enum DyslipidemiaDiagnosedChronicPathologyOption {
  CARDIOVASCULAR = "CARDIOVASCULAR",
  METABOLIC = "METABOLIC",
  CHRONIC_RENAL_FAILURE = "CHRONIC_RENAL_FAILURE",
  INFLAMMATORY = "INFLAMMATORY",
  NONE = "NONE",
}

export enum STICurrentSituationOption {
  RECENT_STI = "RECENT_STI",
  MULTIPLE_PARTNERS = "MULTIPLE_PARTNERS",
  MAN_TO_MAN_RELATIONS = "MAN_TO_MAN_RELATIONS",
  SEX_WORKER = "SEX_WORKER",
  DRUGS = "DRUGS",
  HEPATITE_C_RELATIVE = "HEPATITE_C_RELATIVE",
  NONE = "NONE",
}

export enum NicotineSubstitutionTimeToFirstCigaretteOptions {
  LESS_THAN_5_MINUTES = "LESS_THAN_5_MINUTES",
  SIX_TO_THIRTY_MINUTES = "SIX_TO_THIRTY_MINUTES",
  THIRTY_ONE_TO_SIXTY_MINUTES = "THIRTY_ONE_TO_SIXTY_MINUTES",
  MORE_THAN_SIXTY_MINUTES = "MORE_THAN_SIXTY_MINUTES",
}

export enum NicotineSubstitutionCigarettesPerDayOptions {
  LESS_THAN_10 = "LESS_THAN_10",
  TEN_TO_20 = "TEN_TO_20",
  TWENTY_TO_30 = "TWENTY_TO_30",
  MORE_THAN_30 = "MORE_THAN_30",
}

export enum STIReasonOption {
  SYMPTOMS = "SYMPTOMS",
  PARTNER_HAS_SYMPTOMS = "PARTNER_HAS_SYMPTOMS",
  PARTNER_IS_POSITIVE = "PARTNER_IS_POSITIVE",
  ROUTINE = "ROUTINE",
  RISKY_BEHEAVIOR = "RISKY_BEHEAVIOR",
  POST_TREATMENT = "POST_TREATMENT",
}

export enum STISymptomsOption {
  SKIN_OR_MUCOUS_ERUPTION = "SKIN_OR_MUCOUS_ERUPTION",
  URINARY_BURNING_OR_LEAKS = "URINARY_BURNING_OR_LEAKS",
  FEVER = "FEVER",
  DISCHARGE = "DISCHARGE",
  OTHER = "OTHER",
}

export enum STIGermOption {
  CHLAMYDIA = "CHLAMYDIA",
  GONOCOQUE = "GONOCOQUE",
  MYCOPLASME = "MYCOPLASME",
  SYPHILIS = "SYPHILIS",
  OTHER = "OTHER",
}

export enum STIDetectionMethodsOption {
  VAGINAL_SWAB = "VAGINAL_SWAB",
  URINE_SAMPLE = "URINE_SAMPLE",
  THROAT_SWAB = "THROAT_SWAB",
  RECTAL_SWAB = "RECTAL_SWAB",
}
