export enum UserRole {
  USER = "USER",
  DOCTOR = "DOCTOR",
  ADMIN = "ADMIN",
}

export type User = {
  id: string
  clerkId: string
  emailAddress: string
  phoneNumber: string
  firstName: string
  lastName: string
  fullName: string
  role: UserRole
  avatarUrl: string | undefined
  sealdId: string | undefined
  sealdTwoManRuleKey: string
  sealdDatabaseKey: string
}
