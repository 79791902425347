import { Link as ChakraLink, Checkbox, Text, VStack } from "@chakra-ui/react"
import { useUser } from "@clerk/clerk-react"
import { useForm, zodResolver } from "@mantine/form"
import { Link } from "react-router-dom"
import { z } from "zod"

import {
  BiologicalSexOption,
  profileQuestionnaire,
  questionnaires,
  type ProfileData,
} from "@bleu/shared/questionnaires"

import { Question } from "@bleu/front/components/funnelSteps/Questionnaires/Questionnaire"
import QuestionnaireLayout from "@bleu/front/components/layout/QuestionnaireLayout"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { useProfileStore } from "@bleu/front/lib/stores"
import type { StepProps } from "@bleu/front/pages/types"

interface Props extends StepProps {
  profile?: Partial<ProfileData>
  onSubmit: (profile: ProfileData, acceptsMarketing: boolean) => void
}

export const ProfileQuestionnaire = ({
  profile,
  onSubmit,
  onNext,
  onBack: handleBack,
}: Props) => {
  const { user } = useUser()
  const { questions } = questionnaires.profile
  const title = { text: "Complétez votre profil", highlight: "votre profil" }
  const { setProfile } = useProfileStore()

  const form = useForm<{
    profile: ProfileData
    cgu: boolean
    acceptsMarketing: boolean
  }>({
    initialValues: {
      // @ts-expect-error TODO FIXME: Cannot manage to make it compile
      profile: {
        ...profileQuestionnaire.initialValues,
        ...(profile as Partial<ProfileData>),
        biologicalSex: profile?.biologicalSex ?? BiologicalSexOption.FEMALE,
      },
      ...(!user && { cgu: false, acceptsMarketing: false }),
    },
    validate: zodResolver(
      z.object({
        profile: profileQuestionnaire.schema,
        ...(!user && { cgu: z.literal(true), acceptsMarketing: z.boolean() }),
      }),
    ),
    validateInputOnChange: ["profile.age", "profile.weight", "profile.height"],
    onValuesChange: (values) => {
      setProfile(values.profile)
    },
  })

  const isDisabled = !form.isValid()

  const handleClick = () => {
    const profile = profileQuestionnaire.schema.parse(form.values.profile)
    setProfile(profile)
    onSubmit(profile, form.values.acceptsMarketing)
    onNext()
  }

  return (
    <>
      <QuestionnaireLayout.Header
        pageName="Profil"
        onBack={handleBack}
        progress={40}
      />
      <QuestionnaireLayout.Title title={title} />
      <VSpacer size={8} />
      <VStack gap={8} alignItems="stretch">
        {questions.map((question) => {
          // @ts-expect-error profile is not expected to bedefined on profileQuestionnaire
          if (!question.shouldShow(form.values.profile, undefined)) {
            return null
          }
          return (
            <Question
              key={question.id}
              question={question}
              questionnaireName="profile"
              // @ts-expect-error TODO FIXME: Cannot manage to make it compile
              form={form}
            />
          )
        })}
      </VStack>
      <VSpacer size={8} />
      {!user && (
        <>
          <Checkbox
            alignItems="start"
            value={form.getInputProps("cgu").value}
            onChange={(e) =>
              form.getInputProps("cgu").onChange(e.target.checked)
            }
          >
            <Text marginTop={-1}>
              En continuant, vous acceptez que vos données personnelles soient
              utilisées pour votre prise en charge médicale, conformment à notre{" "}
              <ChakraLink
                as={Link}
                to="https://www.bleu.care/legal/rgpd"
                target="_blank"
              >
                Politique de confidentialité des données
              </ChakraLink>{" "}
              et nos{" "}
              <ChakraLink
                as={Link}
                to="https://www.bleu.care/legal/cgu"
                target="_blank"
              >
                Conditions générales d&apos;utilisation
              </ChakraLink>
              .*
            </Text>
          </Checkbox>
          <VSpacer size={4} />
          <Checkbox
            alignItems="start"
            isChecked={form.getInputProps("acceptsMarketing").value}
            onChange={(e) =>
              form.getInputProps("acceptsMarketing").onChange(e.target.checked)
            }
          >
            <Text marginTop={-1}>
              J&apos;accepte de recevoir d&apos;autres communications de Bleu
              (rappels de dépistages, recommandations, etc.)
            </Text>
          </Checkbox>
        </>
      )}
      <VSpacer size={4} />
      <Text color="navy.300" fontWeight={600}>
        Bleu n&apos;est pas adapté à la prise en charge de situations
        d&apos;urgence vitale (en cas d&apos;urgence, appelez le 15), ni au
        suivi des pathologies chroniques.
      </Text>
      <QuestionnaireLayout.CTA onClick={handleClick} isDisabled={isDisabled}>
        Continuer
      </QuestionnaireLayout.CTA>
    </>
  )
}
