import { Text, VStack } from "@chakra-ui/react"
import * as Sentry from "@sentry/react"
import { useNavigate, useParams } from "react-router-dom"

import type { MedicalRecordResponses } from "@bleu/types/endpoints"

import { MedicalRecordStatuses } from "@bleu/shared/medicalRecords/statuses"

import { ConversationChat } from "@bleu/front/components/ConversationChat"
import { PlaceholderChatInterface } from "@bleu/front/components/PlaceholderChat"
import PatientHomeLayout from "@bleu/front/components/layout/PatientHomeLayout"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { ConversationProvider } from "@bleu/front/components/seald/ConversationProvider"
import { useMedicalRecordPageDataQuery } from "@bleu/front/queries/medicalRecords"

export const ConversationPage = () => {
  const { medicalRecordId } = useParams()
  if (!medicalRecordId) {
    const error = new Error("No medical record id provided")
    Sentry.captureException(error)
    throw error
  }

  const medicalRecordPageDataQuery =
    useMedicalRecordPageDataQuery(medicalRecordId)

  if (medicalRecordPageDataQuery.isError) {
    Sentry.captureException(medicalRecordPageDataQuery.error, {
      level: "error",
      extra: {
        medicalRecordId,
        event: "medical-record-page-data-query-error",
        page: "conversation",
      },
    })
  }

  return (
    <QueryWrapper query={medicalRecordPageDataQuery}>
      {({ data }) => <_ConversationPage data={data} />}
    </QueryWrapper>
  )
}

type MedicalRecordPageData = MedicalRecordResponses.MedicalRecordPageData

interface Props {
  data: MedicalRecordPageData
}

const _ConversationPage = ({ data: record }: Props) => {
  const navigate = useNavigate()

  const handleBack = () => navigate(`/prescriptions/${record.id}`)
  return (
    <>
      <PatientHomeLayout.Header onBack={handleBack}>
        <Text fontWeight={600} size="3xl" lineHeight="120%">
          Echanges avec le médecin
        </Text>
      </PatientHomeLayout.Header>
      <VSpacer size={8} />
      {record.conversationId ? (
        <ConversationProvider conversationId={record.conversationId}>
          {({ conversation, sealdSession }) => (
            <ConversationChat
              conversation={conversation}
              sealdSession={sealdSession}
              isDisabled={record.status === MedicalRecordStatuses.CLOSED}
            />
          )}
        </ConversationProvider>
      ) : (
        <PlaceholderChatInterface>
          <VStack h="100%" justifyContent="center" p={0} id="lolesque">
            <Text textAlign="center" fontWeight="bold">
              Ceci est une messagerie sécurisée avec le médecin, chiffrée de
              bout en bout. Elle sera activée quand le médecin vous enverra un
              message
            </Text>
          </VStack>
        </PlaceholderChatInterface>
      )}
    </>
  )
}
