import axios, { AxiosError } from "axios"

const createHTTPClient = (baseURL: string) => {
  const client = axios.create({ baseURL })

  client.interceptors.request.use(
    async (config) => {
      const token = await window.Clerk.session?.getToken()
      if (!token) return config

      config.headers.Authorization = `Bearer ${token}`
      return config
    },
    (error) => Promise.reject(error),
  )

  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error instanceof AxiosError) {
        return Promise.reject(error.response?.data)
      }
      return Promise.reject(error)
    },
  )

  return client
}

export const apiClient = createHTTPClient(import.meta.env.VITE_API_URL)
