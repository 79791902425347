import { useEffect, useMemo } from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom"

import { UserRole } from "@bleu/types/user"

import { checkUserAuthorization } from "@bleu/shared/utils/auth"

import { useUser } from "@bleu/front/components/auth/UserContext"

type Props = {
  role: UserRole
}

export const RoleGuard = ({ role }: Props) => {
  const navigate = useNavigate()
  const params = useParams()

  const user = useUser()

  const isAuthorized = useMemo(
    () => checkUserAuthorization(user, role, params),
    [params, role, user],
  )

  useEffect(() => {
    if (!isAuthorized) {
      navigate("/")
    }
  }, [isAuthorized, navigate])

  if (!isAuthorized) return null
  return <Outlet />
}
