import { Dispatch, SetStateAction } from "react"
import { create } from "zustand"
import { persist } from "zustand/middleware"

import { DepistageName } from "@bleu/shared/depistages"
import {
  ProfileData,
  AllQuestionnairesAnswers,
} from "@bleu/shared/questionnaires"
import { VerticalName } from "@bleu/shared/verticals"

interface FunnelStore {
  selectedVerticals: VerticalName[]
  profile: ProfileData | undefined
  excludedDepistages: DepistageName[]
  answers: AllQuestionnairesAnswers
  prescribedDepistages: DepistageName[]
  setSelectedVerticals: Dispatch<SetStateAction<VerticalName[]>>
  setProfile: Dispatch<SetStateAction<ProfileData | undefined>>
  setExcludedDepistages: Dispatch<SetStateAction<DepistageName[]>>
  setAnswers: Dispatch<SetStateAction<AllQuestionnairesAnswers>>
  setPrescribedDepistages: Dispatch<SetStateAction<DepistageName[]>>
  reset: () => void
}

export const useFunnelStore = create<FunnelStore>()(
  persist(
    (set) => ({
      selectedVerticals: [],
      profile: undefined,
      excludedDepistages: [],
      answers: {},
      prescribedDepistages: [],
      setSelectedVerticals: (verticals) =>
        set((state) => ({
          selectedVerticals:
            typeof verticals === "function"
              ? verticals(state.selectedVerticals)
              : verticals,
        })),
      setProfile: (action) =>
        set((state) => ({
          profile:
            typeof action === "function" ? action(state.profile) : action,
        })),
      setExcludedDepistages: (action) =>
        set((state) => ({
          excludedDepistages:
            typeof action === "function"
              ? action(state.excludedDepistages)
              : action,
        })),
      setAnswers: (action) =>
        set((state) => ({
          answers:
            typeof action === "function" ? action(state.answers) : action,
        })),
      setPrescribedDepistages: (action) =>
        set((state) => ({
          prescribedDepistages:
            typeof action === "function"
              ? action(state.prescribedDepistages)
              : action,
        })),
      reset: () =>
        set({
          selectedVerticals: [],
          profile: undefined,
          excludedDepistages: [],
          answers: {},
          prescribedDepistages: [],
        }),
    }),
    {
      name: "funnel-store",
    },
  ),
)
