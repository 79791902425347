import { createIcon } from "@chakra-ui/react"

export const IconVerticalesSTI = createIcon({
  displayName: "IconVerticalesSTI",
  viewBox: "0 0 32 32",
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
      <g fill="currentColor" clipPath="url(#a)">
        <path d="M14.062 21.93c.781-1.343 2.734-1.343 3.515 0l4.1 7.049C22.458 30.322 21.482 32 19.92 32h-8.201c-1.562 0-2.538-1.678-1.757-3.021l4.1-7.049ZM20.103 20.283c-.768-1.35.208-3.047 1.757-3.055l8.134-.038c1.55-.008 2.51 1.68 1.729 3.037l-4.1 7.127c-.782 1.357-2.719 1.367-3.487.017l-4.033-7.088ZM.478 20.283c-.768-1.35.208-3.047 1.758-3.055l8.133-.038c1.55-.008 2.51 1.68 1.729 3.037l-4.1 7.127c-.781 1.357-2.718 1.367-3.486.017L.478 20.283ZM17.938 10.07c-.781 1.342-2.734 1.342-3.515 0l-4.1-7.049C9.542 1.678 10.518 0 12.08 0h8.2c1.563 0 2.54 1.678 1.758 3.021l-4.1 7.049ZM11.897 11.717c.768 1.35-.208 3.047-1.757 3.054l-8.134.04c-1.55.007-2.51-1.681-1.729-3.038l4.1-7.127c.782-1.358 2.719-1.367 3.487-.017l4.033 7.088ZM31.522 11.717c.768 1.35-.208 3.047-1.758 3.054l-8.133.04c-1.55.007-2.51-1.681-1.729-3.038l4.1-7.127c.782-1.358 2.718-1.367 3.486-.017l4.034 7.088Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
})
