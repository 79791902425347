import { B9SupplementsCurrentSituationOption } from "@bleu/shared/questionnaires"
import { computeBodyMassIndexFromProfile } from "@bleu/shared/questionnaires/utils"

import type { ComputePrescription } from "./types"

export const B9_SUPPLEMENTS_PRESCRIPTIONS = {
  b9Supplements: {
    shortName: "B9",
    title: "ACIDE FOLIQUE CCD 0,4 mg Cpr Plq/30",
    description:
      "1 comprimé par jour\n" +
      "à commencer idéalement 4 semaines avant la conception et poursuivre pendant 8 semaines.\n" +
      "2 boîtes, renouvelable 1 fois.",
    prescriptionType: "PRESCRIPTION_TREATMENT",
  },
} as const

export const b9SupplementsComputePrescription: ComputePrescription = (
  { b9Supplements },
  profile,
) => {
  const { currentSituation } = b9Supplements!
  const bmi = computeBodyMassIndexFromProfile(profile)

  if (
    !currentSituation.includes(B9SupplementsCurrentSituationOption.NONE) ||
    bmi > 28 ||
    currentSituation.includes(B9SupplementsCurrentSituationOption.NONE) ||
    bmi <= 28
  ) {
    return [B9_SUPPLEMENTS_PRESCRIPTIONS.b9Supplements]
  }

  return []
}
