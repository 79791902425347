export * from "./enums"

import _ from "lodash"

import {
  DepistagesLongNames,
  DepistagesNames,
  type DepistageName,
} from "@bleu/shared/depistages/enums"
import type { Depistage } from "@bleu/shared/depistages/types"
import {
  abdominalAorticAneurysmComputePrescription,
  b9SupplementsComputePrescription,
  breastCancerComputePrescription,
  cervicalCancerComputePrescription,
  colorectalCancerComputePrescription,
  diabetesComputePrescription,
  dyslipidemiaComputePrescription,
  highBloodPressureComputePrescription,
  nicotineSubstitutionComputePrescription,
  score2ComputePrescription,
  stiComputePrescription,
} from "@bleu/shared/prescriptions/index"
import {
  type QuestionnaireShape,
  questionnaires,
  type ProfileData,
  BiologicalSexOption,
} from "@bleu/shared/questionnaires"
import { score2Questionnaire } from "@bleu/shared/questionnaires/score2"
import { computeBodyMassIndexFromProfile } from "@bleu/shared/questionnaires/utils"
import {
  VerticalName,
  allVerticalNameList,
  getDepistagesNamesForVertical,
} from "@bleu/shared/verticals"

export const getQuestionnaireForDepistage = (
  depistageName: DepistageName,
): QuestionnaireShape | undefined => {
  const questionnairesForDepistageMap = {
    abdominalAorticAneurysm: questionnaires.abdominalAorticAneurysm,
    breastCancer: questionnaires.breastCancer,
    cervicalCancer: questionnaires.cervicalCancer,
    colorectalCancer: questionnaires.colorectalCancer,
    diabetes: questionnaires.diabetes,
    b9Supplements: questionnaires.b9Supplements,
    highBloodPressure: undefined,
    dyslipidemia: questionnaires.dyslipidemia,
    nicotineSubstitution: questionnaires.nicotineSubstitution,
    score2: questionnaires.score2,
    sti: questionnaires.sti,
  } as const
  return questionnairesForDepistageMap[depistageName]
}

type DepistageRelevanceFunction = (profile: ProfileData) => boolean

const isAlwaysRelevantToProfile: DepistageRelevanceFunction = () => true

export const getVerticalNameForDepistageName = (depistageName: DepistageName) =>
  allVerticalNameList
    .filter((verticalName) => verticalName !== VerticalName.GENERAL)
    .find((verticalName) =>
      getDepistagesNamesForVertical(verticalName).includes(depistageName),
    )!

export const depistagesNames = Object.values(DepistagesNames)

export const depistages: Record<DepistageName, Depistage> = {
  abdominalAorticAneurysm: {
    name: DepistagesNames.abdominalAorticAneurysm,
    longName: DepistagesLongNames.abdominalAorticAneurysm,
    isRelevantToProfile: ({ age, biologicalSex }) =>
      _.inRange(age, 50, 75) && biologicalSex === BiologicalSexOption.MALE,
    computePrescription: abdominalAorticAneurysmComputePrescription,
    questionnaire: questionnaires.abdominalAorticAneurysm,
    description:
      "Il est recommandé aux hommes entre 65 et 75 ans fumeurs ou ayant été fumeurs ainsi qu’aux hommes entre 50 et 75 ans présentant des antécédents familiaux.",
    moreInfo:
      "L’anévrisme de l’aorte abdominale touche majoritairement les hommes (1 femme pour 13 hommes), essentiellement après 65 ans. A part l’âge et le sexe, les autres facteurs de risque sont le tabac, les autres maladies cardiovasculaires et les antécédents familiaux d’AAA.\n\nEn pratique, il s’agit d’une dilatation d’une portion plus ou moins longue de l’aorte, l’artère qui quitte le coeur pour distribuer le sang dans l’organisme, dans sa partie abdominale. Cette dilatation entraine un risque important de rupture, dont le traitement est urgent, chirurgical et de pronostic réservé (34% de décès des patients ayant pu bénéficier d’une chirurgie en urgence).",
    prescriptionInfo: {
      title: "Examen d'imagerie",
      description:
        "Le dépistage de l’AAA est recommandé une fois dans leur vie aux hommes entre 65 et 75 ans fumeurs ou ayant été fumeurs, ainsi qu’aux hommes entre 50 et 75 ans présentant des antécédents familiaux.\n" +
        "\n" +
        "Il est réalisé par échographie doppler qui permet d’identifier l’anévrisme et le cas échéant de décrire sa forme et sa taille. Le médecin échographiste pourra également demander une IRM en complément.",
      sources: [
        {
          title: "Haute Autorité de Santé",
          url: "https://www.has-sante.fr/jcms/c_1361253/fr/anevrismes-de-l-aorte-abdominale-en-france-proposer-un-depistage-cible-opportuniste-unique-chez-les-hommes-presentant-des-facteurs-de-risque",
        },
      ],
    },
  },
  b9Supplements: {
    name: DepistagesNames.b9Supplements,
    longName: DepistagesLongNames.b9Supplements,
    isRelevantToProfile: ({ age, biologicalSex, isPregnant }) =>
      biologicalSex === BiologicalSexOption.FEMALE &&
      _.inRange(age, 18, 50) &&
      !isPregnant,
    computePrescription: b9SupplementsComputePrescription,
    questionnaire: questionnaires.b9Supplements,
    subtitle: "Vitamine B9",
    description: "Elle est recommandée dans le cadre d'un projet de grossesse",
    moreInfo:
      "En cas de grossesse, les anomalies de fermeture du tube neural, dont le spina bifida, concerneraient en France 1 naissance sur 1000. Si les causes sont multiples, il a été prouvé qu’une supplémentation en vitamine B9 permettait d’en diminuer le risque.\n\nLa supplémentation en acide folique (vitamine B9) à raison de 0,4mg/j commencée 4 semaines avant la conception et prolongée 8 semaines après, permettrait de diminuer le risque que l’embryon ne développe de telles anomalies.",
    prescriptionInfo: {
      title: "Médicament",
      description:
        "La supplémentation en acide folique (vitamine B9), à raison de 0,4mg/j commencée 4 semaines avant la conception et prolongée 8 semaines après, diminue le risque que l’embryon ne développe des anomalies de fermeture du tube neural. Elle est recommandée si vous avez un projet de grossesse.",
      sources: [
        {
          title: "Ministère de la santé",
          url: "https://sante.gouv.fr/IMG/pdf/Recommandations_pour_la_prevention_des_anomalies_de_la_fermeture_du_tube_neural.pdf",
        },
      ],
    },
  },
  breastCancer: {
    name: DepistagesNames.breastCancer,
    longName: DepistagesLongNames.breastCancer,
    isRelevantToProfile: ({ age, biologicalSex, isPregnant }) =>
      biologicalSex === BiologicalSexOption.FEMALE &&
      _.inRange(age, 50, 75) &&
      !isPregnant,
    computePrescription: breastCancerComputePrescription,
    questionnaire: questionnaires.breastCancer,
    description:
      "Dans la plupart des cas, le dépistage du cancer du sein est à réaliser tous les deux ans à partir de 50 ans.",
    moreInfo:
      "Les personnes éligibles au dépistage sont habituellement contactées par l’Assurance Maladie dans le cadre d’un dépistage organisé, mais nous pouvons également prescrire ce dépistage en rattrapage, à réaliser dans n’importe quel cabinet d’imagerie labellisé (liste accessible sur le site de votre CPAM).\n\nMais dans certaines situations abordées dans le questionnaire suivant, les modalités de dépistage sont différentes (âge de début plus précoce, fréquence plus élevée…). Ces situations ne sont pas prises en charge sur Bleu car un suivi présentiel et régulier est nécessaire.\n\nA tout âge, l’auto-palpation est également recommandée.",
    prescriptionInfo: {
      title: "Examen d'imagerie",
      description:
        "La mammographie est à réaliser dans un centre agréé dont la liste est disponible sur le site de la CPAM de votre département.\n" +
        "\n" +
        "Ces centres agréés pratiquent le tiers payant intégral, la double lecture des clichés et sont en état de réaliser d’éventuels actes complémentaires et de vous orienter.",
      sources: [
        {
          title: "Assurance Maladie",
          url: "https://www.ameli.fr/corse-du-sud/assure/sante/themes/cancer-sein",
        },
        {
          title: "INCa",
          url: "https://www.e-cancer.fr/Professionnels-de-sante/Depistage-et-detection-precoce/Depistage-du-cancer-du-sein",
        },
      ],
    },
  },
  cervicalCancer: {
    name: DepistagesNames.cervicalCancer,
    longName: DepistagesLongNames.cervicalCancer,
    isRelevantToProfile: ({ age, biologicalSex }) =>
      _.inRange(age, 25, 65) && biologicalSex === BiologicalSexOption.FEMALE,
    computePrescription: cervicalCancerComputePrescription,
    questionnaire: questionnaires.cervicalCancer,
    description:
      "Il est à réaliser pour les femmes de plus de 25 ans selon le calendrier recommandé",
    moreInfo:
      "Cancer pouvant notamment affecter la femme jeune, le cancer du col de l’utérus se traite bien s'il est dépisté tôt. A un stade plus avancé, le pronostic est assez réservé.\n\nIl est à réaliser tous les trois ans pour les femmes de plus de 25 ans après 2 premiers examens cytologiques normaux espacés de 1 an, ou tous les 5 ans pour les femmes de plus de 30 ans réalisant leur dépistage par recherche d’HPV-HR (à l’exception du premier dépistage par recherche d’HPV-HR qui doit être réalisé trois ans après un examen cytologique normal).",
    prescriptionInfo: {
      title: "Examen de biologie",
      description:
        "Selon les cas, le dépistage consiste en une recherche d’HPV sur prélèvements vaginaux ou sur frottis du col ou en une recherche d’anomalies cellulaires sur frottis du col.\n" +
        "\n" +
        "Vos réponses au questionnaire nous permettent de prescrire l’examen adapté, à réaliser au laboratoire. \n" +
        "\n" +
        "Sachez que cet examen peut aussi être pratiqué par un médecin généraliste, un gynécologue ou une sage-femme, selon vos préférences.\n" +
        "\n" +
        "Si vous décidez de le réaliser au laboratoire, cet examen est le plus souvent sur rendez-vous. Lors de l’appel au laboratoire, et si votre dépistage consiste en une recherche d’HPV, vous pouvez demander si l’auto-prélèvement est possible.",
      sources: [
        {
          title: "Assurance Maladie",
          url: "https://www.ameli.fr/corse-du-sud/assure/sante/themes/cancer-col-uterus",
        },
        {
          title: "INCa",
          url: "https://www.e-cancer.fr/Professionnels-de-sante/Depistage-et-detection-precoce/Depistage-du-cancer-du-col-de-l-uterus",
        },
      ],
    },
  },
  colorectalCancer: {
    name: DepistagesNames.colorectalCancer,
    longName: DepistagesLongNames.colorectalCancer,
    isRelevantToProfile: ({ age }) => _.inRange(age, 50, 75),
    computePrescription: colorectalCancerComputePrescription,
    questionnaire: questionnaires.colorectalCancer,
    description:
      "Le dépistage est à réaliser tous les deux ans et se fait en un ou deux temps: avec un kit de prélèvement à la maison suivi, en cas de test positif, d’une coloscopie",
    moreInfo:
      'L’adulte (femme et homme) de 50 à 74 ans sans antécédent familial ou personnel de cancer colo-rectal, et ne présentant aucun symptôme ou facteur de risque est considéré comme à risque "moyen" de cancer colo-rectal.\n\nLes personnes éligibles sont habituellement contactées par l’Assurance Maladie dans le cadre d’un dépistage organisé, mais nous pouvons également prescrire ce dépistage en rattrapage, à réaliser dans n’importe quel laboratoire de biologie médicale si vous le souhaitez.',
    prescriptionInfo: {
      title: "Prélèvement de selles",
      description:
        "Le dépistage consiste en une recherche de sang dans les selles. Vous devez dans un premier temps récupérer un kit de prélèvement au laboratoire.",
      sources: [
        {
          title: "Assurance Maladie",
          url: "https://www.ameli.fr/corse-du-sud/assure/sante/themes/cancer-colorectal/depistage-gratuit-cancer-colorectal50-74-ans",
        },
        {
          title: "INCa",
          url: "https://www.e-cancer.fr/Professionnels-de-sante/Depistage-et-detection-precoce/Depistage-du-cancer-colorectal",
        },
      ],
    },
  },
  diabetes: {
    name: DepistagesNames.diabetes,
    longName: DepistagesLongNames.diabetes,
    isRelevantToProfile: ({ age, height, weight }) => {
      const bmi = computeBodyMassIndexFromProfile({ height, weight })
      if (age >= 70) return false
      if (age >= 18 && bmi >= 30) return true
      if (age >= 35 && bmi >= 25) return true
      return false
    },
    computePrescription: diabetesComputePrescription,
    questionnaire: questionnaires.diabetes,
    description:
      "Ce dépistage est réaliser tous les 3 ans, en l’absence d’apparition de symptômes évocateurs (modification du poids, fatigue, soif, mictions fréquentes…) ou de modifications de l’hygiène de vie.",
    moreInfo: `Le diabète est une élévation de la concentration de sucre dans le sang.

Maladie silencieuse, elle est responsable notamment d’une détérioration des petites artères et entraîne en conséquence insuffisance rénale, troubles de la vision, troubles sensitifs pouvant aller jusqu’à l’amputation.

➤ Premier stade du diabète (de type 2), le pré-diabète est une condition réversible, notamment en modifiant son alimentation et augmentant son activité physique.

➤ Le dépistage permet d’éviter de découvrir un diabète à un stade déjà avancé, c’est-à-dire avec de premières complications (souvent silencieuses et difficilement réversibles).`,
    prescriptionInfo: {
      title: "Examen sanguin",
      description:
        "Cet examen consiste en une prise de sang à réaliser à jeun depuis plus de 8h. Il est également recommandé de ne pas réaliser d’effort physique intense ni de fumer dans les heures précédant l'examen.",
      sources: [
        {
          title: "Assurance Maladie",
          url: "https://www.ameli.fr/corse-du-sud/assure/sante/themes/diabete",
        },
      ],
    },
  },
  dyslipidemia: {
    name: DepistagesNames.dyslipidemia,
    longName: DepistagesLongNames.dyslipidemia,
    isRelevantToProfile: ({ age }) => age >= 18,
    computePrescription: dyslipidemiaComputePrescription,
    questionnaire: questionnaires.dyslipidemia,
    subtitle: "Cholestérol et lipides",
    description:
      "Ce dépistage est à réaliser tous les 5 ans en l’absence de modification de l’hygiène de vie ou de symptômes évocateurs (prise de poids, essoufflement, douleur dans la poitrine, crampes pour des efforts peu importants…).",
    moreInfo:
      'L’excès de graisses dans le sang peut être à l’origine de maladies cardiovasculaires telles que l’infarctus du myocarde (excès de cholestérol) ou métaboliques telles que la stéatose hépatique (excès de triglycérides : le "foie gras non alcoolique").\n\nAvec le niveau de pression artérielle et l‘exposition au tabac, la concentration de mauvais cholestérol (le C-LDL) dans le sang est à la base du calcul du niveau de risque cardiovasculaire.',
    prescriptionInfo: {
      title: "Examen sanguin",
      description:
        "Le dépistage des anomalies lipidiques (bilan lipidique) consiste en une prise de sang à réaliser à jeun depuis plus de 12h. Il est également recommandé de ne pas réaliser d’effort physique intense ni de fumer dans les heures précédant l'examen.",
      sources: [
        {
          title: "Assurance Maladie",
          url: "https://www.ameli.fr/corse-du-sud/assure/sante/themes/trop-cholesterol-triglycerides-dans-sang-dyslipidemie/diagnostic-surveillance",
        },
      ],
    },
  },
  highBloodPressure: {
    name: DepistagesNames.highBloodPressure,
    longName: DepistagesLongNames.highBloodPressure,
    isRelevantToProfile: ({ age }) => age >= 18,
    computePrescription: highBloodPressureComputePrescription,
    questionnaire: undefined,
    description:
      "L’HTA est un facteur de risque cardiovasculaire important et l’une des toutes premières causes de mortalité car responsable d’insuffisance cardiaque, d’AVC ou encore d’infarctus.",
    moreInfo:
      "Souvent silencieuse, elle passe inaperçue jusqu’à la survenue d’un accident cardiovasculaire.\n\nPourtant, dépistée, l’HTA peut se traiter…\n\nLe dépistage de l’HTA est préconisé pour tous les adultes de 18 ans et plus. Il est réalisé idéalement par un professionnel de la santé (au cabinet pour les médecins, infirmiers, sages-femmes et à l’officine pour les pharmaciens):\n\n➤ tous les 3 à 5 ans pour les adultes de 18 à 39 ans\n\n➤ tous les ans à partir de 40 ans ou pour les adultes plus jeunes ayant un facteur de risque",
    prescriptionInfo: {
      title: "Dépistage de l'hypertension artérielle",
      description:
        "Nous vous préparons un courrier d'adressage à présenter à un pharmacien.",
      sources: [
        {
          title: "Assurance Maladie",
          url: "https://www.ameli.fr/paris/assure/sante/themes/hypertension-arterielle-hta/definition-facteurs-favorisants",
        },
      ],
    },
  },
  nicotineSubstitution: {
    name: DepistagesNames.nicotineSubstitution,
    longName: DepistagesLongNames.nicotineSubstitution,
    isRelevantToProfile: ({ age, isSmoker, biologicalSex, isPregnant }) =>
      age >= 18 &&
      isSmoker &&
      (biologicalSex === BiologicalSexOption.MALE ||
        (biologicalSex === BiologicalSexOption.FEMALE && !isPregnant)),
    computePrescription: nicotineSubstitutionComputePrescription,
    questionnaire: questionnaires.nicotineSubstitution,
    description:
      "Ils constituent le traitement médicamenteux recommandé en première intention dans l'arrêt du tabac.",
    moreInfo:
      "Ils augmentent les chances de réussir à arrêter de fumer de 50 à 70 % par rapport à la volonté seule. Sur prescription, ils sont pris en charge à 65% par la sécurité sociale.\n\nLe substitut nicotinique traite en premier lieu la dépendance physique mais, en gomme ou pastille, il aide également à faire face à la dépendance psychique et comportementale en occupant la bouche et l’esprit durant les quelques minutes que durent le “craving” (l’envie irrépressible de fumer).",
    prescriptionInfo: {
      title: "Médicament",
      description:
        "Les substituts nicotiniques aident à compenser l’arrêt d’apport de nicotine par l’usage de tabac et de ce fait à prévenir l’apparition des symptômes du sevrage. Un ajustement du traitement est souvent à réaliser dans les premiers jours ou premières semaines après l’introduction. Nous pourrons vous accompagner si besoin.",
      sources: [
        {
          title: "Arrêt brutal vs progressif",
          url: "https://pubmed.ncbi.nlm.nih.gov/26975007/",
        },
        {
          title: "Assurance Maladie",
          url: "https://www.ameli.fr/corse-du-sud/medecin/exercice-liberal/regles-de-prescription-et-formalites/medicaments-et-dispositifs/prise-en-charge-sevrage-tabagique",
        },
      ],
    },
  },
  score2: {
    name: DepistagesNames.score2,
    longName: DepistagesLongNames.score2,
    isRelevantToProfile: ({ age }) => age >= 40,
    computePrescription: score2ComputePrescription,
    questionnaire: score2Questionnaire,
    description:
      "Le calcul du score de risque cardiovasculaire est recommandé pour les personnes de plus de 40 ans",
  },
  sti: {
    name: DepistagesNames.sti,
    longName: DepistagesLongNames.sti,
    isRelevantToProfile: isAlwaysRelevantToProfile,
    computePrescription: stiComputePrescription,
    questionnaire: questionnaires.sti,
    subtitle: "Infectieuses et virales",
    description:
      "Ce dépistage est recommandé en cas de symptômes, de prise de risque ou de changement de partenaire",
    moreInfo:
      "Le dépistage est proposé assez largement en population générale y compris en l'absence de facteurs de risque spécifiques. Il est en revanche à réaliser après chaque prise de risque.  \n\nIl vous concerne si vous êtes dans l’une des situations suivantes: \n\n➤ Femme sexuellement active n’ayant jamais réalisé de dépistage \n\n➤ Hommes et femmes dans l’une des situations suivantes : \n\t- Un rapport non protégé, y compris fellation, en dehors d’une relation de couple stricte \n\t- Au moins deux partenaires sexuels dans les 12 mois écoulés, \n\t- Un nouveau partenaire sexuel dans les 12 mois écoulés, \n\t- Autre IST en cours ou autre IST diagnostiquée dans l’année chez soi même ou son partenaire (syphilis, VIH…), \n\t- Travailleur(euse) du sexe ou ayant des relations sexuelles avec un(e) travailleur(euse) du sexe \n\t- Contrôle de l'efficacité d'un traitement pour chlamydia",
    prescriptionInfo: {
      title: "Examens sanguins et microbiologie",
      description:
        "Les recherches microbiologiques (chlamydia, gonocoque, mycoplasme) se font par prélèvement d’urine (pour l’homme) et/ou des sites ayant été pénétrés pour l’homme ou la femme (anus, gorge, vagin).\n" +
        "\n" +
        "Certains laboratoires de biologie médicale autorisent les auto prélèvements (prélèvements faits soi-même) : renseignez-vous au moment de votre prise de RDV.\n" +
        "\n" +
        "Les sérologies (VIH, hépatites, syphilis) consistent en une recherche d’anticorps et d'antigènes dans le sang. La prise de sang ne nécessite pas d’être à jeun.",
      sources: [
        {
          title: "Assurance Maladie",
          url: "https://www.ameli.fr/paris/assure/sante/themes/mst-ist/maladies-infections-sexuellement-transmissibles",
        },
      ],
    },
  },
}
