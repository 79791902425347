import { Request } from "express"
import { default as parsePhoneNumberFromString } from "libphonenumber-js"
import { z } from "zod"

/**
 * Use like this:
 *
 * const schema = z.object({
 *  name: z.string(),
 * })
 *
 * router.post('/', (req, res) => {
 *  validateBody(req.body, schema)
 *  req.body.name // is now a string
 * })
 */
export const validateBody = async <S extends z.Schema>(
  body: Request["body"],
  schema: S,
): Promise<z.infer<typeof schema>> => {
  const validatedBody = await schema.parseAsync(body)
  return validatedBody
}

export const validateQueryParams = async <S extends z.Schema>(
  body: Request["query"],
  schema: S,
): Promise<z.infer<typeof schema>> => {
  const validatedQueryParams = await schema.parseAsync(body)
  return validatedQueryParams
}

export const CustomZodIssueCodes = {
  noNumber: "noNumber",
  noSpecialChar: "noSpecialChar",
  invalidPhone: "invalidPhone",
  passwordsMatch: "passwordsMatch",
}

// Credit: https://stackoverflow.com/a/78046054
export const zPhone = z.string().transform((arg, ctx) => {
  const phone = parsePhoneNumberFromString(arg, {
    // set this to use a default country when the phone number omits country code
    defaultCountry: "FR",

    // set to false to require that the whole string is exactly a phone number,
    // otherwise, it will search for a phone number anywhere within the string
    extract: false,
  })

  // when it's good
  if (phone && phone.isValid()) {
    return phone.format("E.164")
  }

  // when it's not
  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    message: CustomZodIssueCodes.invalidPhone,
  })
  return z.NEVER
})

export const hasNumber = (arg: string) => /\d/.test(arg)
export const hasSpecialChar = (arg: string) => /[^A-Za-z0-9]/.test(arg)
export const isLongEnough = (arg: string) => arg.length >= 8

export const zPassword = z.string().superRefine((arg, ctx) => {
  if (!isLongEnough(arg)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: z.ZodIssueCode.too_small,
    })
  }
  if (!hasNumber(arg)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: CustomZodIssueCodes.noNumber,
    })
  }
  if (!hasSpecialChar(arg)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: CustomZodIssueCodes.noSpecialChar,
    })
  }

  return hasNumber(arg) && hasSpecialChar(arg)
})
