import { useMutation, useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

import { DoctorRequests, DoctorResponses } from "@bleu/types/endpoints/doctor"

import { useUser } from "@bleu/front/components/auth/UserContext"
import { apiClient } from "@bleu/front/lib/apiClient"
import {
  createSealdIdentity,
  initiateSealdClient,
  sealdClient,
} from "@bleu/front/lib/seald"
import { useSealdSignInMutation } from "@bleu/front/queries/seald"

export const useDoctorStatsQuery = () => {
  return useQuery({
    queryKey: ["doctor", "stats"],
    queryFn: async () => {
      const response =
        await apiClient.get<DoctorResponses.Stats>("/doctor/stats")
      return response.data
    },
  })
}

export const useDoctorInfoQuery = () => {
  return useQuery({
    queryKey: ["doctor", "info"],
    queryFn: async () => {
      const response =
        await apiClient.get<DoctorResponses.DoctorInfo>("/doctor/info")
      return response.data
    },
  })
}

export const useInviteNewDoctorMutation = () => {
  const user = useUser()
  const navigate = useNavigate()
  const { mutateAsync: sealdSignIn } = useSealdSignInMutation()

  return useMutation({
    mutationFn: async (newDoctor: DoctorRequests.NewDoctor) => {
      const response = await apiClient.post<DoctorResponses.NewDoctor>(
        "/doctor/invite",
        newDoctor,
      )
      const doctorUser = response.data

      // Save current admin seald client to temp variable for later use
      const adminSealdClient = sealdClient
      await initiateSealdClient(doctorUser.sealdDatabaseKey, true)
      const tmrChallenge = await sealdSignIn(doctorUser.id)

      if (tmrChallenge.tmrChallenge.mustAuthenticate || !tmrChallenge.sealdId) {
        throw new Error(
          `SEALD Sign Up Error: mustAuthenticate: ${tmrChallenge.tmrChallenge.mustAuthenticate} | sealdId: ${tmrChallenge.sealdId}`,
        )
      }

      await createSealdIdentity({
        userId: doctorUser.id,
        emailAddress: doctorUser.emailAddress,
        ...tmrChallenge.tmrChallenge,
        challenge: undefined,
      })

      await adminSealdClient.addGroupMembers(
        import.meta.env.VITE_SEALD_DOCTORS_GROUP_ID,
        {
          sealdIds: [tmrChallenge.sealdId],
        },
      )

      await apiClient.post<void>(`/seald/complete-sign-up/${doctorUser.id}`, {
        sealdId: tmrChallenge.sealdId,
      })

      await initiateSealdClient(user.sealdDatabaseKey, true)

      return doctorUser
    },
    onSuccess: () => {
      navigate("/doctor")
    },
  })
}
