import { useUser as useClerkUser } from "@clerk/clerk-react"
import { useQuery } from "@tanstack/react-query"

import { AuthResponses } from "@bleu/types/endpoints/auth"
import { User } from "@bleu/types/user"

import { apiClient } from "@bleu/front/lib/apiClient"

type AuthState =
  | {
      isLoading: true
      isSignedIn: undefined
      user: undefined
    }
  | {
      isLoading: false
      isSignedIn: false
      user: undefined
    }
  | {
      isLoading: false
      isSignedIn: true
      user: User
    }

export const useAuthState = (): AuthState => {
  const { isLoaded, isSignedIn, user: clerkUser } = useClerkUser()

  const { data: user, isLoading: isMeLoading } = useQuery<User | undefined>({
    queryFn: async () => {
      if (!isLoaded || !isSignedIn) return undefined
      const response = await apiClient.get<AuthResponses.Me>("/auth/me")
      return response.data
    },
    queryKey: ["me", clerkUser?.id],
    enabled: isLoaded && isSignedIn,
  })

  const isLoading = !isLoaded || isMeLoading
  if (isLoading)
    return {
      isLoading: true,
      isSignedIn: undefined,
      user: undefined,
    }
  if (!isSignedIn || !user) {
    // TODO: could we have a hook that returns an always defined user?
    return {
      isLoading: false,
      isSignedIn: false,
      user: undefined,
    }
  }
  return {
    isLoading,
    isSignedIn: true,
    user,
  }
}
