import type {
  AllQuestionnairesAnswers,
  ProfileData,
} from "@bleu/shared/questionnaires"

export const PrescriptionType = {
  PRESCRIPTION_BIOLOGY: "PRESCRIPTION_BIOLOGY",
  PRESCRIPTION_IMAGERY: "PRESCRIPTION_IMAGERY",
  PRESCRIPTION_TREATMENT: "PRESCRIPTION_TREATMENT",
  PRESCRIPTION_ADDRESSING: "PRESCRIPTION_ADDRESSING",
} as const

export type PrescriptionType = keyof typeof PrescriptionType

export const PRESCRIPTION_TYPES: Record<PrescriptionType, string> = {
  PRESCRIPTION_BIOLOGY: "Ordonnance de biologie",
  PRESCRIPTION_IMAGERY: "Ordonnance d'imagerie",
  PRESCRIPTION_TREATMENT: "Ordonnance de traitement",
  PRESCRIPTION_ADDRESSING: "Lettre d'adressage",
}

export type Prescription = {
  prescriptionType: PrescriptionType
  shortName: string
  title: string
  description?: string
}

export type ComputePrescription = (
  data: AllQuestionnairesAnswers,
  profile: ProfileData,
) => Prescription[] // TODO: could we have something with the depistage name as a key here? Let's see if we need it
