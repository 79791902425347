import { createIcon } from "@chakra-ui/react"

export const IconVerticalesSubstites = createIcon({
  displayName: "IconVerticalesSubstites",
  viewBox: "0 0 32 32",
  path: (
    <>
      <g fill="currentColor" clipPath="url(#a)">
        <path d="M0 7.314a7.314 7.314 0 1 1 14.629 0A7.314 7.314 0 0 1 0 7.314ZM0 24.686a7.314 7.314 0 1 0 14.629 0 7.314 7.314 0 0 0-14.629 0ZM32 7.314a7.314 7.314 0 1 0-14.629 0 7.314 7.314 0 0 0 14.629 0ZM32 24.686a7.314 7.314 0 1 1-14.629 0 7.314 7.314 0 0 1 14.629 0Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </>
  ),
})
