import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

import { ProfileData } from "@bleu/shared/questionnaires"

interface ProfileState {
  profile: ProfileData | null
  acceptsMarketing: boolean
  setProfile: (profile: ProfileData) => void
  setAcceptsMarketing: (acceptsMarketing: boolean) => void
  clearProfile: () => void
}

export const useProfileStore = create<ProfileState>()(
  devtools(
    persist(
      (set) => ({
        profile: null,
        acceptsMarketing: false,
        setProfile: (profile) => set({ profile }),
        setAcceptsMarketing: (acceptsMarketing) => set({ acceptsMarketing }),
        clearProfile: () => set({ profile: null, acceptsMarketing: false }),
      }),
      {
        name: "profile-storage",
      },
    ),
  ),
)
