import { HStack, Heading, Text, VStack } from "@chakra-ui/react"
import { IconChevronRight } from "@tabler/icons-react"

import { Blue } from "@bleu/front/components/layout/Blue"
import QuestionnaireLayout from "@bleu/front/components/layout/QuestionnaireLayout"
import { VSpacer } from "@bleu/front/components/layout/Spacer"

const startPageStepContent = [
  {
    number: 1,
    title: "Sélectionnez un type de dépistage",
    description: "Obtenez votre ordonnance en quelques questions (3 à 6 min)",
  },
  {
    number: 2,
    title: (
      <Heading size="lg">
        Votre ordonnance à <Blue>25€ TTC</Blue>
      </Heading>
    ),
    description:
      "Effectuez vos examens en laboratoire en présentant votre ordonnance délivrée par un médecin inscrit à l’Ordre des médecins",
  },
  {
    number: 3,
    title: "Bleu analyse vos résultats",
    description:
      "Sur votre espace patient, chargez vos résultats afin de recevoir une analyse personnalisée réalisée par les médecins de Bleu",
  },
  {
    number: 4,
    title: "L’équipe Bleu vous accompagne",
    description:
      "Si le résultat de dépistage se révèle positif un médecin de l’équipe Bleu vous contacte et vous propose une prise en charge",
  },
] as const

type Props = {
  onNext: () => void
  onBack: () => void
}

export const Start = ({ onBack, onNext }: Props) => {
  return (
    <>
      <QuestionnaireLayout.Header onBack={onBack} />
      <QuestionnaireLayout.Title
        title={{
          text: "Le parcours Bleu",
          highlight: "Bleu",
        }}
      />
      <VSpacer size={8} />
      <VStack alignItems="flex-start" gap={8} marginBottom={10}>
        {startPageStepContent.map(({ number, title, description }) => (
          <VStack gap={2} alignItems="flex-start" key={number}>
            <HStack gap={4}>
              <Heading size="3xl" color="blue.400">
                {number}
              </Heading>
              <Heading size="lg">{title}</Heading>
            </HStack>
            <Text color="navy.300">{description}</Text>
          </VStack>
        ))}
      </VStack>
      <QuestionnaireLayout.CTA
        onClick={onNext}
        rightIcon={<IconChevronRight size="16px" />}
      >
        Démarrer
      </QuestionnaireLayout.CTA>
    </>
  )
}
