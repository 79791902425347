import { createContext, ReactNode, useContext, useState } from "react"

import { User } from "@bleu/types/user"

import { logger } from "@bleu/front/utils/logger"

type UserContextType = {
  user: User
  updateUser: (user: User) => void
}

export const UserContext = createContext<UserContextType>({
  // @ts-expect-error Creating this context to have an absolute to a defined user object.
  // Context shall not be used if user doesn't exist
  user: undefined,
  updateUser: () => {
    logger.warn("Default updateUser function called. This should not happen.")
  },
})

type UserProviderProps = {
  user: User
  children: ReactNode
}

export const UserProvider = ({ user, children }: UserProviderProps) => {
  logger.info("UserProvider initialized", { userId: user.id })
  const [currentUser, setCurrentUser] = useState(user)

  const updateUser = (newUser: User) => {
    logger.info("Updating user", { userId: newUser.id })
    setCurrentUser(newUser)
  }

  return (
    <UserContext.Provider value={{ user: currentUser, updateUser }}>
      {children}
    </UserContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useUser = () => {
  const context = useContext(UserContext)
  if (!context?.user) {
    logger.error("useUser hook called outside of UserProvider")
    throw new Error("useUser must be used under AuthGuard-wrapped routes")
  }
  logger.info("useUser hook called", { userId: context.user.id })
  return context.user
}
