import type { HighlightText } from "@bleu/shared/questionnaires/types"

interface Params {
  weight: number
  height: number
}
export function computeBodyMassIndexFromProfile({ weight, height }: Params) {
  const heightInMeters = height / 100
  const bmi = weight / (heightInMeters * heightInMeters)

  return bmi
}

export function isHighlightText(
  title: string | HighlightText,
): title is HighlightText {
  return typeof title !== "string"
}
