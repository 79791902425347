import { Heading, Highlight } from "@chakra-ui/react"

import { isHighlightText } from "@bleu/shared/questionnaires/utils"

export type HighlightText = { text: string; highlight: string }

export interface LayoutTitleProps {
  title: string | HighlightText
}

export const LayoutTitle = ({ title }: LayoutTitleProps) => {
  const text = isHighlightText(title) ? title.text : title
  return (
    <Heading as="h1" size="3xl" textAlign="center">
      <Highlight
        query={isHighlightText(title) ? title.highlight : ""}
        styles={{ color: "blue.400" }}
      >
        {text}
      </Highlight>
    </Heading>
  )
}
