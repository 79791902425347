// Compute the Fagerström test for nicotine addiction
// See: https://www.pharma-gdd.com/fr/test-de-fagerstr-m-evaluer-la-dependance-physique-a-la-nicotine#:~:text=Le%20test%20de%20Fagerstr%C3%B6m%20est,Fagerstr%C3%B6m%20est%20une%20%C3%A9tape%20importante.
import {
  NicotineSubstitutionCigarettesPerDayOptions,
  NicotineSubstitutionTimeToFirstCigaretteOptions,
} from "@bleu/shared/questionnaires/index"
import { NicotineSubstitutionData } from "@bleu/shared/questionnaires/nicotineSubstitution"

export const computeFagerstromScore = (
  nicotineSubstitutionAnswers: NicotineSubstitutionData,
): number => {
  let score = 0

  switch (nicotineSubstitutionAnswers.timeToFirstCigarette) {
    case NicotineSubstitutionTimeToFirstCigaretteOptions.LESS_THAN_5_MINUTES:
      score += 3
      break
    case NicotineSubstitutionTimeToFirstCigaretteOptions.SIX_TO_THIRTY_MINUTES:
      score += 2
      break
    case NicotineSubstitutionTimeToFirstCigaretteOptions.THIRTY_ONE_TO_SIXTY_MINUTES:
      score += 1
      break
    case NicotineSubstitutionTimeToFirstCigaretteOptions.MORE_THAN_SIXTY_MINUTES:
      score += 0
      break
  }

  switch (nicotineSubstitutionAnswers.isRefrainingDifficult) {
    case true:
      score += 1
      break
    case false:
      score += 0
      break
  }

  switch (nicotineSubstitutionAnswers.quitFirstCigarette) {
    case true:
      score += 1
      break
    case false:
      score += 0
      break
  }

  switch (nicotineSubstitutionAnswers.cigarettesPerDay) {
    case NicotineSubstitutionCigarettesPerDayOptions.LESS_THAN_10:
      score += 0
      break
    case NicotineSubstitutionCigarettesPerDayOptions.TEN_TO_20:
      score += 1
      break
    case NicotineSubstitutionCigarettesPerDayOptions.TWENTY_TO_30:
      score += 2
      break
    case NicotineSubstitutionCigarettesPerDayOptions.MORE_THAN_30:
      score += 3
      break
  }

  switch (nicotineSubstitutionAnswers.smokesMoreInMorning) {
    case true:
      score += 1
      break
    case false:
      score += 0
      break
  }

  switch (nicotineSubstitutionAnswers.smokesWhenSick) {
    case true:
      score += 1
      break
    case false:
      score += 0
      break
  }

  return score
}
