import { UseQueryResult } from "@tanstack/react-query"
import _ from "lodash"

import { InfoBox } from "@bleu/front/components/InfoBox"
import { Loader } from "@bleu/front/components/layout/Loader"
import QuestionnaireLayout from "@bleu/front/components/layout/QuestionnaireLayout"

type Props<TData, TError> = {
  query: UseQueryResult<TData, TError>
  children: (props: { data: TData }) => JSX.Element
}

export const QueryWrapper = <TData, TError>({
  query,
  children,
}: Props<TData, TError>) => {
  const { data, isLoading, isError, error } = query

  if (isError) {
    console.log("Data logind error", error)
    return (
      <QuestionnaireLayout mt="5%" mb="12%">
        <InfoBox
          status="error"
          title="Une erreur est survenue"
          message="Une erreur est survenue lors du chargement des données" // TODO: display error message
        />
      </QuestionnaireLayout>
    )
  }

  if (isLoading)
    return (
      <QuestionnaireLayout mt="5%" mb="12%">
        <Loader />
      </QuestionnaireLayout>
    )

  if (!data || _.isEmpty(data)) {
    return (
      <QuestionnaireLayout mt="5%" mb="12%">
        <InfoBox
          status="warning"
          title="Aucun résultat"
          message={"Nous n'avons trouvé aucun résultat pour votre requête"}
        />
      </QuestionnaireLayout>
    )
  }

  return children({ data })
}
