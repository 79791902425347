import { JSONContent } from "@tiptap/react"

import { ChatInterface } from "@bleu/front/components/ChatInterface"
import {
  type ConversationSession,
  usePostMessageMutation,
} from "@bleu/front/queries/seald"

type Props = ConversationSession & {
  isDisabled?: boolean
}

/**
 * ConversationChat component for rendering the chat interface within a conversation session.
 *
 * @param conversation - The conversation session containing messages and metadata.
 * @param sealdSession - The session information for encryption/decryption.
 * @param isDisabled - Optional flag to disable the chat interface.
 * @returns The rendered ConversationChat component.
 */
export const ConversationChat: React.FC<Props> = ({
  conversation,
  sealdSession,
  isDisabled = false,
}) => {
  const { mutateAsync: postMessage, isPending: isPostingMessage } =
    usePostMessageMutation(conversation.id, sealdSession)

  /**
   * Handles posting a new message to the conversation.
   *
   * @param message - The message content in JSON format.
   */
  const handlePostMessage = async (message: JSONContent) => {
    await postMessage(JSON.stringify(message))
  }

  return (
    <ChatInterface
      isDisabled={isDisabled}
      messages={conversation.decryptedMessages}
      onPostMessage={handlePostMessage}
      isPostingMessage={isPostingMessage}
    />
  )
}
