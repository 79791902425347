import {
  Button,
  Container,
  HStack,
  Input,
  InputGroup,
  Text,
  Stack,
  Textarea,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react"
import { useForm, zodResolver } from "@mantine/form"
import { useRef, useState } from "react"

import { DoctorRequests, newDoctorSchema } from "@bleu/types/endpoints/doctor"

import { BasicSealdSignInComponent } from "@bleu/front/components/doctor/BasicSealdSignInComponent"
import { DoctorLayout } from "@bleu/front/components/layout/DoctorLayout"
import { SealdProvider } from "@bleu/front/components/seald/SealdProvider"
import { PasswordInput } from "@bleu/front/pages/account/PasswordInput"
import { useInviteNewDoctorMutation } from "@bleu/front/queries/doctor"

export const NewDoctorPage = () => {
  const form = useForm<DoctorRequests.NewDoctor>({
    validate: zodResolver(newDoctorSchema),
    validateInputOnChange: ["password"],
  })

  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [signatureFilename, setSignatureFilename] = useState<string>("")

  const { mutateAsync: inviteNewDoctor, isPending } =
    useInviteNewDoctorMutation()

  const handleFileInputChange = async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      // Convert the file to base64 text
      reader.readAsDataURL(file)

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        const base64URL = reader.result
        if (!base64URL) {
          form.setFieldError(
            "signatureImgBase64",
            "Ce fichier ne semble pas être une image valide.",
          )
          reject()
        } else {
          setSignatureFilename(file.name)
          form.setFieldValue("signatureImgBase64", base64URL.toString())
          resolve(base64URL)
        }
      }
    })
  }

  return (
    <Container maxW={800}>
      <Stack gap={10}>
        <DoctorLayout.Title>Inviter un nouveau médecin</DoctorLayout.Title>
        <DoctorLayout.Body>
          <SealdProvider signInComponent={BasicSealdSignInComponent}>
            <form onSubmit={form.onSubmit((values) => inviteNewDoctor(values))}>
              <Stack gap={4}>
                <HStack>
                  <Input
                    placeholder="Nom"
                    {...form.getInputProps("lastName")}
                  />
                  <Input
                    placeholder="Prénom"
                    {...form.getInputProps("firstName")}
                  />
                </HStack>
                <HStack>
                  <Input
                    placeholder="Email de contact (MSSanté)"
                    {...form.getInputProps("contactEmail")}
                  />
                  <Input
                    placeholder="Numéro de téléphone"
                    {...form.getInputProps("phoneNumber")}
                  />
                </HStack>
                <HStack>
                  <Input
                    placeholder="Email de connexion"
                    {...form.getInputProps("loginEmail")}
                  />
                  <FormControl isInvalid={!!form.errors.password}>
                    <PasswordInput
                      placeholder="Mot de passe"
                      autoComplete="password"
                      {...form.getInputProps("password")}
                    />
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                </HStack>
                <HStack>
                  <Input
                    placeholder="Numéro Adeli"
                    {...form.getInputProps("adeliNumber")}
                  />
                  <Input
                    placeholder="Numéro RPPS"
                    {...form.getInputProps("rppsNumber")}
                  />
                  <Input
                    placeholder="Numéro ordinal"
                    {...form.getInputProps("ordinalNumber")}
                  />
                </HStack>
                <Textarea
                  placeholder="Diplômes et spécialités"
                  {...form.getInputProps("diploma")}
                />
                <InputGroup onClick={() => fileInputRef.current?.click()}>
                  <HStack>
                    <Text>{signatureFilename}</Text>
                    <input
                      type="file"
                      hidden
                      accept={"image/*"}
                      onChange={(e) =>
                        handleFileInputChange(e.target.files![0])
                      }
                      ref={(e) => {
                        fileInputRef.current = e
                      }}
                    />
                    <Button size="sm" variant="outline">
                      Charger une image de la signature
                    </Button>
                  </HStack>
                </InputGroup>
                <Button
                  type="submit"
                  isDisabled={!form.isValid()}
                  isLoading={isPending}
                >
                  Inviter le médecin
                </Button>
              </Stack>
            </form>
          </SealdProvider>
        </DoctorLayout.Body>
      </Stack>
    </Container>
  )
}
