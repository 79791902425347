import {
  Text,
  HStack,
  IconButton,
  VStack,
  Icon,
  Button,
} from "@chakra-ui/react"
import { IconDownload, IconDownloadOff } from "@tabler/icons-react"
import dayjs from "dayjs"
import { Link } from "react-router-dom"

import type { AccountResponses } from "@bleu/types/endpoints"

import { VerticalsLongNames } from "@bleu/shared/verticals"

import { colors } from "@bleu/front/assets/theme/colors"
import { verticalsTheme } from "@bleu/front/assets/theme/verticalsTheme"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { useInvoicesPanelQuery } from "@bleu/front/queries/account"
import { formatPrice } from "@bleu/front/utils/formats"

export const InvoicesPanel = () => {
  const invoicePanelQuery = useInvoicesPanelQuery()
  return (
    <QueryWrapper query={invoicePanelQuery}>
      {({ data }) => <_InvoicesPanel data={data} />}
    </QueryWrapper>
  )
}

interface InvoicesPanelProps {
  data: AccountResponses.InvoicesPanelData
}

const _InvoicesPanel = ({ data }: InvoicesPanelProps) => {
  const items = data?.items ?? []

  // Remove null items and check if the resulting array is empty
  const validItems = items.filter((item) => item !== null)

  if (validItems.length === 0) {
    return (
      <VStack gap={0}>
        <VSpacer size={4} />
        <Text textAlign="center" fontSize="lg" color="gray.500">
          Vous n&apos;avez pas encore effectué de dépistage
        </Text>
        <VSpacer size={4} />
        <Button as={Link} to="/questionnaire">
          Effectuer un dépistage
        </Button>
      </VStack>
    )
  }

  return (
    <VStack gap={4} alignItems="stretch" maxW="100%">
      {validItems.map((item) => {
        if (!item) return null
        const { verticalName, date, amount, receiptUrl } = item
        const { color, icon } = verticalsTheme[verticalName] ?? {}
        return (
          <HStack
            maxH={16}
            px={5}
            py={2.5}
            justifyContent="space-between"
            key={date}
            bg="blue.200"
            borderRadius={8}
          >
            <HStack overflow="hidden" wrap="wrap" rowGap={0}>
              {icon && <Icon as={icon} color={color} />}
              <Text
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                fontWeight={600}
              >
                {VerticalsLongNames[verticalName] ?? "Unknown"}
              </Text>
              <Text fontSize="sm">{dayjs(date).format("DD/MM/YYYY")}</Text>
            </HStack>
            <HStack marginLeft={4}>
              <Text fontWeight={600}>{formatPrice(amount / 100)}</Text>

              <IconButton
                {...(receiptUrl
                  ? {
                      as: Link,
                      to: receiptUrl,
                      target: "_blank",
                      icon: <IconDownload size={24} color={colors.blue[400]} />,
                    }
                  : {
                      isDisabled: true,
                      icon: (
                        <IconDownloadOff size={24} color={colors.gray[400]} />
                      ),
                    })}
                padding="0 !important"
                bg="transparent"
                aria-label={`Télécharger la facture du ${dayjs(date).format("DD/MM/YYYY")}`}
              />
            </HStack>
          </HStack>
        )
      })}
    </VStack>
  )
}
