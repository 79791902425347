import { Button, type ButtonProps, forwardRef } from "@chakra-ui/react"

type SelectBoxVariants = "default" | "select" | "disabled"

const variantStyles: Record<
  SelectBoxVariants,
  { background: string; color: string; borderColor: string }
> = {
  default: {
    background: "white",
    color: "navy.400",
    borderColor: "blue.300",
  },
  select: {
    background: "blue.50",
    color: "blue.400",
    borderColor: "blue.400",
  },
  disabled: {
    background: "white",
    color: "gray.400",
    borderColor: "1px solid gray.400",
  },
} as const

interface SelectBoxProps extends Omit<ButtonProps, "variant"> {
  variant: SelectBoxVariants
  isReadOnly?: boolean
}

export const SelectBox = forwardRef<SelectBoxProps, "button">(
  ({ variant, isReadOnly = false, ...props }) => (
    <Button
      isDisabled={isReadOnly}
      _disabled={{
        background: "white",
        _hover: { background: "white", cursor: "default" },
      }}
      width="100%"
      maxWidth={400}
      sx={{ textWrap: "wrap" }}
      paddingX={12}
      paddingY={4}
      borderRadius={8}
      height="auto"
      fontSize="sm"
      borderWidth="1px"
      {...variantStyles[variant]}
      {...props}
    />
  ),
)
