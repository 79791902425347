import { ColorectalCancerCurrentSituationOption } from "@bleu/shared/questionnaires"

import type { ComputePrescription } from "./types"

export const COLORECTAL_CANCER_PRESCRIPTIONS = {
  kcr: {
    shortName: "KCR",
    title: "Faire réaliser un prélèvement de selles",
    description:
      "pour test immunologique de recherche de sang dans les selles dans le cadre d’un rattrapage du dépistage du cancer colorectal",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
} as const

export const colorectalCancerComputePrescription: ComputePrescription = ({
  colorectalCancer,
}) => {
  const { currentSituation } = colorectalCancer! // TODO: check why we need the `!`

  if (currentSituation.includes(ColorectalCancerCurrentSituationOption.NONE)) {
    return [COLORECTAL_CANCER_PRESCRIPTIONS.kcr]
  }

  return []
}
