import { z } from "zod"

import type { FormValues } from "@bleu/types/form"

import {
  AbdominalAorticAneurysmCriteriaOption,
  QuestionTypes,
  QuestionnairesNames,
} from "./enums"
import type { BaseQuestionnaireShape } from "./types"

export const abdominalAorticAneurysmSchema = z.object({
  criteria: z.nativeEnum(AbdominalAorticAneurysmCriteriaOption).array().min(1),
})

enum QuestionNames {
  CRITERIA = "criteria",
}

const initialValues: AbdominalAorticAneurysmFormValues = {
  [QuestionNames.CRITERIA]: new Array<AbdominalAorticAneurysmCriteriaOption>(),
}

export type AbdominalAorticAneurysmData = z.infer<
  typeof abdominalAorticAneurysmSchema
>
type AbdominalAorticAneurysmFormValues = FormValues<AbdominalAorticAneurysmData>

export type AbdominalAorticAneurysmQuestionnaireShape = BaseQuestionnaireShape<
  QuestionNames,
  AbdominalAorticAneurysmData,
  AbdominalAorticAneurysmFormValues
>
export const abdominalAorticAneurysmQuestionnaire: AbdominalAorticAneurysmQuestionnaireShape =
  {
    name: QuestionnairesNames.abdominalAorticAneurysm,
    questions: [
      {
        id: QuestionNames.CRITERIA,
        type: QuestionTypes.MultiSelect,
        shouldShow: (_, { age }) => age >= 50,
        title: "Sélectionnez le ou les critères qui s'appliquent à vous :",
        options: [
          {
            label:
              "Fumeur ou ancien fumeur (même si vous avez cessé de fumer y a longtemps)",
            value: AbdominalAorticAneurysmCriteriaOption.SMOKER,
          },
          {
            label:
              "Antécédents familiaux d'anévrisme de l'aorte abdominale (AAA)",
            value: AbdominalAorticAneurysmCriteriaOption.FAMILY_HISTORY,
          },
          {
            label: "Aucun de ces critères ne s'applique à moi",
            value: AbdominalAorticAneurysmCriteriaOption.NONE,
            unique: true,
          },
          {
            label: "Je ne sais pas",
            value: AbdominalAorticAneurysmCriteriaOption.DOESNT_KNOW,
            unique: true,
          },
        ],
      },
    ],
    schema: abdominalAorticAneurysmSchema,
    initialValues,
  } as const
