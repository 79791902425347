import { RedirectToSignIn } from "@clerk/clerk-react"
import { Outlet } from "react-router-dom"

import { UserProvider } from "@bleu/front/components/auth/UserContext"
import { FullPageLoader } from "@bleu/front/components/layout/FullPageLoader"
import { useAuthState } from "@bleu/front/queries/auth"

export const AuthGuard = () => {
  const { user, isSignedIn, isLoading } = useAuthState()

  if (isLoading) return <FullPageLoader />
  if (!isSignedIn) return <RedirectToSignIn />
  return (
    <UserProvider user={user}>
      <Outlet />
    </UserProvider>
  )
}
