import { DiabetesRiskFactorOption } from "@bleu/shared/questionnaires"
import { computeBodyMassIndexFromProfile } from "@bleu/shared/questionnaires/utils"

import type { ComputePrescription } from "./types"

export const DIABETES_PRESCRIPTIONS = {
  glycemia: {
    shortName: "Glyc",
    title: "Faire réaliser une glycémie",
    description: "(le matin à jeun depuis 12h, eau possible, pas de tabac)",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
} as const

export const diabetesComputePrescription: ComputePrescription = (
  { diabetes },
  { age, ...profile },
) => {
  const { riskFactors } = diabetes!
  const bmi = computeBodyMassIndexFromProfile(profile)

  if (
    age >= 45 &&
    (bmi >= 28 || !riskFactors.includes(DiabetesRiskFactorOption.NONE))
  ) {
    return [DIABETES_PRESCRIPTIONS.glycemia]
  }

  return []
}
