import { tagAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tagAnatomy.keys)

const baseStyle = definePartsStyle({
  container: { borderRadius: 24, fontWeight: "bold" },
})

export const Tag = defineMultiStyleConfig({ baseStyle })
