import "dayjs/locale/fr"

import { ChakraBaseProvider } from "@chakra-ui/react"
import { ClerkLoaded, ClerkLoading, ClerkProvider } from "@clerk/clerk-react"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import dayjs from "dayjs"
import LocalizedFormat from "dayjs/plugin/localizedFormat"
import { ErrorBoundary } from "react-error-boundary"
import { RouterProvider } from "react-router-dom"

import { theme } from "@bleu/front/assets/theme"
import { CLERK_COPY, CLERK_THEME } from "@bleu/front/assets/theme/clerk"
import { FullPageLoader } from "@bleu/front/components/layout/FullPageLoader"
import { queryClient } from "@bleu/front/lib/queryClient"
import { router } from "@bleu/front/pages/router"

import "./global.css"
import { ErrorBoundaryFallback } from "./ErrorBoundaryFallback"

dayjs.extend(LocalizedFormat)
dayjs.locale("fr")

// Add Scalingo Deployment for Develop Branch //

const CLERK_PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY

if (!CLERK_PUBLISHABLE_KEY) {
  throw new Error("Missing Clerk Publishable Key")
}

function App() {
  return (
    <ClerkProvider
      localization={CLERK_COPY}
      appearance={CLERK_THEME}
      publishableKey={CLERK_PUBLISHABLE_KEY}
    >
      <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
        <ChakraBaseProvider theme={theme}>
          <ClerkLoaded>
            <QueryClientProvider client={queryClient}>
              <RouterProvider router={router} />
              <ReactQueryDevtools />
            </QueryClientProvider>
          </ClerkLoaded>
          <ClerkLoading>
            <FullPageLoader />
          </ClerkLoading>
        </ChakraBaseProvider>
      </ErrorBoundary>
    </ClerkProvider>
  )
}

export default App
