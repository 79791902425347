import {
  Button,
  VStack,
  Heading,
  FormControl,
  useToast,
  FormErrorMessage,
  FormLabel,
  Text,
  List,
  ListItem,
} from "@chakra-ui/react"
import { useForm, zodResolver } from "@mantine/form"
import { IconCircleFilled } from "@tabler/icons-react"
import { useState, type KeyboardEventHandler } from "react"
import { useNavigate } from "react-router-dom"
import { z } from "zod"

import {
  hasNumber as _hasNumber,
  hasSpecialChar as _hasSpecialChar,
  isLongEnough as _isLongEnough,
  zPassword,
} from "@bleu/shared/forms/validation"

import { colors } from "@bleu/front/assets/theme/colors"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { ConfirmPassword } from "@bleu/front/pages/account/ConfirmPassword"
import { PasswordInput } from "@bleu/front/pages/account/PasswordInput"
import { useUpdatePasswordMutation } from "@bleu/front/queries/account"

interface UpdatePasswordFormProps {
  onNext: () => void
}
const UpdatePasswordForm = ({ onNext }: UpdatePasswordFormProps) => {
  const [errors, setErrors] = useState<{
    password: boolean
    confirmPassword: boolean
  }>({ password: false, confirmPassword: false })
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false)
  const { mutateAsync: updatePassword, isPending } = useUpdatePasswordMutation()
  const toast = useToast()
  const form = useForm({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validate: zodResolver(
      z
        .object({ password: zPassword, confirmPassword: z.string() })
        .refine((data) => data.password === data.confirmPassword),
    ),
  })

  const handleNext = async () => {
    if (!hasTriedToSubmit) {
      setHasTriedToSubmit(true)
    }
    setErrors({ password: false, confirmPassword: false })

    const password = form.validate()
    if (password.hasErrors) {
      setErrors({
        password: !hasNumber || !isLongEnough || !hasSpecialChar,
        confirmPassword: form.values.password !== form.values.confirmPassword,
      })
      return
    }

    const success = await updatePassword(form.values.password)

    if (success) {
      onNext()
    } else {
      toast({
        title: "Erreur lors de la mise à jour du mot de passe",
        status: "error",
      })
    }
  }

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === "Enter" && !isPending) {
      if (!hasTriedToSubmit) {
        setHasTriedToSubmit(true)
      }
      handleNext()
    }
  }

  const isDisabled = !form.isValid()

  const isLongEnough = _isLongEnough(form.values.password)
  const hasSpecialChar = _hasSpecialChar(form.values.password)
  const hasNumber = _hasNumber(form.values.password)

  const ConditionCircle = ({ condition }: { condition: boolean }) => (
    <IconCircleFilled
      size={10}
      color={
        condition
          ? colors.green[500]
          : hasTriedToSubmit
            ? colors.red[400]
            : colors.gray[400]
      }
    />
  )

  return (
    <>
      <VStack p={0} alignItems="stretch" gap={0}>
        <Heading size="2xl">Nouveau mot de passe</Heading>
        <VSpacer size={2} />
        <Text>Veuillez choisir un mot de passe avec au moins:</Text>
        <List>
          <ListItem display="flex" flexDir="row" alignItems="center" gap={2}>
            <ConditionCircle condition={isLongEnough} />
            <Text>8 caractères</Text>
          </ListItem>
          <ListItem display="flex" flexDir="row" alignItems="center" gap={2}>
            <ConditionCircle condition={hasNumber} />
            <Text>1 chiffre</Text>
          </ListItem>
          <ListItem display="flex" flexDir="row" alignItems="center" gap={2}>
            <ConditionCircle condition={hasSpecialChar} />
            <Text>1 caractère spécial</Text>
          </ListItem>
        </List>
        <VSpacer size={6} />
        <FormControl isInvalid={errors.password}>
          <FormLabel>Nouveau mot de passe</FormLabel>
          <PasswordInput
            errorBorderColor="red.500"
            placeholder="Nouveau mot de passe"
            onKeyDown={handleKeyDown}
            {...form.getInputProps("password")}
          />
          <FormErrorMessage>
            Ce mot de passe n&apos;est pas valide
          </FormErrorMessage>
          <VSpacer size={4} />
        </FormControl>
        <FormControl isInvalid={errors.confirmPassword}>
          <FormLabel>Confirmez le mot de passe</FormLabel>
          <PasswordInput
            errorBorderColor="red.500"
            placeholder="Confirmez votre mot de passe"
            onKeyDown={handleKeyDown}
            {...form.getInputProps("confirmPassword")}
          />
          <FormErrorMessage>
            Les mots de passe ne correspondent pas
          </FormErrorMessage>
        </FormControl>
      </VStack>
      <Button
        position="absolute"
        bottom={10}
        w="100%"
        onClick={handleNext}
        isDisabled={isDisabled}
        isLoading={isPending}
      >
        Continuer
      </Button>
    </>
  )
}

export const UpdatePassword = () => {
  const [isPasswordConfirmed, setIsPasswordConfirmed] = useState(false)
  const navigate = useNavigate()

  const onNext = () => {
    if (!isPasswordConfirmed) {
      setIsPasswordConfirmed(true)
      return
    }
    navigate("../success")
  }

  return !isPasswordConfirmed ? (
    <ConfirmPassword onConfirm={onNext} />
  ) : (
    <UpdatePasswordForm onNext={onNext} />
  )
}
