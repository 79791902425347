import { computeFagerstromScore } from "@bleu/shared/utils/fagerstrom"

import type { ComputePrescription } from "./types"

export const NICOTINE_SUBSTITUTION_PRESCRIPTIONS = {
  heavySmoker: {
    shortName: "Nico 2.5",
    title: "NICOPASS 2,5mg, boîte de 96 pastilles",
    description:
      "8 à 12 pastilles par jour, \n" +
      "1 pastille par prise, laisser fondre sans croquer en changeant régulièrement de côté dans la bouche\n" +
      "Ne pas dépasser 15 par jour, décroître progressivement puis arrêter, en fonction des symptômes de sevrage\n" +
      "Ne pas dépasser 6 mois de traitement\n" +
      "2 boîtes.",
    prescriptionType: "PRESCRIPTION_TREATMENT",
  },
  lightSmoker: {
    shortName: "NICOPASS 1,5mg",
    title: "NICOPASS 1,5mg, boîte de 96 pastilles",
    description:
      "4 à 12 pastilles par jour, \n" +
      "1 pastille par prise, laisser fondre sans croquer en changeant régulièrement de côté dans la bouche\n" +
      "Ne pas dépasser 30 par jour, décroître progressivement puis arrêter, en fonction des symptômes de sevrage\n" +
      "Ne pas dépasser 6 mois de traitement\n" +
      "2 boîtes.",
    prescriptionType: "PRESCRIPTION_TREATMENT",
  },
} as const

export const nicotineSubstitutionComputePrescription: ComputePrescription = ({
  nicotineSubstitution,
}) => {
  const fagerstromScore = computeFagerstromScore(nicotineSubstitution!)
  if (fagerstromScore >= 7)
    return [NICOTINE_SUBSTITUTION_PRESCRIPTIONS.heavySmoker]
  return [NICOTINE_SUBSTITUTION_PRESCRIPTIONS.lightSmoker]
}
