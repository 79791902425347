import { useForm, zodResolver } from "@mantine/form"
import { useEffect, useMemo, useState } from "react"
import { z, type ZodType } from "zod"

import type { Record } from "@bleu/types/prisma-client/runtime/library"

import {
  type AllQuestionnairesAnswers,
  type ProfileData,
  type QuestionnaireName,
  type QuestionnaireShape,
  QuestionTypes,
} from "@bleu/shared/questionnaires"

import { Questionnaire } from "@bleu/front/components/funnelSteps/Questionnaires/Questionnaire"
import QuestionnaireLayout from "@bleu/front/components/layout/QuestionnaireLayout"
import { useQuestionnaireStore } from "@bleu/front/lib/stores"
import type { StepProps } from "@bleu/front/pages/types"

const getFormInitialValuesForQuestionnaires = (
  questionnaires: QuestionnaireShape[],
  storedAnswers: AllQuestionnairesAnswers,
): AllQuestionnairesAnswers => {
  return questionnaires.reduce(
    (acc, questionnaire): AllQuestionnairesAnswers => ({
      ...acc,
      [questionnaire.name]:
        storedAnswers[questionnaire.name] || questionnaire.initialValues,
    }),
    {} as AllQuestionnairesAnswers,
  )
}

const getFormSchemaForQuestionnaires = (
  questionnaires: QuestionnaireShape[],
) => {
  const allSchemas = questionnaires.reduce(
    (acc, questionnaire): Partial<Record<QuestionnaireName, ZodType>> => {
      const newSchema = questionnaire.schema
      return { ...acc, [questionnaire.name]: newSchema }
    },
    {},
  )
  return z.object(allSchemas)
}

const getNumberFieldsForQuestionnaires = (
  questionnaires: QuestionnaireShape[],
) => {
  return questionnaires.flatMap((questionnaire) => {
    const numberQuestions = questionnaire.questions.filter(
      (question) => question.type === QuestionTypes.Number,
    )
    return numberQuestions.map(
      (question) => `${questionnaire.name}.${question.id}`,
    )
  })
}

interface Props extends StepProps {
  questionnaires: QuestionnaireShape[]
  profile: ProfileData
  onSubmit: (data: AllQuestionnairesAnswers) => void
}

export const Questionnaires = ({
  questionnaires,
  onSubmit,
  profile,
  onNext,
  onBack,
}: Props) => {
  const { answers, setAnswers } = useQuestionnaireStore()
  const [currentQuestionnaireIndex, setCurrentQuestionnaireIndex] = useState(0)

  useEffect(() => {
    // Removed console log
  }, [answers])

  useEffect(() => {
    if (questionnaires.length === 0) {
      onNext()
    }
  }, [questionnaires.length, onNext])

  const form = useForm<AllQuestionnairesAnswers>({
    initialValues: getFormInitialValuesForQuestionnaires(
      questionnaires,
      answers,
    ),
    validate: zodResolver(getFormSchemaForQuestionnaires(questionnaires)),
    validateInputOnChange: getNumberFieldsForQuestionnaires(questionnaires),
    onValuesChange: (values) => {
      // Removed console log
      setAnswers(values)
    },
  })

  const isDisabled = useMemo(() => {
    if (!questionnaires.length) return true
    return !form.isValid(questionnaires[currentQuestionnaireIndex].name)
  }, [currentQuestionnaireIndex, form, questionnaires])

  const handleClick = () => {
    if (currentQuestionnaireIndex === questionnaires.length - 1) {
      const parsedAnswers = questionnaires.reduce(
        (acc, questionnaire): AllQuestionnairesAnswers => {
          const newQuestionnaireData: AllQuestionnairesAnswers[QuestionnaireName] =
            questionnaire.schema.parse(form.values[questionnaire.name])
          return {
            ...acc,
            [questionnaire.name]: newQuestionnaireData,
          }
        },
        {},
      )
      // Removed console log
      onSubmit(parsedAnswers)
      onNext()
      return
    }
    // Removed console log
    setCurrentQuestionnaireIndex(currentQuestionnaireIndex + 1)
  }

  const handleBack = () => {
    if (currentQuestionnaireIndex === 0) {
      // Removed console log
      onBack()
    } else {
      // Removed console log
      setCurrentQuestionnaireIndex((prev) => prev - 1)
    }
  }

  if (!questionnaires.length) return null
  return (
    <>
      <Questionnaire
        onBack={handleBack}
        onEmptyQuestionnaire={handleClick}
        progress={(currentQuestionnaireIndex / questionnaires.length) * 20 + 60}
        profile={profile}
        questionnaire={questionnaires[currentQuestionnaireIndex]}
        form={form}
      />
      <QuestionnaireLayout.CTA onClick={handleClick} isDisabled={isDisabled}>
        Continuer
      </QuestionnaireLayout.CTA>
    </>
  )
}
