import { Stack, HStack, Heading, Progress } from "@chakra-ui/react"

import { BackButton } from "@bleu/front/components/layout/BackButton"

export interface LayoutHeaderProps {
  onBack: () => void
  pageName?: string
  progress?: number
}

export const LayoutHeader = ({
  onBack,
  pageName,
  progress,
}: LayoutHeaderProps) => {
  return (
    <Stack gap={6} marginBottom={4} width="100%">
      <HStack alignItems="center" justifyContent="flex-start" gap={6}>
        <BackButton onClick={onBack} />
        {pageName && <Heading size="sm">{pageName}</Heading>}
      </HStack>
      {progress !== undefined && (
        <Progress value={progress} borderRadius="10px" height="5px" />
      )}
    </Stack>
  )
}
