import { Box } from "@chakra-ui/react"

interface SpacerProps {
  size: number
}

export const VSpacer = ({ size }: SpacerProps) => {
  return <Box height={size} width="100 %" />
}
export const Spacer = VSpacer

export const HSpacer = ({ size }: SpacerProps) => {
  return <Box width={size} height="100%" />
}
