import {
  Link as ChakraLink,
  Heading,
  Button,
  Image,
  Text,
} from "@chakra-ui/react"
import { Link } from "react-router-dom"

import QuestionnaireLayout from "@bleu/front/components/layout/QuestionnaireLayout"
import { Spacer } from "@bleu/front/components/layout/Spacer"

export const NotFoundPage = () => (
  <QuestionnaireLayout>
    <QuestionnaireLayout.Content>
      <Image
        src="/img/404-monster.png"
        alt="404"
        maxW={350}
        alignSelf="center"
      />
      <Spacer size={6} />
      <Heading size="3xl" textAlign="center">
        Nous sommes navrés, mais cette page n&apos;existe pas.
      </Heading>
      <Spacer size={16} />
      <Button size="lg" as={Link} to="/">
        Retour à l&apos;accueil
      </Button>
      <Spacer size={6} />
      <Text size="sm" textAlign="center">
        Si le problème persiste, contactez-nous à<br />
        <ChakraLink href="mailto:contact@bleu.care">
          contact@bleu.care
        </ChakraLink>
      </Text>
    </QuestionnaireLayout.Content>
  </QuestionnaireLayout>
)
