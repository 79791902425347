import { tableAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys)

const rounded = definePartsStyle({
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 8px",
  },
  tr: {
    marginBottom: 8,
    "th:first-child, td:first-child": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
    "th:last-child, td:last-child": {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
    },
  },
  thead: {
    tr: {
      color: "navy.300",
      background: "blue.50",
    },
    th: {
      marginBottom: 8,
    },
  },
  tbody: {
    tr: {
      margin: 8,
      background: "white",
    },
    td: {
      fontSize: "sm",
    },
  },
})

export const Table = defineMultiStyleConfig({
  variants: { rounded },
})
