import { Box, Stack, Text } from "@chakra-ui/react"
import dayjs from "dayjs"

import { Document } from "@bleu/types/prisma-client"

import { useFileSignedUrlQuery } from "@bleu/front/queries/files"

type Props = {
  doc: Document
}

export const PatientDocument = ({ doc }: Props) => {
  const { data: docSignedUrl } = useFileSignedUrlQuery(doc.filePath)

  return (
    <Stack
      gap={0}
      cursor="pointer"
      onClick={() => window.open(docSignedUrl?.signedUrl, "_blank")}
      position="relative"
    >
      <Box
        w={100}
        bgColor="blue.200"
        borderTopLeftRadius={20}
        borderTopRightRadius={10}
        h={4}
      />
      <Stack
        w={170}
        px={4}
        py={3}
        bgColor="blue.200"
        borderBottomRadius={20}
        borderTopRightRadius={20}
      >
        <Text size="sm" fontWeight={600}>
          {doc.name}
        </Text>
        <Text size="xs">
          Ajouté le {dayjs(doc.createdAt).format("DD/MM/YYYY")}
        </Text>
      </Stack>
    </Stack>
  )
}
