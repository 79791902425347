import { z } from "zod"

import type { FormValues } from "@bleu/types/form"

import { BreastCancerCurrentSituationOption } from "@bleu/shared/questionnaires"
import { shouldShow } from "@bleu/shared/utils/shouldShow"

import { QuestionTypes, QuestionnairesNames } from "./enums"
import type { BaseQuestionnaireShape } from "./types"

export const breastCancerSchema = z.object({
  currentSituation: z.nativeEnum(BreastCancerCurrentSituationOption),
})

const initialValues: BreastCancerFormValues = {
  currentSituation: undefined,
}

const QuestionNames = {
  currentSituation: "currentSituation",
} as const

type QuestionName = keyof typeof QuestionNames

export type BreastCancerData = z.infer<typeof breastCancerSchema>
type BreastCancerFormValues = FormValues<BreastCancerData>

export type BreastCancerQuestionnaireShape = BaseQuestionnaireShape<
  QuestionName,
  BreastCancerData,
  BreastCancerFormValues
>
export const breastCancerQuestionnaire: BreastCancerQuestionnaireShape = {
  name: QuestionnairesNames.breastCancer,
  questions: [
    {
      id: QuestionNames.currentSituation,
      shouldShow,
      type: QuestionTypes.SingleSelect,
      title: "Etes-vous dans l'une ou plusieurs des situations suivantes ?",
      options: [
        {
          label:
            "J'ai un des antécédents personnels suivants: antécédent de cancer du sein (y compris carcinome canalaire in situ), hyperplasie atypique canalaire ou lobulaire, irradiation thoracique à haute dose",
          value: BreastCancerCurrentSituationOption.PERSONAL_HISTORY,
        },
        {
          label:
            "J’ai un des antécédents familiaux suivants (famille proche : mère, père, sœur, frère, enfant) : antécédent de cancers du sein ou de l'ovaire chez les femmes, ou de cancer du sein chez les hommes",
          value: BreastCancerCurrentSituationOption.PERSONAL_HISTORY,
        },
        {
          label:
            "Altération des gènes BRCA 1 ou BRCA 2, personnelle ou dans la famille proche",
          value: BreastCancerCurrentSituationOption.GENES_ALTERATION,
        },
        {
          label:
            "Je suis dans une situation caractérisée par une sensibilité accrue aux rayons X ou caractérisée par une pré exposition importante aux rayonnements ionisants (notamment, répétition d'actes d'imagerie ou exposition professionnelle).",
          value: BreastCancerCurrentSituationOption.RADIATION_SENSITIVITY,
        },
        {
          label: "Non, aucune des situations ci-dessus",
          value: BreastCancerCurrentSituationOption.NONE,
          unique: true,
        },
      ],
    },
  ],
  schema: breastCancerSchema,
  initialValues,
} as const
