import { EncryptionSession } from "@seald-io/sdk/lib/main"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"

import { FileResponses } from "@bleu/types/endpoints/index"
import {
  MedicalRecordRequests,
  MedicalRecordResponses,
} from "@bleu/types/endpoints/medicalRecords"
import { Paginated } from "@bleu/types/pagination"
import { MedicalRecord } from "@bleu/types/prisma-client"

import { PrescriptionType } from "@bleu/shared/prescriptions/types"

import { apiClient } from "@bleu/front/lib/apiClient"
import { queryClient } from "@bleu/front/lib/queryClient"
import { usePostMessageMutation } from "@bleu/front/queries/seald"

export const useListMedicalRecordsQuery = (
  page: number,
  searchQuery: string,
  filterKey = "all", // TODO: type it better?
) => {
  return useQuery({
    queryKey: ["medical-records", { page, searchQuery, filterKey }],
    queryFn: async () => {
      const searchParams = new URLSearchParams({
        page: page.toString(),
        searchQuery,
        filterKey,
      })
      const response = await apiClient.get<
        Paginated<MedicalRecordResponses.MedicalRecord>
      >(`/medical-records?${searchParams.toString()}`)
      return response.data
    },
  })
}

export const useCreateMedicalRecordMutation = () => {
  return useMutation({
    mutationFn: async (data: MedicalRecordRequests.AnswerQuestionnaire) => {
      const response = await apiClient.post<MedicalRecord>(
        "/medical-records",
        data,
      )
      return response.data
    },
    // TODO: handle error management
  })
}

export const useUploadPrescriptionMutation = (medicalRecordId: string) => {
  return useMutation({
    mutationFn: async (args: {
      blob: Blob
      patientId: string
      doctorId: string
      prescriptionType: PrescriptionType
      filename: string
    }) => {
      // Replace "/" with "-" in the filename to avoid creating subfolders
      const file = new File([args.blob], args.filename.replace(/\//g, "-"), {
        type: "application/pdf",
      })

      const form = new FormData()
      form.append("file", file)
      form.append("prescriptionType", args.prescriptionType)
      form.append("doctorId", args.doctorId)

      const response = await apiClient.post<FileResponses.UploadedFile>(
        `/medical-records/${medicalRecordId}/prescription`,
        form,
      )

      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["medical-records", medicalRecordId],
      })
    },
    // TODO: handle error management
  })
}

/**
 * Custom hook to fetch medical record page data
 * @param medicalRecordId - The ID of the medical record
 * @returns Query result containing medical record page data
 */
export const useMedicalRecordPageDataQuery = (medicalRecordId: string) => {
  return useQuery({
    queryKey: ["medical-records", medicalRecordId],
    queryFn: async () => {
      if (!medicalRecordId) {
        throw new Error("Medical record ID is required")
      }

      try {
        const response =
          await apiClient.get<MedicalRecordResponses.MedicalRecordPageData>(
            `/medical-records/${medicalRecordId}`,
          )
        return response.data
      } catch (error) {
        console.error("Error fetching medical record data:", error)
        throw error
      }
    },
    enabled: Boolean(medicalRecordId),
  })
}

export const useMedicalRecordAnswersQuery = (medicalRecordId: string) => {
  return useQuery({
    queryKey: ["medical-records-answers", medicalRecordId],
    queryFn: async () => {
      const response =
        await apiClient.get<MedicalRecordResponses.MedicalRecordAnswersPageData>(
          `/medical-records/${medicalRecordId}/answers`,
        )
      return response.data
    },
  })
}

export const useUploadLabResultsMutation = (medicalRecordId: string) => {
  return useMutation({
    mutationFn: async ({
      file,
      password,
      doctorId,
    }: {
      file: File | null
      password?: string
      doctorId: string
    }) => {
      if (!file) {
        return
      }

      const form = new FormData()
      form.append("file", file)
      form.append("doctorId", doctorId)
      if (password) {
        form.append("password", password)
      }

      const response = await apiClient.post<FileResponses.UploadedFile>(
        `/medical-records/${medicalRecordId}/lab-results`,
        form,
      )

      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["medical-records", medicalRecordId],
      })
    },
    // TODO: handle error management
  })
}

type LabResultAnalysisInput = {
  status: MedicalRecordRequests.LabResultAnalysis["status"]
  analysis: string
}

export const useAnalyseLabResultMutation = (
  patientId: string,
  medicalRecordId: string,
  documentId: string,
  conversationId: string,
  sealdSession: EncryptionSession,
) => {
  const navigate = useNavigate()
  const { mutateAsync: postMessage } = usePostMessageMutation(
    conversationId,
    sealdSession,
  )

  return useMutation({
    mutationFn: async ({ status, analysis }: LabResultAnalysisInput) => {
      const formattedMessage = `Analyse de vos résultats d'examen:\n\n${analysis}`
      await postMessage(formattedMessage)

      const response = await apiClient.post(
        `/medical-records/${medicalRecordId}/lab-results/${documentId}/analysis`,
        { status },
      )

      return response.data
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["medical-records", medicalRecordId],
      })
      await queryClient.invalidateQueries({
        queryKey: ["conversations", conversationId],
      })
      navigate(`/doctor/patients/${patientId}/records/${medicalRecordId}`)
    },
    // TODO: handle error management
  })
}

export const useCloseMedicalRecordMutation = (medicalRecordId: string) => {
  return useMutation({
    mutationFn: async () => {
      const response = await apiClient.post(
        `/medical-records/${medicalRecordId}/close`,
      )

      return response.data
    },
    onSuccess: async () => {
      // TODO: currently the page is reloaded to update the UI, because invalidating cache does not update the left menu on the patient file doctor view
      window.location.reload()
    },
    // TODO: handle error management
  })
}

export const useUpdateScore2Mutation = (medicalRecordId: string) => {
  return useMutation({
    mutationFn: async (args: {
      tension: number
      hdlCholesterol: number
      totalCholesterol: number
    }) => {
      const response = await apiClient.put<MedicalRecordResponses.UpdateScore2>(
        `/medical-records/${medicalRecordId}/score2`,
        args,
      )
      return response.data.score2
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["medical-records", medicalRecordId],
      })
    },
  })
}
