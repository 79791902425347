export function formatPrice(price: number): string {
  return new Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(price)
}

export function formatDate(date: string): string {
  return new Date(date).toLocaleDateString()
}
