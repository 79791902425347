export enum VerticalName {
  GENERAL = "general",
  STI = "sti",
  CARDIO = "cardio",
  CANCER = "cancer",
  SUBSTITUTES = "substitutes",
}

export type VerticalNameWithoutGeneral = Exclude<VerticalName, "general">

export const allVerticalNameList = Object.values(VerticalName)

export const VerticalsLongNames: Record<VerticalName, string> = {
  [VerticalName.CARDIO]: "Risques cardio-métaboliques",
  [VerticalName.CANCER]: "Dépistage du cancer",
  [VerticalName.STI]: "Infections sexuellement transmissibles",
  [VerticalName.SUBSTITUTES]: "Substituts & supplémentation",
  [VerticalName.GENERAL]: "Bilan général",
}
