import { type BadgeProps, Badge } from "@chakra-ui/react"

export const StatusBadgeStatuses = {
  verification: "Vérification",
  archived: "Archivé",
  analyze: "Ordonnance dispo",
  analyzed: "Analysé",
} as const

export type StatusBadgeStatus =
  (typeof StatusBadgeStatuses)[keyof typeof StatusBadgeStatuses]

const statusToBadgeProps: Record<StatusBadgeStatus, BadgeProps> = {
  [StatusBadgeStatuses.verification]: {
    colorScheme: "yellow",
    children: "Vérification",
  },
  [StatusBadgeStatuses.archived]: {
    colorScheme: "gray",
    children: "Archivé",
  },
  [StatusBadgeStatuses.analyze]: {
    colorScheme: "green",
    children: "Ordonnance dispo",
  },
  [StatusBadgeStatuses.analyzed]: {
    colorScheme: "blue",
    children: "Analysé",
  },
}

interface StatusBadgeProps {
  status: StatusBadgeStatus
}

export const StatusBadge = ({ status }: StatusBadgeProps) => {
  return (
    <Badge
      {...statusToBadgeProps[status]}
      px={2}
      py={1}
      borderRadius={30}
      fontSize="xs"
    />
  )
}
