export const HIGH_BLOOD_PRESSURE_PRESSURE = {
  hta: {
    shortName: "HTA",
    title: "Prise de tension artérielle",
    description:
      "Cher(e) ami(e),\n" +
      "\n" +
      "Merci de recevoir mon patient, pour réaliser une mesure de pression artérielle à l’officine, dans le cadre d’un dépistage systématique.\n" +
      "\n" +
      "Merci\n",
    prescriptionType: "PRESCRIPTION_ADDRESSING",
  },
} as const

export const highBloodPressureComputePrescription = () => [
  HIGH_BLOOD_PRESSURE_PRESSURE.hta,
]
