import { type ButtonProps, Button, forwardRef, Box } from "@chakra-ui/react"
import { IconMessageChatbot } from "@tabler/icons-react"
import type { MouseEventHandler } from "react"
import { useNavigate } from "react-router-dom"

import { colors } from "@bleu/front/assets/theme/colors"

interface ChatButtonProps extends ButtonProps {
  hasNewMessage: boolean
  href: string
}

export const ChatButton = forwardRef<ChatButtonProps, "button">(
  ({ hasNewMessage, isDisabled, href, ...props }, ref) => {
    const navigate = useNavigate()
    const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
      e.stopPropagation()
      navigate(href)
    }
    return (
      <Button
        bgColor={isDisabled ? "gray.200" : "blue.200"}
        borderRadius={8}
        width={12}
        height={12}
        aria-label="Conversation"
        onClick={handleClick}
        ref={ref}
        css={{ padding: "8px !important" }}
        isDisabled={isDisabled}
        {...props}
      >
        <IconMessageChatbot
          size={24}
          color={isDisabled ? colors.gray[400] : colors.blue[400]}
        />
        {hasNewMessage && (
          <Box
            bg="red.400"
            width={2.5}
            h={2.5}
            borderRadius={10}
            top={-0.5}
            right={-0.5}
            position="absolute"
          />
        )}
      </Button>
    )
  },
)
