import { Doctor } from "@bleu/types/prisma-client"
import { zPassword, zPhone } from "@bleu/shared/forms/validation"
import { z } from "zod"
import { User } from "@bleu/types/user"

export const newDoctorSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  loginEmail: z.string().email(),
  contactEmail: z.string().email(),
  password: zPassword,
  phoneNumber: zPhone,
  adeliNumber: z.string(),
  rppsNumber: z.string(),
  ordinalNumber: z.string(),
  diploma: z.string(),
  signatureImgBase64: z.string(),
})

export namespace DoctorRequests {
  export type NewDoctor = z.infer<typeof newDoctorSchema>
}

export namespace DoctorResponses {
  export type StatBlock = {
    key: string
    label: string
    value: number
  }

  export type Stats = {
    blocks: StatBlock[]
    countByStatus: StatBlock[]
  }

  export type NewDoctor = User

  export type DoctorInfo = Doctor
}
