import { Box, Heading, HStack, Stack } from "@chakra-ui/react"
import {
  IconCircleCheckFilled,
  IconCircleXFilled,
  IconClipboard,
} from "@tabler/icons-react"
import { useMemo } from "react"

import { DepistageName, depistages } from "@bleu/shared/depistages/index"

import { colors } from "@bleu/front/assets/theme/colors"
import { InfoBox } from "@bleu/front/components/InfoBox"

type Props = {
  despistageName: DepistageName
  isPrescribed: boolean
}

export const PrescriptionRecapItem = ({
  despistageName,
  isPrescribed,
}: Props) => {
  const { longName, prescriptionInfo } = useMemo(
    () => depistages[despistageName],
    [despistageName],
  )

  return (
    <Stack w="100%" gap={4}>
      <HStack gap={3} paddingRight={8} alignItems="flex-start">
        <Box boxSize={6}>
          {isPrescribed ? (
            <IconCircleCheckFilled color={colors.green[400]} size={24} />
          ) : (
            <IconCircleXFilled color={colors.gray[400]} size={24} />
          )}
        </Box>
        <Heading
          size="md"
          paddingTop={0.5} // align with icon
          {...(!isPrescribed && { color: "navy.300" })}
        >
          {longName}
        </Heading>
      </HStack>
      {prescriptionInfo && isPrescribed && (
        <InfoBox
          isOpen
          isCollapsible
          title={prescriptionInfo.title}
          message={prescriptionInfo.description}
          sources={prescriptionInfo.sources}
          icon={IconClipboard}
        />
      )}
    </Stack>
  )
}
