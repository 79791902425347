import {
  Text,
  Box,
  Button,
  VStack,
  Icon,
  HStack,
  useBreakpoint,
} from "@chakra-ui/react"
import { IconArrowRight } from "@tabler/icons-react"
import { Link } from "react-router-dom"

import {
  type VerticalName,
  VerticalsLongNames,
  allVerticalNameList,
} from "@bleu/shared/verticals"

import { verticalsTheme } from "@bleu/front/assets/theme/verticalsTheme"

interface VerticalCardProps {
  verticalName: VerticalName
}

const VerticalCard = ({ verticalName }: VerticalCardProps) => (
  <Button
    as={Link}
    to={`/questionnaire?vertical=${verticalName}`}
    flexShrink={0}
    style={{ padding: "32px 16px !important" }}
    display="block"
    width={["42dvw", 170]}
    height={["42dvw", 170]}
    borderRadius={30}
    background={verticalsTheme[verticalName].verticalButtonGradient}
    position="relative"
  >
    <Box
      bg="white"
      top={3}
      right={3}
      position="absolute"
      borderRadius={999}
      transform="rotate(-45deg)"
    >
      <IconArrowRight size={40} color="black" strokeWidth={1} />
    </Box>
    <VStack
      bottom={0}
      left={0}
      padding={5}
      position="absolute"
      alignItems="flex-start"
      gap={4}
      w={170}
      style={{ textWrap: "wrap" }}
    >
      <Icon
        as={verticalsTheme[verticalName].icon}
        boxSize={5}
        color={verticalsTheme[verticalName].color}
      />
      <Text fontWeight={600} textAlign="left">
        {VerticalsLongNames[verticalName]}
      </Text>
    </VStack>
  </Button>
)

export const VerticalsCards = () => {
  const breakpoint = useBreakpoint()

  const Cards = () =>
    allVerticalNameList.map((verticalName) => (
      <VerticalCard key={verticalName} verticalName={verticalName} />
    ))

  if (breakpoint === "base") {
    return (
      <HStack
        gap={3}
        overflowX="scroll"
        style={{ scrollbarWidth: "none" }}
        marginX={-5}
        paddingX={5}
      >
        <Cards />
      </HStack>
    )
  }

  return (
    <HStack flexWrap="wrap" gap={6}>
      <Cards />
    </HStack>
  )
}
