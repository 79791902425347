export const DepistagesNames = {
  abdominalAorticAneurysm: "abdominalAorticAneurysm",
  breastCancer: "breastCancer",
  cervicalCancer: "cervicalCancer",
  colorectalCancer: "colorectalCancer",
  diabetes: "diabetes",
  b9Supplements: "b9Supplements",
  highBloodPressure: "highBloodPressure",
  dyslipidemia: "dyslipidemia",
  nicotineSubstitution: "nicotineSubstitution",
  score2: "score2",
  sti: "sti",
} as const

export type DepistageName = keyof typeof DepistagesNames

export enum DepistagesLongNames {
  abdominalAorticAneurysm = "Dépistage de l'anévrisme de l'aorte abdominale",
  b9Supplements = "Supplémentation en acide folique",
  breastCancer = "Dépistage du cancer du sein",
  cervicalCancer = "Dépistage du cancer du col de l'utérus",
  colorectalCancer = "Dépistage du cancer colo-rectal",
  diabetes = "Dépistage du pré-diabète et diabète",
  dyslipidemia = "Dépistage des anomalies lipidiques",
  hivSyphilisHepatite = "Dépistage du VIH, de la syphilis et des hépatites",
  highBloodPressure = "Dépistage de l'hypertension artérielle",
  nicotineSubstitution = "Substituts nicotiniques",
  score2 = "Calcul de votre risque cardiovasculaire",
  sti = "Dépistage des infections sexuellement transmissibles",
}
