import { tabsAnatomy } from "@chakra-ui/anatomy"
import {
  createMultiStyleConfigHelpers,
  PartsStyleInterpolation,
} from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

const baseStyle: PartsStyleInterpolation = definePartsStyle((props) => {
  const isVertical = props.orientation === "vertical"
  const borderProp = isVertical ? "borderLeft" : "borderBottom"
  return {
    tab: {
      textAlign: isVertical ? "left" : "center",
      [borderProp]: "2px solid",
      _selected: {
        color: "gray.800",
        [`${borderProp}Color`]: "blue.400 !important",
        fontWeight: 600,
        ".chakra-text": {
          fontWeight: 600,
        },
      },
      ".chakra-text": {
        color: "gray.800",
      },
    },
    tablist: {
      [`${borderProp}Color`]: "blue.300 !important",
    },
  }
})

const sizes = {
  sm: { tab: { fontSize: "xs", py: 2 } },
  md: { tab: { fontSize: "sm" } },
}

// export the component theme
export const Tabs = defineMultiStyleConfig({ baseStyle, sizes })
