import {
  Page,
  View,
  StyleSheet,
  Text,
  Document,
  Image,
  Font,
} from "@react-pdf/renderer"
import dayjs from "dayjs"

import { Doctor } from "@bleu/types/prisma-client"
import { User } from "@bleu/types/user"

import {
  Prescription,
  PRESCRIPTION_TYPES,
  PrescriptionType,
} from "@bleu/shared/prescriptions/types"

import { colors } from "@bleu/front/assets/theme/colors"

type Props = {
  prescriptionType: PrescriptionType
  prescriptions: Prescription[]
  patient: User
  patientAge: number
  doctor: User
  doctorInfo: Doctor
}

export const PrescriptionPreview = ({
  prescriptionType,
  prescriptions,
  patient,
  patientAge,
  doctorInfo,
  doctor,
}: Props) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.body}>
          <View>
            <Image src="/img/logo.png" style={styles.logo} />
            <View style={styles.header}>
              <View style={styles.headerColumn}>
                <Text style={styles.title}>Dr {doctor.fullName}</Text>
                <Text style={styles.smallText}>{doctorInfo.contactEmail}</Text>
                <View>
                  <Text style={styles.smallText}>
                    N° RPPS : {doctorInfo.rppsNumber}
                  </Text>
                  <Text style={styles.smallText}>
                    N° Ordinal : {doctorInfo.ordinalNumber}
                  </Text>
                  <Text style={styles.smallText}>
                    N° AM : {doctorInfo.adeliNumber}
                  </Text>
                </View>
                <Text style={styles.smallText}>{doctorInfo.diploma}</Text>
              </View>
              <View style={styles.headerColumn}>
                <Text style={styles.smallText}>
                  Le {dayjs().format("DD MMMM YYYY")},
                </Text>
                <View>
                  <Text style={[styles.smallText, styles.bold]}>
                    {patient.fullName}
                  </Text>
                  <Text style={styles.smallText}>{patientAge} ans</Text>
                </View>
              </View>
            </View>
            <View style={styles.prescriptions}>
              <Text style={[styles.bold, styles.prescriptionType]}>
                {PRESCRIPTION_TYPES[prescriptionType]}
              </Text>
              {prescriptions.map((prescription) => (
                <View key={prescription.title} style={styles.prescription}>
                  {prescription.title && (
                    <Text style={[styles.text, styles.bold]}>
                      {prescription.title}
                    </Text>
                  )}
                  <Text style={styles.smallText}>
                    {prescription.description}
                  </Text>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.footer}>
            <Text style={styles.smallText}>Dr {doctor.fullName}</Text>
            <Image
              src={doctorInfo.signatureImgBase64}
              style={styles.signature}
            />
            <Text style={styles.smallText}>
              En cas d&apos;urgence appeler le 15
            </Text>
            <Text style={styles.smallText}>
              Professionnels de santé uniquement : 07 56 97 71 82
            </Text>
          </View>
        </View>
        {/*<Image src="/img/dots-pdf.png" style={styles.background} />*/}
      </Page>
    </Document>
  )
}

const styles = StyleSheet.create({
  page: {
    width: "100%",
    fontFamily: "Manrope",
    fontWeight: 400,
    position: "relative",
    flexDirection: "row",
    background: "transparent",
  },
  background: {
    position: "absolute",
    objectFit: "cover",
    top: 0,
    left: 0,
    minWidth: "100%",
    minHeight: "100%",
    height: "100%",
    width: "100%",
    zIndex: -1,
  },
  logo: {
    width: 75,
    height: 24,
    marginBottom: 24,
  },
  body: {
    width: "100%",
    paddingVertical: 60,
    paddingHorizontal: 70,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: 40,
  },
  headerColumn: {
    flexDirection: "column",
    gap: 12,
  },
  footer: {
    alignSelf: "flex-end",
    alignItems: "flex-end",
  },
  signature: {
    width: 120,
    height: 100,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  text: {
    fontSize: 10,
  },
  smallText: {
    fontSize: 8,
  },
  bold: {
    fontWeight: 600,
  },
  prescriptions: {
    flexDirection: "column",
    gap: 12,
  },
  prescription: {
    flexDirection: "column",
    gap: 4,
  },
  prescriptionType: {
    fontSize: 12,
    color: colors.gray[500],
  },
})

Font.register({
  family: "Manrope",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk79FO_F87jxeN7B.ttf",
      fontWeight: 400,
    },
    {
      src: "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk7PFO_F87jxeN7B.ttf",
      fontWeight: 500,
    },
    {
      src: "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_F87jxeN7B.ttf",
      fontWeight: 600,
    },
  ],
})
