import {
  Text,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightAddon,
} from "@chakra-ui/react"

import { colors } from "@bleu/front/assets/theme/colors"
import type { QuestionProps } from "@bleu/front/components/forms/types"
import { VSpacer } from "@bleu/front/components/layout/Spacer"

interface NumberQuestionProps extends QuestionProps<number> {
  unit?: string
  title: string
}

export const NumberQuestion = ({
  title,
  value,
  unit,
  onChange,
  error,
  ...props
}: NumberQuestionProps) => {
  return (
    <FormControl isInvalid={!!error}>
      <Text fontWeight={600}>{title}</Text>
      <VSpacer size={2} />
      <InputGroup>
        <Input
          type="number"
          value={value ?? ""}
          border={`1px solid ${colors.blue[300]}`}
          borderRadius={4}
          {...props}
          onChange={(e) => onChange(parseFloat(e.target.value))}
        />
        {unit && (
          <InputRightAddon border={`1px solid ${colors.blue[300]}`}>
            {unit}
          </InputRightAddon>
        )}
      </InputGroup>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  )
}
