import { z } from "zod"

import type { FormValues } from "@bleu/types/form"

import { shouldShow } from "@bleu/shared/utils/shouldShow"

import { QuestionTypes, QuestionnairesNames } from "./enums"
import type { BaseQuestionnaireShape } from "./types"

enum QuestionNames {
  hasConstants = "hasConstants",
  tension = "tension",
  totalCholesterol = "totalCholesterol",
  hdlCholesterol = "hdlCholesterol",
}

// Source: https://bleuespace.slack.com/archives/C061PBUHWMD/p1717482201381029
export const score2ConstantsSchema = z.object({
  [QuestionNames.tension]: z
    .number()
    .gte(100, "Une mesure de tension ne peut être inférieure à 100 mmHg")
    .lt(230, "Une mesure de tension ne peut être supérieure à 230 mmHg"),
  [QuestionNames.totalCholesterol]: z
    .number()
    .gte(3, "Le taux de cholestérol total ne peut être inférieur à 3 mmol/L")
    .lt(10, "Le taux de cholestérol total ne peut être supérieur à 10 mmol/L"),
  [QuestionNames.hdlCholesterol]: z
    .number()
    .gte(0.6, "Le taux de cholestérol HDL ne peut être inférieur à 0.6 mmol/L")
    .lt(2, "Le taux de cholestérol HDL ne peut être supérieur à 2 mmol/L"),
})

export const score2Schema = z.discriminatedUnion(QuestionNames.hasConstants, [
  score2ConstantsSchema.extend({
    [QuestionNames.hasConstants]: z.literal(true),
  }),
  z.object({
    [QuestionNames.hasConstants]: z.literal(false),
  }),
])

const initialValues: Score2FormValues = {
  [QuestionNames.hasConstants]: undefined,
  [QuestionNames.tension]: undefined,
  [QuestionNames.totalCholesterol]: undefined,
  [QuestionNames.hdlCholesterol]: undefined,
}

type QuestionName = keyof typeof QuestionNames

export type Score2Data = z.infer<typeof score2Schema>
type Score2FormValues = FormValues<Score2Data>

export type Score2QuestionnaireShape = BaseQuestionnaireShape<
  QuestionName,
  Score2Data,
  Score2FormValues
>
export const score2Questionnaire: Score2QuestionnaireShape = {
  name: QuestionnairesNames.score2,
  questions: [
    {
      id: QuestionNames.hasConstants,
      type: QuestionTypes.SingleSelect,
      shouldShow,
      title:
        "Connaissez-vous votre pression artérielle systolique et votre taux de non-HDL cholestérol ?",
      options: [
        { label: "Oui", value: true },
        {
          label: "Non, je souhaite réaliser les examens",
          value: false,
        },
      ],
    },
    {
      id: QuestionNames.tension,
      type: QuestionTypes.Number,
      shouldShow: ({ hasConstants }) => !!hasConstants,
      title: "Pression artérielle systolique",
      unit: "mmHg",
    },
    {
      id: QuestionNames.totalCholesterol,
      type: QuestionTypes.Number,
      shouldShow: ({ hasConstants }) => !!hasConstants,
      title: "Concentration sanguine de cholestérol",
      unit: "mmol/L",
    },
    {
      id: QuestionNames.hdlCholesterol,
      type: QuestionTypes.Number,
      shouldShow: ({ hasConstants }) => !!hasConstants,
      title: "Concentration sanguine de cholestérol HDL ",
      unit: "mmol/L",
    },
  ],
  schema: score2Schema,
  initialValues,
} as const
