import type { SVGProps } from "react"

export const Logo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={24}
    viewBox="0 0 75 24"
    {...props}
  >
    <path
      fill="#19154E"
      d="M29.543 20.823V4.583h6.496q1.737 0 2.809.699 1.07.687 1.567 1.736.497 1.05.497 2.12 0 1.365-.62 2.268a2.93 2.93 0 0 1-1.681 1.206v-.563q1.522.315 2.278 1.41.767 1.093.767 2.447 0 1.455-.541 2.56a3.94 3.94 0 0 1-1.625 1.737q-1.094.62-2.751.62zm3.113-2.887h3.767q.62 0 1.105-.26.485-.27.756-.744.282-.485.282-1.14 0-.575-.237-1.025a1.7 1.7 0 0 0-.722-.711q-.474-.27-1.184-.27h-3.767zm0-7.015h3.338q.519 0 .925-.18.406-.181.643-.553.237-.384.237-.982 0-.744-.463-1.252-.462-.507-1.342-.507h-3.338zM43.68 20.823V4.243h3.067v16.58zM55.34 21.161q-1.873 0-3.305-.8a5.9 5.9 0 0 1-2.233-2.222Q49 16.717 49 14.89q0-1.997.79-3.474.789-1.477 2.176-2.29 1.387-.823 3.192-.823 1.916 0 3.26.902 1.342.903 1.984 2.538.644 1.635.451 3.846H57.82v-1.128q0-1.86-.597-2.673-.587-.823-1.929-.823-1.568 0-2.312.958-.733.948-.733 2.809 0 1.703.733 2.639.744.924 2.177.925.901 0 1.545-.395.642-.395.981-1.14l3.068.88q-.688 1.67-2.177 2.595a6 6 0 0 1-3.237.924M51.3 15.59v-2.278h8.076v2.278zM67.484 21.184q-1.432 0-2.335-.485t-1.41-1.23a5.2 5.2 0 0 1-.71-1.545 8 8 0 0 1-.26-1.477 16 16 0 0 1-.045-.97V8.642h3.113v5.752q0 .418.045 1.072.045.642.282 1.297t.767 1.094q.54.44 1.523.44.395 0 .845-.124.451-.125.846-.474.395-.36.643-1.049.26-.699.26-1.827l1.759.835q0 1.443-.587 2.706a4.84 4.84 0 0 1-1.77 2.042q-1.172.777-2.966.778m3.947-.361v-4.038h-.383V8.642h3.09v12.181z"
    />
    <path
      fill="#008DEF"
      d="m12.035 23.3-.52-.348c-2.476-1.659-5.608-3.534-7.927-6.29C1.143 13.758-.03 10.776 0 7.544.037 3.935 2.92 1 6.428 1c2.783 0 4.634 1.626 5.607 2.8.973-1.174 2.824-2.8 5.608-2.8 3.508 0 6.39 2.935 6.427 6.542.032 3.234-1.14 6.214-3.587 9.118-2.32 2.758-5.452 4.633-7.927 6.292z"
    />
  </svg>
)
