import {
  Icon,
  Text,
  Divider,
  HStack,
  Switch,
  VStack,
  Link,
} from "@chakra-ui/react"

import { type DepistageName } from "@bleu/shared/depistages"
import type { Depistage } from "@bleu/shared/depistages/types"
import { type ProfileData } from "@bleu/shared/questionnaires"
import { computeBodyMassIndexFromProfile } from "@bleu/shared/questionnaires/utils"
import {
  VerticalsLongNames,
  VerticalName,
  allVerticalNameList,
  getDepistagesForVertical,
  type VerticalNameWithoutGeneral,
} from "@bleu/shared/verticals"

import { verticalsTheme } from "@bleu/front/assets/theme/verticalsTheme"
import { InfoBox } from "@bleu/front/components/InfoBox"
import { Bold } from "@bleu/front/components/layout/Bold"
import QuestionnaireLayout from "@bleu/front/components/layout/QuestionnaireLayout"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { useDepistagesStore } from "@bleu/front/lib/stores"
import type { StepProps } from "@bleu/front/pages/types"

type GetRelevantDepistagesForProfile = (profile: ProfileData) => {
  depistage: Depistage
  vertical: VerticalNameWithoutGeneral
}[]

/**
 * Take all depistages, filter out the ones that are not relevant to the profile
 * and return their vertical and depistage names
 */
const getRelevantDepistagesForProfile: GetRelevantDepistagesForProfile = (
  profile,
) =>
  (
    allVerticalNameList.filter(
      (verticalName) => verticalName !== VerticalName.GENERAL,
    ) as VerticalNameWithoutGeneral[]
  ).flatMap((vertical) =>
    getDepistagesForVertical(vertical)
      .filter((depistage) => depistage.isRelevantToProfile(profile))
      .map((depistage) => ({ vertical, depistage })),
  )

interface Props extends StepProps {
  profile: ProfileData
}

export const DepistagesSelection = ({ profile, onNext, onBack }: Props) => {
  const { excludedDepistages, setExcludedDepistages } = useDepistagesStore()

  const handleToggleDepistage = (depistage: DepistageName) => {
    setExcludedDepistages(
      excludedDepistages.includes(depistage)
        ? excludedDepistages.filter((d) => d !== depistage)
        : [...excludedDepistages, depistage],
    )
  }

  const relevantDepistages = getRelevantDepistagesForProfile(profile)
  const isDisabled = excludedDepistages.length === relevantDepistages.length

  return (
    <>
      <QuestionnaireLayout.Header
        pageName={VerticalsLongNames.general}
        onBack={onBack}
        progress={50}
      />
      <QuestionnaireLayout.Title
        title={{
          text: "Vos recommandations prioritaires",
          highlight: "prioritaires",
        }}
      />
      <VSpacer size={8} />
      <Text size="sm">
        <Text>
          Les actions ci-dessous couvrent les{" "}
          <Bold>principaux facteurs de risque</Bold> vous concernant.
        </Text>
        <VSpacer size={4} />
        <Text>
          Elles sont conformes aux directives de la{" "}
          <Link href="https://www.has-sante.fr/" target="_blank">
            Haute Autorité de Santé
          </Link>{" "}
          et à{" "}
          <Link
            href="https://www.bleu.care/blog/methodologie-recommandations"
            target="_blank"
          >
            notre méthodologie de recommandation
          </Link>
          .
        </Text>
      </Text>
      <VSpacer size={6} />
      <HStack
        borderRadius="9px"
        bgColor="blue.200"
        height={98}
        padding={4}
        justifyContent="space-between"
      >
        <VStack alignItems="flex-start" gap={0}>
          <Text size="2xl">Votre IMC</Text>
          <Text size="xs" color="navy.300">
            *Indice de masse
            <br />
            corporelle
          </Text>
        </VStack>
        <Text size="4xl" color="blue.400">
          {computeBodyMassIndexFromProfile(profile).toFixed(1)}
        </Text>
      </HStack>
      <VSpacer size={6} />
      <Divider />
      <VStack alignItems="stretch" divider={<Divider />}>
        {relevantDepistages.map(({ depistage, vertical }) => {
          const { name, longName, subtitle, description, moreInfo } = depistage
          return (
            <VStack key={name} alignItems="stretch" gap={0} mt={4}>
              <HStack
                key={name}
                alignItems="flex-start"
                justifyContent="space-between"
              >
                <HStack alignItems="flex-start" gap={6} paddingRight={8}>
                  <Icon
                    as={verticalsTheme[vertical].icon}
                    color={verticalsTheme[vertical].color}
                    boxSize={5}
                  />
                  <VStack gap={0} align="start" mt={-1}>
                    <Text size="lg" key={name} fontWeight={600}>
                      {longName}
                    </Text>
                    <Text color="navy.300" size="sm">
                      {subtitle}
                    </Text>
                  </VStack>
                </HStack>

                <Switch
                  justifySelf="flex-start"
                  isChecked={!excludedDepistages.includes(name)}
                  onChange={() => handleToggleDepistage(name)}
                />
              </HStack>
              <VSpacer size={6} />
              <Text size="sm" color="navy.400">
                {description}
              </Text>
              {moreInfo && (
                <>
                  <VSpacer size={4} />
                  <InfoBox
                    isCollapsible={true}
                    title="En savoir plus"
                    message={moreInfo}
                  />
                </>
              )}
              <VSpacer size={5} />
            </VStack>
          )
        })}
      </VStack>
      <Divider borderTop="1px solid gray.400" />
      <VSpacer size={6} />
      <InfoBox
        title="Avertissement"
        message="Si vous avez des des antécédents personnels ou familiaux, des symptômes ou une pathologie connue en cours, d’autres dépistages pourraient aussi être pertinents mais relèvent d’une consultation médicale. Parlez-en à votre médecin."
        status="warning"
      />
      <QuestionnaireLayout.CTA onClick={onNext} isDisabled={isDisabled}>
        Continuer
      </QuestionnaireLayout.CTA>
    </>
  )
}
