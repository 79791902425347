import type { ComputePrescription } from "@bleu/shared/prescriptions/types"

export const SCORE2_PRESCRIPTIONS = {
  hta: {
    shortName: "HTA",
    title: "Prise de tension artérielle",
    description:
      "Cher(e) ami(e),\n" +
      "\n" +
      "Merci de recevoir mon patient, pour réaliser une mesure de pression artérielle à l’officine, dans le cadre d’un dépistage systématique.\n" +
      "\n" +
      "Merci\n",
    prescriptionType: "PRESCRIPTION_ADDRESSING",
  },
  eal: {
    shortName: "EAL",
    title: "Faire réaliser un bilan lipidique",
    description: "(le matin à jeun depuis 12h, eau possible, pas de tabac)",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
} as const

export const score2ComputePrescription: ComputePrescription = ({ score2 }) => {
  const { hasConstants } = score2!
  return !hasConstants
    ? [SCORE2_PRESCRIPTIONS.hta, SCORE2_PRESCRIPTIONS.eal]
    : []
}
