export * from "./enums"

import { z } from "zod"

import {
  DepistagesNames,
  depistages,
  depistagesNames,
  type DepistageName,
} from "@bleu/shared/depistages"
import type { Depistage } from "@bleu/shared/depistages/types"
import { VerticalName } from "@bleu/shared/verticals/enums"

export const getDepistagesNamesForVertical = (
  verticalName: VerticalName,
): DepistageName[] =>
  ({
    [VerticalName.CARDIO]: [
      DepistagesNames.score2,
      DepistagesNames.abdominalAorticAneurysm,
      DepistagesNames.diabetes,
      DepistagesNames.highBloodPressure,
      DepistagesNames.dyslipidemia,
    ],
    [VerticalName.CANCER]: [
      DepistagesNames.breastCancer,
      DepistagesNames.cervicalCancer,
      DepistagesNames.colorectalCancer,
    ],
    [VerticalName.STI]: [DepistagesNames.sti],
    [VerticalName.SUBSTITUTES]: [
      DepistagesNames.nicotineSubstitution,
      DepistagesNames.b9Supplements,
    ],
    [VerticalName.GENERAL]: [...depistagesNames],
  })[verticalName]

export const getDepistagesForVertical = (
  verticalName: VerticalName,
): Depistage[] =>
  ({
    [VerticalName.CARDIO]: [
      depistages.abdominalAorticAneurysm,
      depistages.diabetes,
      depistages.highBloodPressure,
      depistages.dyslipidemia,
      depistages.score2,
    ],
    [VerticalName.CANCER]: [
      depistages.breastCancer,
      depistages.cervicalCancer,
      depistages.colorectalCancer,
    ],
    [VerticalName.STI]: [depistages.sti],
    [VerticalName.SUBSTITUTES]: [
      depistages.nicotineSubstitution,
      depistages.b9Supplements,
    ],
    [VerticalName.GENERAL]: [...Object.values(depistages)],
  })[verticalName]

export const isVerticalName = (value: unknown): value is VerticalName =>
  z.nativeEnum(VerticalName).safeParse(value).success
