import * as Sentry from "@sentry/react"
import { QueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 120000,
      retry: (failureCount, error) => {
        if (failureCount > 2) return false
        if (error instanceof AxiosError) {
          // Do not retry on authorization errors
          return ![401, 403].includes(error.response?.status ?? 0)
        }
        return true
      },
    },
    mutations: {
      onError: (err) => {
        Sentry.captureException(err, { tags: { source: "queryClient" } })
        console.error(err)
      },
    },
  },
})
