import { BreastCancerCurrentSituationOption } from "@bleu/shared/questionnaires"

import type { ComputePrescription } from "./types"

export const BREAST_CANCER_PRESCRIPTIONS = {
  mammogramm: {
    shortName: "Mammo",
    title: "Faire réaliser une mammographie",
    description:
      "dans le cadre d’un rattrapage du dépistage organisé du cancer du sein (double lecture)",
    prescriptionType: "PRESCRIPTION_IMAGERY",
  },
} as const

export const breastCancerComputePrescription: ComputePrescription = (
  { breastCancer },
  { age },
) => {
  const { currentSituation } = breastCancer!
  if (
    age > 50 &&
    age < 75 &&
    !currentSituation.includes(BreastCancerCurrentSituationOption.NONE)
  ) {
    return [BREAST_CANCER_PRESCRIPTIONS.mammogramm]
  }
  return []
}
