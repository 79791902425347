import { ZodTypeAny, z } from "zod"

import {
  BiologicalSexOption,
  QuestionTypes,
  QuestionnairesNames,
  STICurrentSituationOption,
  STIDetectionMethodsOption,
  STIGermOption,
  STIReasonOption,
  STISymptomsOption,
  YesNoOther,
} from "./enums"
import type { BaseQuestionnaireShape } from "./types"

const shouldShow = () => true

enum QuestionNames {
  REASON = "reason",
  RISKY_BEHEAVIOR_DATE = "riskyBehaviorDate",
  SYMPTOMS = "symptoms",
  OTHER_SYMPTOMS = "otherSymptoms",
  DETECTION_METHODS = "detectionMethods",
  GERM = "germ",
  OTHER_GERM = "otherGerm",
  CURRENT_SITUATION = "currentSituation",
  DECLINE_HIV = "declineHIV",
  HEPATITE_VACCINE = "hepatiteVaccine",
}

interface BaseSTI {
  [QuestionNames.DETECTION_METHODS]: STIDetectionMethodsOption[]
  [QuestionNames.DECLINE_HIV]: boolean
  [QuestionNames.HEPATITE_VACCINE]: YesNoOther
  [QuestionNames.CURRENT_SITUATION]: STICurrentSituationOption[]
}

interface STIReasonSymptoms extends BaseSTI {
  reason: typeof STIReasonOption.SYMPTOMS
  symptoms: STISymptomsOption[]
  germ: never
  otherSymptoms?: string
}

interface STIReasonGerm extends BaseSTI {
  reason:
    | typeof STIReasonOption.PARTNER_IS_POSITIVE
    | typeof STIReasonOption.POST_TREATMENT
  germ: STIGermOption[]
  symptoms: never
  otherGerm?: string
}

interface STIReasonOther extends BaseSTI {
  germ: never
  symptoms: never
  reason: typeof STIReasonOption.PARTNER_HAS_SYMPTOMS
}

interface STIReasonRiskyBehavior extends BaseSTI {
  germ: never
  symptoms: never
  riskyBehaviorDate: Date
  reason: typeof STIReasonOption.RISKY_BEHEAVIOR
}

export type STIData =
  | STIReasonRiskyBehavior
  | STIReasonSymptoms
  | STIReasonGerm
  | STIReasonOther
export interface STIQuestionnaireFormValues {
  reason: STIReasonOption | undefined
  symptoms: STISymptomsOption[]
  germ: STIGermOption[] | undefined
  detectionMethods: STIDetectionMethodsOption[]
  otherSymptoms: string | undefined
  otherGerm: string | undefined
  declineHIV: boolean | undefined
  hepatiteVaccine: YesNoOther | undefined
  currentSituation: STICurrentSituationOption[]
}

const initialValues: STIQuestionnaireFormValues = {
  [QuestionNames.REASON]: undefined,
  [QuestionNames.SYMPTOMS]: new Array<STISymptomsOption>(),
  [QuestionNames.DETECTION_METHODS]: new Array<STIDetectionMethodsOption>(),
  [QuestionNames.GERM]: new Array<STIGermOption>(),
  [QuestionNames.OTHER_SYMPTOMS]: undefined,
  [QuestionNames.OTHER_GERM]: undefined,
  [QuestionNames.DECLINE_HIV]: undefined,
  [QuestionNames.HEPATITE_VACCINE]: undefined,
  [QuestionNames.CURRENT_SITUATION]: new Array<STICurrentSituationOption>(),
} as const

export const stiSchema = z
  .object<Record<QuestionNames, ZodTypeAny>>({
    [QuestionNames.REASON]: z.nativeEnum(STIReasonOption),
    [QuestionNames.RISKY_BEHEAVIOR_DATE]: z
      .union([z.string(), z.date()])
      .optional()
      .nullable(),

    [QuestionNames.SYMPTOMS]: z
      .array(z.nativeEnum(STISymptomsOption))
      .optional()
      .nullable(),
    [QuestionNames.GERM]: z
      .nativeEnum(STIGermOption)
      .array()
      .optional()
      .nullable(),
    [QuestionNames.DETECTION_METHODS]: z
      .array(z.nativeEnum(STIDetectionMethodsOption))
      .min(1),
    [QuestionNames.OTHER_SYMPTOMS]: z.string().optional().nullable(),
    [QuestionNames.OTHER_GERM]: z.string().optional().nullable(),
    [QuestionNames.DECLINE_HIV]: z.boolean(),
    [QuestionNames.HEPATITE_VACCINE]: z.nativeEnum(YesNoOther),
    [QuestionNames.CURRENT_SITUATION]: z
      .array(z.nativeEnum(STICurrentSituationOption))
      .min(1),
  })
  .transform((data) => {
    if (data.riskyBehaviorDate && typeof data.riskyBehaviorDate === "string") {
      data.riskyBehaviorDate = new Date(data.riskyBehaviorDate)
    }
    return data
  })
  .refine((data): data is STIData => {
    const {
      reason,
      symptoms,
      germ,
      detectionMethods,
      otherGerm,
      otherSymptoms,
      riskyBehaviorDate,
    } = data
    if (detectionMethods.size === 0) return false

    if (
      (reason === STIReasonOption.PARTNER_IS_POSITIVE ||
        reason === STIReasonOption.POST_TREATMENT) &&
      germ.length === 0
    ) {
      return false
    }
    if (reason === STIReasonOption.SYMPTOMS && symptoms.length === 0) {
      return false
    }
    if (germ === STIGermOption.OTHER && !otherGerm) {
      return false
    }

    if (symptoms.includes(STISymptomsOption.OTHER) && !otherSymptoms) {
      return false
    }
    if (reason === STIReasonOption.RISKY_BEHEAVIOR && !riskyBehaviorDate) {
      return false
    }
    return true
  })

export type STIQuestionnaireShape = BaseQuestionnaireShape<
  QuestionNames,
  STIData,
  STIQuestionnaireFormValues
>

export const stiQuestionnaire: STIQuestionnaireShape = {
  name: QuestionnairesNames.sti,
  questions: [
    {
      id: QuestionNames.REASON,
      title: "Pourquoi souhaitez-vous réaliser ce dépistage ?",
      type: QuestionTypes.SingleSelect,
      options: [
        { label: "J'ai des symptômes", value: STIReasonOption.SYMPTOMS },
        {
          label: "Mon partenaire a des symptômes",
          value: STIReasonOption.PARTNER_HAS_SYMPTOMS,
        },
        {
          label: "Mon partenaire a été testé positif",
          value: STIReasonOption.PARTNER_IS_POSITIVE,
        },
        {
          label: "C'est un contrôle post-traitement",
          value: STIReasonOption.POST_TREATMENT,
        },
        {
          label: "C'est un contrôle de routine",
          value: STIReasonOption.ROUTINE,
        },
        {
          label: "C'est un contrôle suite à une prise de risque récente",
          value: STIReasonOption.RISKY_BEHEAVIOR,
        },
      ],
      shouldShow,
    },
    {
      id: QuestionNames.RISKY_BEHEAVIOR_DATE,
      type: QuestionTypes.Date,
      shouldShow: ({ reason }) => reason === STIReasonOption.RISKY_BEHEAVIOR,
      title: "Quelle est la date de la prise de risque ?",
    },
    {
      id: QuestionNames.SYMPTOMS,
      type: QuestionTypes.MultiSelect,
      shouldShow: ({ reason }) => reason === STIReasonOption.SYMPTOMS,
      title: "Quels symptômes avez-vous ?",
      options: [
        {
          label: "Une éruption sur la peau ou les muqueuses",
          value: STISymptomsOption.SKIN_OR_MUCOUS_ERUPTION,
        },
        {
          label:
            "Une modification de mes pertes (quantité, aspect, odeur, etc.)",
          value: STISymptomsOption.DISCHARGE,
          shouldShow: (_, { biologicalSex }) =>
            biologicalSex === BiologicalSexOption.FEMALE,
        },
        { label: "De la fièvre", value: STISymptomsOption.FEVER },
        { label: "Autres", value: STISymptomsOption.OTHER, freeText: true },
      ],
    },
    {
      id: QuestionNames.OTHER_SYMPTOMS,
      type: QuestionTypes.FreeText,
      shouldShow: (data) =>
        data.reason === STIReasonOption.SYMPTOMS &&
        data.symptoms.includes(STISymptomsOption.OTHER),
      title: "Précisez les symptômes",
    },
    {
      id: QuestionNames.GERM,
      shouldShow: ({ reason }) =>
        reason === STIReasonOption.PARTNER_IS_POSITIVE ||
        reason === STIReasonOption.POST_TREATMENT,
      title: "De quel germe(s) s'agit-il ?",
      type: QuestionTypes.MultiSelect,
      options: [
        { label: "Chlamydia", value: STIGermOption.CHLAMYDIA },
        { label: "Gonocoque", value: STIGermOption.GONOCOQUE },
        { label: "Mycoplasme", value: STIGermOption.MYCOPLASME },
        // { label: "Syphilis", value: STIGermOption.SYPHILIS },
        { label: "Autre", value: STIGermOption.OTHER },
      ],
    },
    {
      id: QuestionNames.OTHER_GERM,
      type: QuestionTypes.FreeText,
      shouldShow: ({ germ }) => germ.includes(STIGermOption.OTHER),
      title: "Précisez le germe",
    },
    {
      id: QuestionNames.DETECTION_METHODS,
      title:
        "Sélectionnez le ou les types de prélèvement s'appliquant à votre situation",
      subtitle: "Sélectionnez tous les sites ayant pu être contaminés",
      infoBox: {
        title: "Auto-prélèvement",
        message:
          "L'auto-prélèvement est possible dans certains laboratoires pour l'ensemble des sites. Renseignez-vous au moment de la prise de rendez-vous.",
      },
      type: QuestionTypes.MultiSelect,
      shouldShow,
      options: [
        {
          label: "Prélèvement d'urine",
          value: STIDetectionMethodsOption.URINE_SAMPLE,
          shouldShow: (_, { biologicalSex }) =>
            biologicalSex === BiologicalSexOption.MALE,
        },
        {
          label: "Prélèvement vaginal",
          value: STIDetectionMethodsOption.VAGINAL_SWAB,
          shouldShow: (_, { biologicalSex }) =>
            biologicalSex === BiologicalSexOption.FEMALE,
        },
        {
          label: "Prélèvement pharyngé (gorge)",
          value: STIDetectionMethodsOption.THROAT_SWAB,
        },
        {
          label: "Prélèvement anal",
          value: STIDetectionMethodsOption.RECTAL_SWAB,
        },
      ],
    },
    {
      id: QuestionNames.DECLINE_HIV,
      shouldShow,
      title:
        "Nous recommandons le test VIH par défaut. Souhaitez-vous le faire ?",
      type: QuestionTypes.SingleSelect,
      options: [
        {
          label: "Oui",
          value: false,
        },
        {
          label: "Non",
          value: true,
        },
      ],
    },
    {
      id: QuestionNames.HEPATITE_VACCINE,
      shouldShow,
      title: "Etes-vous vacciné contre l'hépatite B ?",
      type: QuestionTypes.SingleSelect,
      options: [
        { label: "Oui", value: YesNoOther.YES },
        { label: "Non", value: YesNoOther.NO },
        {
          label: "Je ne sais pas",
          value: YesNoOther.OTHER,
        },
      ],
    },
    {
      id: QuestionNames.CURRENT_SITUATION,
      type: QuestionTypes.MultiSelect,
      shouldShow,
      title: "Etes-vous dans une ou plusieurs des situations suivantes ?",
      // description: "Sélectionnez toutes les réponses qui s'appliquent",
      options: [
        {
          label: "IST récente",
          value: STICurrentSituationOption.RECENT_STI,
        },
        {
          label: "2 partenaires ou plus dans les 12 mois écoulés",
          value: STICurrentSituationOption.MULTIPLE_PARTNERS,
        },
        {
          label: "Homme ayant des rapports avec d'autres hommes",
          value: STICurrentSituationOption.MAN_TO_MAN_RELATIONS,
          shouldShow: (_, { biologicalSex }) =>
            biologicalSex === BiologicalSexOption.MALE,
        },
        {
          label:
            "Travailleur(euse) du sexe ou ayant des relations sexuelles avec un(e) travailleur(euse) du sexe",
          value: STICurrentSituationOption.SEX_WORKER,
        },
        {
          label:
            "Usager de drogue injectée ou sniffée, tatouage ou piercing réalisé dans des conditions d'hygiène douteuses, transfusion sanguine, intervention chirurgicale dans un pays en voie de développement ou détention pénitentiaire passée",
          value: STICurrentSituationOption.DRUGS,
        },
        {
          label: "Je vis avec une personne atteinte par l'hépatite C",
          value: STICurrentSituationOption.HEPATITE_C_RELATIVE,
        },
        {
          label: "Aucune de ces situations",
          value: STICurrentSituationOption.NONE,
          unique: true,
        },
      ],
    },
  ],
  schema: stiSchema,
  initialValues,
} as const
