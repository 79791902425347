import { Flex, Center, Heading, VStack, Text } from "@chakra-ui/react"

import { SelectBox } from "@bleu/front/components/forms/SelectBox"
import type {
  QuestionProps,
  SelectOption,
} from "@bleu/front/components/forms/types"

export interface MultiSelectOption extends SelectOption<string> {
  unique?: true
}

interface MultiSelectQuestionProps
  extends Omit<QuestionProps<string[]>, "onChange" | "value"> {
  options: MultiSelectOption[]
  values: string[]
  onChange: (value: string, isUnique?: true) => void
}
export const MultiSelectQuestion = ({
  isReadOnly,
  title,
  subtitle,
  options,
  values,
  onChange,
}: MultiSelectQuestionProps) => (
  <Flex gap={6} direction="column">
    <Center>
      {title && (
        <VStack gap={2}>
          <Heading size="lg" textAlign="center">
            {title}
          </Heading>
          {subtitle && (
            <Text textAlign="center" fontSize="sm">
              {subtitle}
            </Text>
          )}
        </VStack>
      )}
    </Center>
    <VStack gap={5} alignItems="center">
      {options.map((option) => (
        <SelectBox
          isReadOnly={isReadOnly}
          variant={values.includes(option.value) ? "select" : "default"}
          key={option.label}
          onClick={() => onChange(option.value, option.unique)}
        >
          {option.label}
        </SelectBox>
      ))}
    </VStack>
  </Flex>
)
