import { Text, Input } from "@chakra-ui/react"

import type { QuestionProps } from "@bleu/front/components/forms/types"

interface DateQuestionProps extends QuestionProps<string> {
  title: string
}

export const DateQuestion = ({
  title,
  onChange,
  ...props
}: DateQuestionProps) => {
  return (
    <>
      <Text textAlign="center">{title}</Text>
      <Input
        type="date"
        placeholder={title}
        borderRadius={4}
        borderColor="blue.300"
        onChange={(e) => onChange(e.target.value)}
        {...props}
      />
    </>
  )
}
