import { create } from "zustand"
import { persist } from "zustand/middleware"

import { VerticalName } from "@bleu/shared/verticals"

interface VerticalsStore {
  selectedVerticals: VerticalName[]
  setSelectedVerticals: (verticals: VerticalName[]) => void
}

export const useVerticalsStore = create<VerticalsStore>()(
  persist(
    (set) => ({
      selectedVerticals: [],
      setSelectedVerticals: (verticals) =>
        set({ selectedVerticals: verticals }),
    }),
    {
      name: "verticals-storage",
    },
  ),
)
