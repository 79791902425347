import { Spinner, VStack } from "@chakra-ui/react"

export const Loader = () => (
  <VStack
    h="100%"
    align="center"
    justify="center"
    position="relative"
    bottom="10%"
  >
    <Spinner color="blue.400" size="xl" thickness="3px" />
  </VStack>
)
