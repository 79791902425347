import { Text, Input, VStack } from "@chakra-ui/react"

import type { QuestionProps } from "@bleu/front/components/forms/types"
import { VSpacer } from "@bleu/front/components/layout/Spacer"

interface FreeTextQuestionProps extends QuestionProps<string> {
  title: string
}

export const FreeTextQuestion = ({
  title,
  onChange,
  ...props
}: FreeTextQuestionProps) => {
  return (
    <VStack align="stretch" gap={0}>
      <Text fontWeight={600}>{title}</Text>
      <VSpacer size={2} />
      <Input
        borderRadius={4}
        borderColor="blue.300"
        onChange={(e) => onChange(e.target.value)}
        {...props}
      />
    </VStack>
  )
}
