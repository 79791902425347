import { z } from "zod"
import { MedicalRecordStatuses } from "@bleu/shared/medicalRecords/statuses"
import { DepistagesNames } from "@bleu/shared/depistages/index"

export const emailExtractionParamsSchema = z.object({
  createdBefore: z.coerce.date().optional(),
  createdAfter: z.coerce.date().optional(),
  statuses: z.nativeEnum(MedicalRecordStatuses).array().min(0),
  selectedDepistages: z.nativeEnum(DepistagesNames).array().min(0),
  acceptsMarketing: z.boolean(),
})

export namespace AdminRequests {
  export type ExtractEmails = z.infer<typeof emailExtractionParamsSchema>
}

export namespace AdminResponses {
  export type ExtractedEmails = { emails: string[] }
}
