import { Button, forwardRef, type ButtonProps } from "@chakra-ui/react"
import { IconPhone } from "@tabler/icons-react"

import { useUser } from "@bleu/front/components/auth/UserContext"
import { amplitudeTrack } from "@bleu/front/utils/tracking"

interface CalendlyButtonProps extends ButtonProps {
  medicalRecordCode: string
}

export const CalendlyButton = forwardRef<CalendlyButtonProps, "button">(
  ({ medicalRecordCode, ...props }) => {
    const user = useUser()

    const searchParams = new URLSearchParams({
      name: user.fullName,
      email: user.emailAddress,
      location: user.phoneNumber,
      utm_content: medicalRecordCode,
    })

    return (
      <Button
        size="lg"
        leftIcon={<IconPhone />}
        variant="outline"
        as="a"
        href={`${import.meta.env.VITE_CALENDLY_URL}?${searchParams.toString()}`}
        onClick={() => {
          amplitudeTrack("Meeting Link Clicked", {
            recordCode: medicalRecordCode,
          })
        }}
        target="_blank"
        {...props}
      >
        Fixer mon rendez-vous
      </Button>
    )
  },
)
