import { Button, HStack } from "@chakra-ui/react"
import { usePagination } from "@mantine/hooks"

type Props = {
  page: number
  totalPages: number
  onChangePage: (page: number) => void
}

export const Pagination = ({ page, totalPages, onChangePage }: Props) => {
  const pagination = usePagination({
    total: totalPages,
    page,
    onChange: onChangePage,
  })

  return (
    <HStack w="100%" justify="flex-end">
      {pagination.range.map((i, index) => (
        <Button
          px={0}
          size="sm"
          variant={i === page ? "primary" : "outline"}
          key={`page-${i !== "dots" ? i : `ellipsis-${index}`}`}
          onClick={() => i !== "dots" && onChangePage(i)}
        >
          {i === "dots" ? "..." : i}
        </Button>
      ))}
    </HStack>
  )
}
