import SealdSDKConstructor, { SealdSDK } from "@seald-io/sdk"
import { EncryptionSession } from "@seald-io/sdk/lib/main"
import SealdSDKPluginSSKS2MR from "@seald-io/sdk-plugin-ssks-2mr"
import * as Sentry from "@sentry/react"

import { ConversationResponses } from "@bleu/types/endpoints/conversations"

export let sealdClient: SealdSDK

type SealdIdentityInput = {
  userId: string
  twoManRuleKey: string
  emailAddress: string
  twoManRuleSessionId: string
  challenge?: string
}

export const initiateSealdClient = async (
  databaseKey: string,
  forceRecreate = false,
) => {
  if (sealdClient && !forceRecreate) return sealdClient
  sealdClient = SealdSDKConstructor({
    appId: import.meta.env.VITE_SEALD_APP_ID,
    apiURL: import.meta.env.VITE_SEALD_API_URL,
    databaseKey,
    databasePath: forceRecreate
      ? `tmp-seald-session-${databaseKey}`
      : "seald-session",
    plugins: [
      SealdSDKPluginSSKS2MR(import.meta.env.VITE_SEALD_KEY_STORAGE_URL), // Optional. If not set, defaults to public keyStorageURL https://ssks.seald.io
    ],
  })
  await sealdClient.initialize()
  return sealdClient
}

export const initiateSealdIdentity = async (
  signupJWT: string,
): Promise<string> => {
  console.log("SEALD - initiateSealdIdentity")
  const scope = Sentry.getCurrentScope()
  scope.setTags({ initiateIdentity: true })
  try {
    const { sealdId } = await sealdClient.initiateIdentity({ signupJWT })
    console.log("SEALD - initiateSealdIdentity - Done", sealdId)
    return sealdId
  } catch (error) {
    // @ts-expect-error Already registered is a valid error
    if (error.message === "Already registered") {
      console.log("SEALD - initiateSealdIdentity", "Already registered")
      await sealdClient.dropDatabase()
      return initiateSealdIdentity(signupJWT)
    }
    console.error("SEALD - initiateSealdIdentity", error)
    Sentry.captureException(error, { extra: { signupJWT } })
    throw error
  }
}

export const createSealdIdentity = async ({
  userId,
  twoManRuleKey,
  emailAddress,
  twoManRuleSessionId,
  challenge,
}: SealdIdentityInput) => {
  console.log("SEALD - createSealdIdentity")
  const scope = Sentry.getCurrentScope()
  scope.setTags({ saveIdentity: true })
  const identity = await sealdClient.ssks2MR.saveIdentity({
    challenge,
    authFactor: {
      type: "EM",
      value: emailAddress,
    },
    twoManRuleKey,
    userId,
    sessionId: twoManRuleSessionId,
  })
  console.log("SEALD - createSealdIdentity - Done", identity)
  return identity.authenticatedSessionId
}

export const retrieveIdentityFromLocalStorage = async () => {
  console.log("SEALD - retrieveIdentityFromLocalStorage")
  const scope = Sentry.getCurrentScope()
  scope.setTags({ registrationStatus: true })
  const status = await sealdClient.registrationStatus()
  scope.setExtras({ sealdStatus: status })
  if (status === "no-account") {
    console.log("SEALD - retrieveIdentityFromLocalStorage", "Not registered")
    return null
  } else if (status === "registered") {
    try {
      Sentry.captureMessage("SEALD - retrieveIdentityFromLocalStorage", {
        tags: { seald: "retrieveIdentity" },
      })
      const accountInfo = await sealdClient.getCurrentAccountInfo()
      console.log(
        "SEALD - retrieveIdentityFromLocalStorage",
        "Done",
        accountInfo.sealdId,
      )

      scope.setTags({ getCurrentAccountInfo: true })
      scope.setExtras({ sealdId: accountInfo.sealdId })

      return accountInfo.sealdId
    } catch (error) {
      console.error(
        "SEALD - retrieveIdentityFromLocalStorage - No Account Info",
        error,
      )
      Sentry.captureException(error, {
        tags: { seald: "retrieveIdentity" },
      })
      throw error
    }
  } else {
    const error = new Error(
      `SEALD - retrieveIdentityFromLocalStorage - Unknown Status ${status}`,
    )
    Sentry.captureException(error)
    throw error
  }
}

export const retrieveIdentity2MR = async ({
  userId,
  emailAddress,
  twoManRuleKey,
  twoManRuleSessionId,
  challenge,
}: SealdIdentityInput) => {
  console.log("SEALD - retrieveIdentity2MR")
  const scope = Sentry.getCurrentScope()
  scope.setTags({ retrieveIdentity2MR: true })
  const identity = await sealdClient.ssks2MR.retrieveIdentity({
    challenge,
    authFactor: {
      type: "EM",
      value: emailAddress,
    },
    twoManRuleKey,
    userId,
    sessionId: twoManRuleSessionId,
  })
  console.log("SEALD - retrieveIdentity2MR - Done", identity)
  return identity.authenticatedSessionId
}

export const retrieveSealdSessionFromConversation = async (
  conversation: ConversationResponses.GetConversation,
  userId: string,
  isDoctor: boolean,
): Promise<EncryptionSession> => {
  const scope = Sentry.getCurrentScope()
  scope.setTags({ retrieveEncryptionSession: true })
  const participant = conversation.participants.find(
    (participant) => participant.userId === userId,
  )

  if (!participant) {
    if (!isDoctor) {
      throw new Error("Participant not found")
    }
  }

  // Finally, we can retrieve the EncryptionSession
  return sealdClient.retrieveEncryptionSession({
    sessionId: conversation.sealdSessionId,
  })
}
