import {
  Box,
  Divider,
  HStack,
  Icon,
  List,
  ListItem,
  Stack,
  Text,
  type ChakraProps,
} from "@chakra-ui/react"
import _ from "lodash"
import { useParams } from "react-router-dom"

import { PatientResponses } from "@bleu/types/endpoints/patients"

import {
  DepistagesLongNames,
  depistages,
  getVerticalNameForDepistageName,
} from "@bleu/shared/depistages"
import {
  QuestionTypes,
  QuestionnairesNames,
  profileQuestionnaire,
  type QuestionnaireName,
  type SingleSelectQuestion,
} from "@bleu/shared/questionnaires"
import { computeBodyMassIndexFromProfile } from "@bleu/shared/questionnaires/utils"

import { colors } from "@bleu/front/assets/theme/colors"
import { verticalsTheme } from "@bleu/front/assets/theme/verticalsTheme"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { usePatientMedicalRecordsQuery } from "@bleu/front/queries/patients"

const scrollbarStyles: ChakraProps["css"] = {
  "&::-webkit-scrollbar": {
    width: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: colors.blue[400],
    borderRadius: "8px",
  },
  "&::-webkit-scrollbar-track": {
    backgroundColor: colors.blue[200],
    borderRadius: "8px",
  },
}

type Props = {
  medicalRecord: PatientResponses.PatientMedicalRecord
}

const _MedicalRecordQuestionnaireAnswersPage = ({ medicalRecord }: Props) => {
  const { questionnaireAnswer } = medicalRecord

  const questionnairesNames = Object.keys(questionnaireAnswer)
    .filter((key): key is QuestionnaireName => key in QuestionnairesNames)
    .filter(
      (questionnaireName) => questionnaireAnswer[questionnaireName] !== null,
    )

  const formatedAnswers = _.sortBy(
    questionnairesNames.map((questionnaireName) => {
      return {
        questionnaireName,
        verticalName: getVerticalNameForDepistageName(questionnaireName),
        answers: depistages[questionnaireName]
          .questionnaire!.questions.map(({ id, title, type, ...question }) => {
            if (type === QuestionTypes.SingleSelect) {
              return {
                title,
                answer: (
                  question as SingleSelectQuestion<unknown, unknown>
                ).options.find(
                  ({ value }) =>
                    // @ts-expect-error ts doesn't understand that id is a key of questionnaireAnswer[questionnaireName]
                    value === questionnaireAnswer[questionnaireName][id],
                )?.label,
              }
            } else if (type === QuestionTypes.MultiSelect) {
              return {
                title,
                answer: (
                  question as SingleSelectQuestion<unknown, unknown>
                ).options
                  .filter(({ value }) =>
                    // @ts-expect-error ts doesn't understand that id is a key of questionnaireAnswer[questionnaireName]
                    questionnaireAnswer[questionnaireName][id].includes(value),
                  )
                  .map(({ label }) => label),
              }
            } else {
              return {
                title,
                // @ts-expect-error ts doesn't understand that id is a key of questionnaireAnswer[questionnaireName]
                answer: questionnaireAnswer[questionnaireName][id],
              }
            }
          })
          .filter(
            ({ answer }) =>
              !(
                answer === undefined ||
                answer === null ||
                answer === "" ||
                (_.isArray(answer) && answer.length === 0)
              ),
          ),
      }
    }),
    ({ verticalName }) => verticalName,
  )

  return (
    <Box
      h="100%"
      w="100%"
      overflowY="scroll"
      css={scrollbarStyles}
      maxH="calc(100vh - 350px)"
      borderRadius={8}
    >
      <Stack divider={<Divider border="1px solid gray.300" />} gap={4}>
        <Stack>
          <HStack>
            <Icon
              as={verticalsTheme.general.icon}
              color={verticalsTheme.general.color}
            />
            <Text fontWeight={600} size="lg">
              Profil
            </Text>
          </HStack>
          <Text fontWeight={600}>IMC</Text>
          <Text color="blue.400">
            {computeBodyMassIndexFromProfile(medicalRecord.profile).toFixed(1)}
          </Text>
          {profileQuestionnaire.questions.map((question) => {
            const { title } = question
            const { profile } = medicalRecord
            const answer =
              question.type === QuestionTypes.SingleSelect
                ? question.options.find(
                    ({ value }) => value === profile[question.id],
                  )?.label
                : profile[question.id]

            if (answer === null || answer === undefined || answer === "") {
              return null
            }
            return (
              <Stack key={question.id}>
                <Text fontWeight={600}>{title}</Text>
                {_.isArray(answer) ? (
                  <List>
                    {answer.map((item) => (
                      <ListItem key={item} color="blue.400">
                        {item}
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Text color="blue.400">{answer}</Text>
                )}
              </Stack>
            )
          })}
        </Stack>
        {formatedAnswers.map(({ verticalName, questionnaireName, answers }) => {
          const { icon, color } = verticalsTheme[verticalName]
          return (
            <Stack key={questionnaireName}>
              <HStack key={questionnaireName}>
                <Icon as={icon} color={color} />
                <Text fontWeight={600} size="lg">
                  {DepistagesLongNames[questionnaireName]}
                </Text>
              </HStack>
              {answers.map(({ title, answer }) => {
                return (
                  <Stack key={questionnaireName + title}>
                    <Text fontWeight={600}>{title}</Text>
                    {_.isArray(answer) ? (
                      <List>
                        {answer.map((item) => (
                          <ListItem key={item} color="blue.400">
                            {item}
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <Text color="blue.400">{answer}</Text>
                    )}
                  </Stack>
                )
              })}
            </Stack>
          )
        })}
      </Stack>
    </Box>
  )
}

export const MedicalRecordQuestionnaireAnswersPage = () => {
  const { patientId, recordId } = useParams()
  const medicalRecordQuery = usePatientMedicalRecordsQuery(
    patientId as string,
    recordId as string,
  )

  return (
    <QueryWrapper query={medicalRecordQuery}>
      {({ data }) => (
        <_MedicalRecordQuestionnaireAnswersPage medicalRecord={data} />
      )}
    </QueryWrapper>
  )
}
