import _ from "lodash"

import { MedicalRecordResponses } from "@bleu/types/endpoints/index"

import { DepistageName, depistages } from "@bleu/shared/depistages"
import { ABDOMINAL_AORTIC_ANEURYSM_PRESCRIPTIONS } from "@bleu/shared/prescriptions/abdominalAorticAneurysm"
import { B9_SUPPLEMENTS_PRESCRIPTIONS } from "@bleu/shared/prescriptions/b9Supplements"
import { BREAST_CANCER_PRESCRIPTIONS } from "@bleu/shared/prescriptions/breastCancer"
import { CERVICAL_CANCER_PRESCRIPTIONS } from "@bleu/shared/prescriptions/cervicalCancer"
import { COLORECTAL_CANCER_PRESCRIPTIONS } from "@bleu/shared/prescriptions/colorectalCancer"
import { DIABETES_PRESCRIPTIONS } from "@bleu/shared/prescriptions/diabetes"
import { DYSLIPIDEMIA_PRESCRIPTIONS } from "@bleu/shared/prescriptions/dyslipidemia"
import { HIGH_BLOOD_PRESSURE_PRESSURE } from "@bleu/shared/prescriptions/highBloodPressure"
import { NICOTINE_SUBSTITUTION_PRESCRIPTIONS } from "@bleu/shared/prescriptions/nicotineSubstitution"
import { SCORE2_PRESCRIPTIONS } from "@bleu/shared/prescriptions/score2"
import { STI_PRESCRIPTIONS } from "@bleu/shared/prescriptions/sti"
import {
  Prescription,
  PrescriptionType,
} from "@bleu/shared/prescriptions/types"
import type {
  AllQuestionnairesAnswers,
  ProfileData,
} from "@bleu/shared/questionnaires"

export { abdominalAorticAneurysmComputePrescription } from "./abdominalAorticAneurysm"
export { b9SupplementsComputePrescription } from "./b9Supplements"
export { breastCancerComputePrescription } from "./breastCancer"
export { cervicalCancerComputePrescription } from "./cervicalCancer"
export { colorectalCancerComputePrescription } from "./colorectalCancer"
export { diabetesComputePrescription } from "./diabetes"
export { dyslipidemiaComputePrescription } from "./dyslipidemia"
export { highBloodPressureComputePrescription } from "./highBloodPressure"
export { nicotineSubstitutionComputePrescription } from "./nicotineSubstitution"
export { score2ComputePrescription } from "./score2"
export { stiComputePrescription } from "./sti"

export const computeAllPrescriptions = (
  depistagesNames: DepistageName[],
  data: AllQuestionnairesAnswers,
  profile: ProfileData,
): Partial<Record<DepistageName, Prescription[]>> => {
  return depistagesNames.reduce((acc, depistageName) => {
    const prescription = depistages[depistageName].computePrescription(
      data,
      profile,
    )
    return {
      ...acc,
      [depistageName]: prescription,
    }
  }, {})
}

export const computeAllPrescriptionsByType = (
  medicalRecord: MedicalRecordResponses.MedicalRecord,
): Partial<Record<PrescriptionType, Prescription[]>> => {
  const allPrescriptions = computeAllPrescriptions(
    medicalRecord.questionnaireAnswer.prescribedDepistages as DepistageName[], // TODO: fix types FIXME
    medicalRecord.questionnaireAnswer as AllQuestionnairesAnswers,
    medicalRecord.profile as ProfileData,
  )
  return _.groupBy(
    _.uniqBy(
      Object.values(allPrescriptions).flat(),
      (prescription) => prescription.shortName,
    ),
    (p) => p.prescriptionType,
  )
}

export const ALL_PRESCRIPTIONS = {
  ...ABDOMINAL_AORTIC_ANEURYSM_PRESCRIPTIONS,
  ...B9_SUPPLEMENTS_PRESCRIPTIONS,
  ...BREAST_CANCER_PRESCRIPTIONS,
  ...CERVICAL_CANCER_PRESCRIPTIONS,
  ...COLORECTAL_CANCER_PRESCRIPTIONS,
  ...DIABETES_PRESCRIPTIONS,
  ...DYSLIPIDEMIA_PRESCRIPTIONS,
  ...HIGH_BLOOD_PRESSURE_PRESSURE,
  ...NICOTINE_SUBSTITUTION_PRESCRIPTIONS,
  ...SCORE2_PRESCRIPTIONS,
  ...STI_PRESCRIPTIONS,
} as const

export const ALL_PRESCRIPTIONS_BY_TYPE = _.groupBy(
  ALL_PRESCRIPTIONS,
  (p) => p.prescriptionType,
)
