import { z } from "zod"

import type { FormValues } from "@bleu/types/form"

import {
  NicotineSubstitutionCigarettesPerDayOptions,
  NicotineSubstitutionTimeToFirstCigaretteOptions,
} from "@bleu/shared/questionnaires"
import { shouldShow } from "@bleu/shared/utils/shouldShow"

import { QuestionTypes, QuestionnairesNames } from "./enums"
import type { BaseQuestionnaireShape } from "./types"

export const nicotineSubstitutionSchema = z.object({
  timeToFirstCigarette: z.nativeEnum(
    NicotineSubstitutionTimeToFirstCigaretteOptions,
  ),
  isRefrainingDifficult: z.boolean(),
  quitFirstCigarette: z.boolean(),
  cigarettesPerDay: z.nativeEnum(NicotineSubstitutionCigarettesPerDayOptions),
  smokesMoreInMorning: z.boolean(),
  smokesWhenSick: z.boolean(),
})

const initialValues: NicotineSubstitutionFormValues = {
  quitFirstCigarette: undefined,
  cigarettesPerDay: undefined,
  smokesMoreInMorning: undefined,
  smokesWhenSick: undefined,
  timeToFirstCigarette: undefined,
  isRefrainingDifficult: undefined,
}

const QuestionNames = {
  timeToFirstCigarette: "timeToFirstCigarette",
  isRefrainingDifficult: "isRefrainingDifficult",
  quitFirstCigarette: "quitFirstCigarette",
  cigarettesPerDay: "cigarettesPerDay",
  smokesMoreInMorning: "smokesMoreInMorning",
  smokesWhenSick: "smokesWhenSick",
} as const

type QuestionName = keyof typeof QuestionNames

export type NicotineSubstitutionData = z.infer<
  typeof nicotineSubstitutionSchema
>
type NicotineSubstitutionFormValues = FormValues<NicotineSubstitutionData>

export type NicotineSubstitutionQuestionnaireShape = BaseQuestionnaireShape<
  QuestionName,
  NicotineSubstitutionData,
  NicotineSubstitutionFormValues
>

export const nicotineSubstitutionQuestionnaire: NicotineSubstitutionQuestionnaireShape =
  {
    name: QuestionnairesNames.nicotineSubstitution,
    questions: [
      {
        id: QuestionNames.timeToFirstCigarette,
        type: QuestionTypes.SingleSelect,
        shouldShow,
        title:
          "Le matin, combien de temps après vous être réveillé(e), fumez-vous votre première cigarette ?",
        options: [
          {
            label: "Dans les 5 premières minutes",
            value:
              NicotineSubstitutionTimeToFirstCigaretteOptions.LESS_THAN_5_MINUTES,
          },
          {
            label: "6 à 30 minutes",
            value:
              NicotineSubstitutionTimeToFirstCigaretteOptions.SIX_TO_THIRTY_MINUTES,
          },
          {
            label: "31 à 60 minutes",
            value:
              NicotineSubstitutionTimeToFirstCigaretteOptions.THIRTY_ONE_TO_SIXTY_MINUTES,
          },
          {
            label: "Plus de 60 minutes",
            value:
              NicotineSubstitutionTimeToFirstCigaretteOptions.MORE_THAN_SIXTY_MINUTES,
          },
        ],
      },
      {
        id: QuestionNames.isRefrainingDifficult,
        type: QuestionTypes.SingleSelect,
        shouldShow,
        title:
          "Trouvez-vous qu’il est difficile de vous abstenir de fumer dans les endroits où c’est interdit (cinémas, bibliothèques...) ?",
        options: [
          { label: "Oui", value: true },
          { label: "Non", value: false },
        ],
      },
      {
        id: QuestionNames.quitFirstCigarette,
        type: QuestionTypes.SingleSelect,
        shouldShow,
        title: "A quelle cigarette renonceriez-vous le plus difficilement ?",
        options: [
          { label: "La première de la journée", value: true },
          { label: "Une autre", value: false },
        ],
      },
      {
        id: QuestionNames.cigarettesPerDay,
        type: QuestionTypes.SingleSelect,
        shouldShow,
        title: "Combien de cigarettes fumez-vous par jour ?",
        options: [
          {
            label: "10 ou moins",
            value: NicotineSubstitutionCigarettesPerDayOptions.LESS_THAN_10,
          },
          {
            label: "11 à 20",
            value: NicotineSubstitutionCigarettesPerDayOptions.TEN_TO_20,
          },
          {
            label: "21 à 30",
            value: NicotineSubstitutionCigarettesPerDayOptions.TWENTY_TO_30,
          },
          {
            label: "31 ou plus",
            value: NicotineSubstitutionCigarettesPerDayOptions.MORE_THAN_30,
          },
        ],
      },
      {
        id: QuestionNames.smokesMoreInMorning,
        type: QuestionTypes.SingleSelect,
        shouldShow,
        title:
          "Fumez-vous à intervalles plus rapprochés durant les premières heures de la matinée que durant le reste de la journée ?",
        options: [
          { label: "Oui", value: true },
          { label: "Non", value: false },
        ],
      },
      {
        id: QuestionNames.smokesWhenSick,
        type: QuestionTypes.SingleSelect,
        shouldShow,
        title:
          "Fumez-vous lorsque vous êtes malade au point de devoir rester au lit presque la journée ?",
        options: [
          { label: "Oui", value: true },
          { label: "Non", value: false },
        ],
      },
    ],
    schema: nicotineSubstitutionSchema,
    initialValues,
  } as const
