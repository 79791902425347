import { AbdominalAorticAneurysmCriteriaOption } from "@bleu/shared/questionnaires"

import type { ComputePrescription } from "./types"

export const ABDOMINAL_AORTIC_ANEURYSM_PRESCRIPTIONS = {
  aaa: {
    shortName: "AAA",
    title: "Faire réaliser une échographie abdominale",
    description:
      "dans le cadre du dépistage systématique de l’anévrisme de l’aorte abdominale",
    prescriptionType: "PRESCRIPTION_IMAGERY",
  },
} as const

export const abdominalAorticAneurysmComputePrescription: ComputePrescription =
  ({ abdominalAorticAneurysm }) => {
    const { criteria } = abdominalAorticAneurysm!

    if (
      criteria.includes(AbdominalAorticAneurysmCriteriaOption.SMOKER) ||
      criteria.includes(AbdominalAorticAneurysmCriteriaOption.FAMILY_HISTORY)
    ) {
      return [ABDOMINAL_AORTIC_ANEURYSM_PRESCRIPTIONS.aaa]
    }

    return []
  }
