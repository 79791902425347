import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
} from "@chakra-ui/react"
import { useState } from "react"

import { Prescription } from "@bleu/shared/prescriptions/types"

type Props = {
  isOpen: boolean
  onClose: () => void
  onSave: (prescription: Prescription) => void
}

export const PrescriptionModal = ({ isOpen, onSave, onClose }: Props) => {
  const [shortName, setShortName] = useState<string>()
  const [title, setTitle] = useState<string>()
  const [description, setDescription] = useState<string>()

  const handleSave = () => {
    if (!title || !shortName || !description) return
    onSave({
      title,
      shortName,
      description,
      prescriptionType: "PRESCRIPTION_TREATMENT",
    })
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Ajouter une prescription</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack gap={4}>
            <Input
              placeholder="Identifiant (s'affiche sur la sélection mais pas la prescription)"
              value={shortName}
              onChange={(e) => setShortName(e.target.value)}
            />
            <Input
              placeholder="Titre (s'affiche en gras)"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <Textarea
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Stack>
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={!description || !title} onClick={handleSave}>
            Ajouter
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
