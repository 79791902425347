import {
  Button,
  Heading,
  Text,
  Box,
  useClipboard,
  useToast,
  Stack,
} from "@chakra-ui/react"
import { useUser } from "@clerk/clerk-react"
import * as Sentry from "@sentry/react"
import { useEffect, useState } from "react"

export const ErrorBoundaryFallback = ({
  error,
  resetErrorBoundary,
}: {
  error: Error
  resetErrorBoundary: () => void
}) => {
  const [errorId, setErrorId] = useState<string | null>(null)
  const { onCopy, hasCopied } = useClipboard(errorId || "")
  const { user } = useUser()
  const toast = useToast()

  useEffect(() => {
    const id = Sentry.captureException(error, {
      level: "error",
      user: user
        ? {
            id: user.id,
            email: user.primaryEmailAddress?.emailAddress,
            username: user.username || undefined,
          }
        : undefined,
      extra: {
        error: error.toString(),
        stack: error.stack,
      },
    })
    setErrorId(id)
  }, [error, user])

  const handleCopy = () => {
    if (errorId) {
      onCopy()
      toast({
        title: "Code d'erreur copié",
        status: "success",
        duration: 2000,
        isClosable: true,
      })
    }
  }

  const handleContactUs = () => {
    const subject = encodeURIComponent("Erreur sur l'application Bleu.care")
    const body = encodeURIComponent(
      `Code d'erreur : ${errorId || "Non disponible"}`,
    )
    window.location.href = `mailto:contact@bleu.care?subject=${subject}&body=${body}`
  }

  return (
    <Box textAlign="center" p={8}>
      <Heading as="h2" size="xl" mb={4} color="red.500">
        Oups ! Une erreur s&apos;est produite.
      </Heading>
      <Text fontSize="lg" mb={6}>
        Nous sommes désolés, quelque chose s&apos;est mal passé. Notre équipe a
        été informée et travaille à résoudre le problème.
      </Text>
      <Text fontSize="sm" mb={2}>
        Si vous avez besoin d&apos;aide, veuillez contacter notre support en
        mentionnant ce code d&apos;erreur :
      </Text>
      <Stack
        direction={{ base: "column", sm: "row" }}
        spacing={2}
        justify="center"
        align="center"
        mb={8}
      >
        <Text
          fontWeight="bold"
          fontSize="small"
          className="w-full sm:w-auto text-center"
        >
          {errorId || "Génération en cours..."}
        </Text>
        <Button
          size="sm"
          variant="outline"
          onClick={handleCopy}
          isDisabled={!errorId}
          className="w-full sm:w-auto"
        >
          {hasCopied ? "Copié !" : "Copier ce code"}
        </Button>
      </Stack>
      <Stack
        direction={{ base: "column", sm: "row" }}
        spacing={4}
        justify="center"
      >
        <Button colorScheme="blue" onClick={resetErrorBoundary}>
          Réessayer
        </Button>
        <Button colorScheme="teal" onClick={handleContactUs}>
          Nous contacter
        </Button>
      </Stack>
    </Box>
  )
}
