declare global {
  class Identify {
    set: (key: string, value: unknown) => void
  }
  interface IdentifyConstructable {
    new (): Identify
  }

  interface Window {
    dataLayer: Record<string, unknown>[]
    amplitudeGTM: {
      Identify: IdentifyConstructable
      identify: (identify: Identify) => void
      track: (
        eventName: string,
        eventProperties: Record<string, unknown>,
      ) => void
    }
  }
}

export const pageview = (url: string) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({
      event: "pageview",
      page: url,
    })
  } else {
    console.log({
      event: "pageview",
      page: url,
    })
  }
}

type GTMEvent =
  | "recap_prescription"
  | "start_payment"
  | "payment_success"
  | "payment_failed"
export const gtmTrack = (
  event: GTMEvent,
  eventProperties?: Record<string, unknown>,
) => {
  if (typeof window.dataLayer !== "undefined") {
    window.dataLayer.push({ event, ...(eventProperties ?? {}) })
  } else {
    console.log({ event })
  }
}

export const amplitudeTrack = (
  eventName: string,
  eventProperties: Record<string, unknown>,
) => {
  if (typeof window.amplitudeGTM !== "undefined") {
    window.amplitudeGTM.track(eventName, eventProperties)
    return
  }

  console.log("amplitude track", eventName, eventProperties)
}

export const amplitudeIdentify = (userId: string) => {
  if (typeof window.amplitudeGTM !== "undefined") {
    const identifyObj = new window.amplitudeGTM.Identify()
    identifyObj.set("user_id", userId)
    window.amplitudeGTM.identify(identifyObj)
    return
  }

  console.log("amplitude identify", userId)
}
