import { z } from "zod"

import { shouldShow } from "@bleu/shared/utils/shouldShow"

import { BiologicalSexOption, QuestionTypes } from "./enums"
import type { BaseQuestionnaireShape } from "./types"

const QuestionNames = {
  biologicalSex: "biologicalSex",
  isSmoker: "isSmoker",
  age: "age",
  weight: "weight",
  height: "height",
  isPregnant: "isPregnant",
} as const

type QuestionName = keyof typeof QuestionNames

const initialValues: ProfileQuestionnaireFormValues = {
  biologicalSex: undefined,
  isPregnant: undefined,
  isSmoker: undefined,
  age: undefined,
  weight: undefined,
  height: undefined,
}

export const profileSchema = z
  .object({
    biologicalSex: z.nativeEnum(BiologicalSexOption),
    isPregnant: z.boolean().optional().nullable(),
    age: z
      .number()
      .min(18, "Le questionnaire n'est accessible qu'aux personnes majeures")
      .max(120, "L'âge maximum autorisé est 120"),
    weight: z
      .number()
      .min(20, "Le poids minimum autorisé est 20kg")
      .max(200, "Le poids maximum autorisé est 200kg"),
    height: z
      .number()
      .min(120, "La taille minimum autorisée est 120cm")
      .max(240, "La taille maximum autorisée est 240cm"),
    isSmoker: z.boolean(),
  })
  .transform((data) => {
    if (data.biologicalSex === BiologicalSexOption.MALE || data.age >= 50) {
      return {
        ...data,
        isPregnant: undefined,
      }
    }
    return data
  })
  .refine(
    (data): data is ProfileData => {
      return (
        (data.biologicalSex === BiologicalSexOption.FEMALE &&
          data.age < 50 &&
          data.isPregnant !== undefined) ||
        data.biologicalSex === BiologicalSexOption.MALE ||
        data.age >= 50
      )
    },
    { message: "isPregnant is mandatory for female over 50" },
  )

interface BaseProfileData {
  age: number
  weight: number
  height: number
  isSmoker: boolean
}
interface FemaleProfileData extends BaseProfileData {
  biologicalSex: typeof BiologicalSexOption.FEMALE
  isPregnant: boolean | undefined
}

interface MaleProfileData extends BaseProfileData {
  biologicalSex: typeof BiologicalSexOption.MALE
  isPregnant: undefined
}

export type ProfileData = FemaleProfileData | MaleProfileData

export type ProfileQuestionnaireFormValues = {
  biologicalSex: keyof typeof BiologicalSexOption | undefined
  isPregnant: boolean | undefined
  isSmoker: boolean | undefined
  age: number | undefined
  weight: number | undefined
  height: number | undefined
}

export type ProfileQuestionnaireShape = Omit<
  BaseQuestionnaireShape<
    QuestionName,
    ProfileData,
    ProfileQuestionnaireFormValues
  >,
  "subtitle" | "moreInfo" | "name"
>

export const profileQuestionnaire: ProfileQuestionnaireShape = {
  questions: [
    {
      id: QuestionNames.biologicalSex,
      type: QuestionTypes.SingleSelect,
      shouldShow,
      title: "Quel est votre sexe biologique ?",
      options: [
        { label: "Homme", value: BiologicalSexOption.MALE },
        { label: "Femme", value: BiologicalSexOption.FEMALE },
      ],
    },
    {
      id: QuestionNames.isSmoker,
      type: QuestionTypes.SingleSelect,
      shouldShow,
      title: "Fumeur(se) ou cessé de fumer depuis moins de 3 ans ?",
      options: [
        { label: "Oui", value: true },
        { label: "Non", value: false },
      ],
    },
    {
      id: QuestionNames.age,
      title: "Âge",
      type: QuestionTypes.Number,
      shouldShow,
    },
    {
      id: QuestionNames.isPregnant,
      type: QuestionTypes.SingleSelect,
      title: "Etes-vous enceinte ?",
      shouldShow: ({ biologicalSex, age }) => {
        return (
          biologicalSex !== undefined &&
          biologicalSex === BiologicalSexOption.FEMALE &&
          !!age &&
          age < 50 &&
          age >= 16
        )
      },
      options: [
        { label: "Oui", value: true },
        { label: "Non", value: false },
      ],
    },
    {
      id: QuestionNames.weight,
      title: "Poids",
      type: QuestionTypes.Number,
      unit: "kg",
      shouldShow,
    },
    {
      id: QuestionNames.height,
      title: "Taille",
      type: QuestionTypes.Number,
      unit: "cm",
      shouldShow,
    },
  ],
  schema: profileSchema,
  initialValues,
} as const
