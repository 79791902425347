import { createIcon } from "@chakra-ui/react"

export const IconVerticalesGeneral = createIcon({
  displayName: "IconVerticalesGeneral",
  viewBox: "0 0 32 32",
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16Zm0-4c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12Z"
      clipRule="evenodd"
    />
  ),
})
