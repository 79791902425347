import { useParams } from "react-router-dom"

import { PrescriptionEditor } from "@bleu/front/components/doctor/PrescriptionEditor"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { usePatientMedicalRecordsQuery } from "@bleu/front/queries/patients"

export const PrescriptionPage = () => {
  const { patientId, recordId } = useParams()
  const medicalRecordQuery = usePatientMedicalRecordsQuery(
    patientId as string,
    recordId as string,
  )
  return (
    <QueryWrapper query={medicalRecordQuery}>
      {({ data }) => <PrescriptionEditor medicalRecord={data} />}
    </QueryWrapper>
  )
}
