import {
  Button,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { IconHeartCheck, IconPill } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useMemo } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import { PatientResponses } from "@bleu/types/endpoints/patients"
import { DocumentType } from "@bleu/types/prisma-client"

import {
  isMedicalRecordStatusBefore,
  MedicalRecordStatus,
  MedicalRecordStatuses,
} from "@bleu/shared/medicalRecords/statuses"
import { computeAllPrescriptionsByType } from "@bleu/shared/prescriptions/index"
import {
  PRESCRIPTION_TYPES,
  PrescriptionType,
} from "@bleu/shared/prescriptions/types"

import { InfoBox } from "@bleu/front/components/InfoBox"
import { Score2Card } from "@bleu/front/components/Score2Card"
import { PhoneCallLogModal } from "@bleu/front/components/doctor/PhoneCallLogModal"
import { Score2CalculationModal } from "@bleu/front/components/doctor/Score2CalculationModal"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { TimelineItem } from "@bleu/front/components/patientSpace/Timeline"
import { DoctorActionItem } from "@bleu/front/pages/doctor/DoctorActionItem"
import { useCloseMedicalRecordMutation } from "@bleu/front/queries/medicalRecords"
import { usePatientMedicalRecordsQuery } from "@bleu/front/queries/patients"

type Props = {
  medicalRecord: PatientResponses.PatientMedicalRecord
}

const _MedicalRecordHomePage = ({ medicalRecord }: Props) => {
  const navigate = useNavigate()
  const score2Modal = useDisclosure()
  const phoneCallModal = useDisclosure()
  const { patientId, recordId } = useParams()

  const { mutateAsync: closeRecord, isPending } = useCloseMedicalRecordMutation(
    medicalRecord.id,
  )

  const prescriptionsByType = useMemo(() => {
    return computeAllPrescriptionsByType(medicalRecord)
  }, [medicalRecord])

  const getTimelineItems = (status: MedicalRecordStatus): TimelineItem[] => {
    return [
      {
        disabled: isMedicalRecordStatusBefore(
          status,
          MedicalRecordStatuses.PENDING_PRESCRIPTION,
          true,
        ),
        title: "Votre rendez-vous téléphonique avec le patient",
        subtitle: medicalRecord.phoneCall
          ? medicalRecord.phoneCall.isCallDone
            ? `Le rendez-vous a eu lieu le ${dayjs(medicalRecord.phoneCall.date).format("LL [à] HH[h]mm")}`
            : `Vous avez rendez-vous le ${dayjs(medicalRecord.phoneCall.date).format("LL [à] HH[h]mm")}`
          : "Le patient n'a pas encore fixé son rendez-vous",
        content: medicalRecord.phoneCall &&
          !medicalRecord.phoneCall.isCallDone && (
            <>
              <Button variant="outline" onClick={phoneCallModal.onOpen}>
                Tracer l&apos;appel téléphonique
              </Button>
              <PhoneCallLogModal
                medicalRecordId={medicalRecord.id}
                phoneCallId={medicalRecord.phoneCall.id}
                isOpen={phoneCallModal.isOpen}
                onClose={phoneCallModal.onClose}
              />
            </>
          ),
      },
      {
        disabled: isMedicalRecordStatusBefore(
          status,
          MedicalRecordStatuses.PENDING_PRESCRIPTION,
          true,
        ),
        title: "Préparation et envoi de l'ordonnance",
        content: (
          <Stack gap={5}>
            <Stack>
              <Text fontWeight="bold" fontSize="sm">
                Ordonnances à éditer:
              </Text>
              {Object.keys(prescriptionsByType).map((prescriptionType) => (
                <DoctorActionItem
                  key={prescriptionType}
                  label={
                    PRESCRIPTION_TYPES[prescriptionType as PrescriptionType]
                  }
                  isCompleted={medicalRecord.documents
                    .map((doc) => doc.type)
                    .includes(prescriptionType as DocumentType)}
                  onClick={() =>
                    navigate(
                      `/doctor/patients/${patientId}/records/${recordId}/prescription/${prescriptionType}`,
                    )
                  }
                />
              ))}
            </Stack>
            <Button
              size="sm"
              as={Link}
              variant="outline"
              leftIcon={<IconPill />}
              to={`/doctor/patients/${patientId}/records/${recordId}/prescription`}
            >
              Editer une ordonnance générique
            </Button>
          </Stack>
        ),
      },
      {
        disabled: isMedicalRecordStatusBefore(
          status,
          MedicalRecordStatuses.PENDING_LAB_RESULTS,
        ),
        title: "Examens du patient",
        content: (
          <Stack>
            {medicalRecord.documents
              .filter((doc) => doc.type === "LAB_RESULTS")
              .map((doc) => (
                <DoctorActionItem
                  key={doc.id}
                  label={doc.name}
                  isCompleted={!!doc.labResultStatus}
                  onClick={() =>
                    navigate(
                      `/doctor/patients/${patientId}/records/${recordId}/lab-results/${doc.id}`,
                    )
                  }
                />
              ))}
          </Stack>
        ),
      },
      {
        hidden: !medicalRecord.questionnaireAnswer.score2,
        disabled:
          !medicalRecord.questionnaireAnswer.score2?.tension &&
          isMedicalRecordStatusBefore(
            status,
            MedicalRecordStatuses.PENDING_LAB_RESULTS,
          ),
        title: "Calcul du Score2",
        content: medicalRecord.score2 ? (
          <Score2Card score2={medicalRecord.score2} />
        ) : (
          <>
            <Button
              size="sm"
              variant="outline"
              onClick={score2Modal.onOpen}
              leftIcon={<IconHeartCheck />}
              isDisabled={
                !medicalRecord.questionnaireAnswer.score2?.tension &&
                isMedicalRecordStatusBefore(
                  status,
                  MedicalRecordStatuses.PENDING_LAB_RESULTS,
                )
              }
            >
              Calculer le Score2
            </Button>
            <Score2CalculationModal
              isOpen={score2Modal.isOpen}
              onClose={score2Modal.onClose}
              medicalRecord={medicalRecord}
            />
          </>
        ),
      },
    ]
  }

  const timelineItems = getTimelineItems(medicalRecord.status)

  return (
    <Stack gap={5}>
      {medicalRecord.status === MedicalRecordStatuses.CLOSED && (
        <InfoBox
          title="Ce dossier est clôturé"
          message="Vous pouvez toujours effectuer des actions sur le dossier, mais son statut restera “Archivé“ pour le patient."
        />
      )}
      <SimpleGrid columns={2} gap={5}>
        {timelineItems.map((item, index) => {
          return <TimelineItem key={index} {...item} number={index + 1} />
        })}
      </SimpleGrid>
      <HStack justify="flex-end">
        <Button
          variant="outline"
          isLoading={isPending}
          isDisabled={medicalRecord.status === MedicalRecordStatuses.CLOSED}
          onClick={() => closeRecord()}
        >
          Clôturer le dossier
        </Button>
      </HStack>
    </Stack>
  )
}

export const MedicalRecordHomePage = () => {
  const { patientId, recordId } = useParams()
  const medicalRecordQuery = usePatientMedicalRecordsQuery(
    patientId as string,
    recordId as string,
  )

  return (
    <QueryWrapper query={medicalRecordQuery}>
      {({ data }) => <_MedicalRecordHomePage medicalRecord={data} />}
    </QueryWrapper>
  )
}
