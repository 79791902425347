import { defineStyleConfig } from "@chakra-ui/react"

export const Button = defineStyleConfig({
  baseStyle: {
    borderRadius: 9999,
    fontWeight: 600,
    // TODO: Cannot overwrite theme with px or paddingInlineStart. Should be possible though
    // paddingLeft: "40px !important",
    // paddingRight: "40px !important",
  },
  sizes: {
    lg: {
      height: 12,
    },
    md: {
      height: 10,
    },
    sm: {
      height: 8,
    },
    xs: {
      height: 6,
    },
  },
  variants: {
    primary: {
      color: "white",
      bgColor: "blue.400",
      _disabled: {
        bgColor: "gray.500",
      },
      _hover: {
        opacity: 0.8,
        _disabled: {
          bgColor: "gray.500",
        },
      },
    },
    outline: {
      color: "blue.400",
      bgColor: "white",
      borderColor: "blue.400",
      _disabled: {
        color: "gray.700",
        borderColor: "gray.700",
        bgColor: "gray.200",
      },
      _hover: {
        bgColor: "blue.50",
        opacity: 0.8,
        _disabled: {
          opacity: 0.4,
          bgColor: "gray.200",
        },
      },
    },
  },
  defaultProps: {
    variant: "primary",
  },
})
