import { z } from "zod"

import type { FormValues } from "@bleu/types/form"

import { DyslipidemiaDiagnosedChronicPathologyOption } from "@bleu/shared/questionnaires"
import { shouldShow } from "@bleu/shared/utils/shouldShow"

import { QuestionTypes, QuestionnairesNames } from "./enums"
import type { BaseQuestionnaireShape } from "./types"

export const dyslipidemiaSchema = z.object({
  diagnosedChronicPathology: z
    .nativeEnum(DyslipidemiaDiagnosedChronicPathologyOption)
    .array()
    .min(1),
  familyHistory: z.boolean(),
})

const QuestionNames = {
  diagnosedChronicPathology: "diagnosedChronicPathology",
  familyHistory: "familyHistory",
} as const

export type DyslipidemiaData = z.infer<typeof dyslipidemiaSchema>

export type DyslipidemiaQuestionnaireShape = BaseQuestionnaireShape<
  keyof typeof QuestionNames,
  DyslipidemiaData,
  FormValues<DyslipidemiaData>
>
const initialValues: FormValues<DyslipidemiaData> = {
  diagnosedChronicPathology:
    new Array<DyslipidemiaDiagnosedChronicPathologyOption>(),
  familyHistory: undefined,
}

export const dyslipidemiaQuestionnaire: DyslipidemiaQuestionnaireShape = {
  name: QuestionnairesNames.dyslipidemia,
  questions: [
    {
      id: QuestionNames.diagnosedChronicPathology,
      type: QuestionTypes.MultiSelect,
      shouldShow,
      title:
        "Souffrez-vous d'une ou plusieurs pathologies chroniques diagnostiquées ?",
      options: [
        {
          label:
            "Cardiovasculaire (angine de poitrine, artérite des membres inférieurs, hypertension artérielle, etc.)",
          value: DyslipidemiaDiagnosedChronicPathologyOption.CARDIOVASCULAR,
        },
        {
          label:
            "Métabolique (diabète, dyslipidémie, syndrôme métabolique, etc.)",
          value: DyslipidemiaDiagnosedChronicPathologyOption.METABOLIC,
        },
        {
          label: "Insuffisance rénale chronique",
          value:
            DyslipidemiaDiagnosedChronicPathologyOption.CHRONIC_RENAL_FAILURE,
        },
        {
          label:
            "Maladie auto-immune ou inflammatoire (polyarthrite rhumatoïde, maladie de Crohn, sclérose en plaques, spondylarthrite ankylosante, etc.).",
          value: DyslipidemiaDiagnosedChronicPathologyOption.INFLAMMATORY,
        },
        {
          label: "Non, aucune des pathologies ci-dessus",
          value: DyslipidemiaDiagnosedChronicPathologyOption.NONE,
          unique: true,
        },
      ],
    },
    {
      id: QuestionNames.familyHistory,
      type: QuestionTypes.SingleSelect,
      title: "Avez-vous des antécédents familiaux de maladie cardiaque ?",
      subtitle:
        "Chez le père ou un frère avant 55 ans, ou chez la mère ou une sœur avant 65 ans.",
      options: [
        {
          label: "Oui",
          value: true,
        },
        {
          label: "Non",
          value: false,
        },
      ],
      shouldShow,
    },
  ],
  schema: dyslipidemiaSchema,
  initialValues,
} as const
