import type { DecryptedConversation } from "@bleu/front/queries/seald"

type Message = DecryptedConversation["decryptedMessages"][number]

interface MessageGroup {
  authorUserId: string
  date: Date
  messages: Message[]
}

export function groupMessagesByDateAndAuthor(
  messages: Message[],
): MessageGroup[] {
  return messages
    .sort((a, b) => a.date.getTime() - b.date.getTime())
    .reduce((acc, currentMessage) => {
      if (!acc.length) {
        return [
          {
            date: currentMessage.date,
            authorUserId: currentMessage.authorUserId,
            messages: [currentMessage],
          },
        ]
      }

      const lastMessageGroup = acc[acc.length - 1]
      const { date: lastMessageGroupDate } = lastMessageGroup

      if (
        lastMessageGroup.authorUserId === currentMessage.authorUserId &&
        isSameDate(lastMessageGroupDate, currentMessage.date)
      ) {
        lastMessageGroup.messages.push(currentMessage)
      } else {
        acc.push({
          date: currentMessage.date,
          authorUserId: currentMessage.authorUserId,
          messages: [currentMessage],
        })
      }
      return acc
    }, [] as MessageGroup[])
}

export const isSameDate = (date1: Date, date2: Date) => {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  )
}
