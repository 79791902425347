import { Box, Button, Heading, Text, VStack } from "@chakra-ui/react"
import { XCircleIcon } from "@heroicons/react/24/solid"
import { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { logger } from "@bleu/front/utils/logger"
import { amplitudeTrack, gtmTrack } from "@bleu/front/utils/tracking"

export const PaymentFailed = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  logger.info("PaymentFailed component rendered")

  useEffect(() => {
    const error = searchParams.get("error")
    if (error) {
      logger.warn("Payment failed, tracking event", { error })
      amplitudeTrack("Payment Failed", { error })
      gtmTrack("payment_failed", { error })
    } else {
      logger.warn("No error parameter found in search params")
    }
  }, [searchParams])

  const handleRetry = () => {
    logger.info("User initiated payment retry")
    navigate("/questionnaire")
  }

  logger.info("Rendering PaymentFailed component content")
  return (
    <Box
      maxWidth="600px"
      margin="auto"
      padding={8}
      bg="white"
      rounded="md"
      border="1px solid"
      borderColor="gray.200"
    >
      <VStack spacing={8} align="center">
        <XCircleIcon className="h-24 w-24 text-red-500" />
        <Heading as="h1" size="xl" textAlign="center">
          Échec du paiement
        </Heading>
        <VStack spacing={4} align="center">
          <Text fontSize="lg" textAlign="center">
            Nous sommes désolés, mais votre paiement n&apos;a pas pu être
            traité.
          </Text>
          <Text fontSize="lg" textAlign="center">
            Veuillez réessayer ou contacter notre support si le problème
            persiste.
          </Text>
        </VStack>
        <Button onClick={handleRetry} colorScheme="blue" size="lg">
          Réessayer le paiement
        </Button>
      </VStack>
    </Box>
  )
}
