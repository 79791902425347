import {
  Text,
  VStack,
  Heading,
  FormControl,
  FormErrorMessage,
  Button,
} from "@chakra-ui/react"
import { useForm, zodResolver } from "@mantine/form"
import { useState, type KeyboardEventHandler } from "react"
import { z } from "zod"

import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { PasswordInput } from "@bleu/front/pages/account/PasswordInput"
import { useConfirmPasswordMutation } from "@bleu/front/queries/account"

interface ConfirmPasswordProps {
  onConfirm: () => void
}

export const ConfirmPassword = ({ onConfirm }: ConfirmPasswordProps) => {
  const [error, setError] = useState(false)
  const { mutateAsync: confirmPassword, isPending } =
    useConfirmPasswordMutation()

  const form = useForm({
    initialValues: {
      password: "",
    },
    validate: zodResolver(z.object({ password: z.string().min(3) })),
  })

  const handleNext = async () => {
    setError(false)
    const isSuccess = await confirmPassword(form.values.password)
    if (isSuccess) {
      onConfirm()
    }
    setError(true)
  }

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (e.key === "Enter" && !isPending) {
      handleNext()
    }
  }

  const isDisabled = !form.isValid()
  return (
    <>
      <VStack p={0} align="stretch" gap={0}>
        <Heading size="2xl">Confirmez votre mot de passe</Heading>
        <VSpacer size={2} />
        <Text>Pour continuer, veuillez confirmer votre mot de passe</Text>
        <VSpacer size={6} />
        <FormControl isInvalid={error}>
          <PasswordInput
            placeholder="Mot de passe"
            autoComplete="password"
            onKeyDown={handleKeyDown}
            {...form.getInputProps("password")}
          />
          <FormErrorMessage>Le mot de passe est incorrect</FormErrorMessage>
        </FormControl>
      </VStack>
      <Button
        isLoading={isPending}
        position="absolute"
        bottom={10}
        w="100%"
        isDisabled={isDisabled}
        onClick={handleNext}
      >
        Continuer
      </Button>
    </>
  )
}
