import {
  Button,
  forwardRef,
  HStack,
  Icon,
  Text,
  VStack,
  type ButtonProps,
} from "@chakra-ui/react"

import {
  allVerticalNameList,
  VerticalName,
  VerticalsLongNames,
} from "@bleu/shared/verticals"

import {
  verticalsTheme,
  type VerticalTheme,
} from "@bleu/front/assets/theme/verticalsTheme"
import QuestionnaireLayout from "@bleu/front/components/layout/QuestionnaireLayout"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { useVerticalsStore } from "@bleu/front/lib/stores"
import type { StepProps } from "@bleu/front/pages/types"

const variantsProps: Record<VerticalSelectionButtonVariant, VariantsProps> = {
  [VerticalName.CARDIO]: {
    ...verticalsTheme[VerticalName.CARDIO],
    background:
      "radial-gradient(84.92% 101.3% at 50% 50%, #FEA5DD 0%, #F5E6FB 100%), #F5E6FB",
  },
  [VerticalName.CANCER]: {
    ...verticalsTheme[VerticalName.CANCER],
    background:
      "radial-gradient(87.71% 62.13% at 56.18% 55.68%, #BDB1FF 0%, #EAE7FF 100%)",
  },
  [VerticalName.STI]: {
    ...verticalsTheme[VerticalName.STI],
    background:
      "radial-gradient(50% 50% at 50% 50%, #F9E6A5 0%, #FEF9E6 100%), #FEF9E6",
  },
  [VerticalName.SUBSTITUTES]: {
    ...verticalsTheme[VerticalName.SUBSTITUTES],
    background:
      "radial-gradient(50% 50% at 50% 50%, #BBE4B7 0%, #E6F6E5 100%), #E6F6E5",
  },
  [VerticalName.GENERAL]: {
    ...verticalsTheme[VerticalName.GENERAL],
    background:
      "radial-gradient(50% 50% at 50% 50%, #89CAF8 0%, #E0F1FD 100%), #E6F6E5",
  },
} as const

type VerticalSelectionButtonVariant = VerticalName
interface VerticalSelectionButtonProps {
  variant: VerticalSelectionButtonVariant
  isSelected: boolean
}

interface VariantsProps extends VerticalTheme {
  background: ButtonProps["background"]
}

const VerticalSelectionButton = forwardRef<
  Omit<ButtonProps, "variant"> & VerticalSelectionButtonProps,
  "button"
>(({ variant, isSelected = false, ...props }) => {
  const selectedStyles = {
    boxShadow: `0px 4px 10px 0px ${variantsProps[variant].color}50`,
    border: `2px solid ${variantsProps[variant].color}`,
  }

  return (
    <Button
      height="auto"
      borderRadius={8}
      paddingX={6}
      paddingY={4}
      color="navy.400"
      width="100%"
      {...(isSelected && selectedStyles)}
      background={variantsProps[variant].background}
      {...props}
    >
      <HStack
        alignItems="center"
        justifyContent="flex-start"
        width="100%"
        overflow="hidden"
      >
        <Icon
          as={variantsProps[variant].icon}
          color={variantsProps[variant].color}
          boxSize={5}
        />
        <Text fontWeight={600} textOverflow="ellipsis" overflow="hidden">
          {props.children}
        </Text>
      </HStack>
    </Button>
  )
})

export const VerticalsSelection = ({ onNext, onBack }: StepProps) => {
  const { selectedVerticals, setSelectedVerticals } = useVerticalsStore()

  const handleVerticalSelection = (verticalName: VerticalName) => {
    if (
      verticalName === VerticalName.GENERAL &&
      !selectedVerticals.includes(VerticalName.GENERAL)
    ) {
      setSelectedVerticals([VerticalName.GENERAL])
      return
    }

    if (selectedVerticals.includes(verticalName)) {
      setSelectedVerticals(selectedVerticals.filter((v) => v !== verticalName))
    } else {
      setSelectedVerticals([
        ...selectedVerticals.filter((v) => v !== VerticalName.GENERAL),
        verticalName,
      ])
    }
  }

  const isDisabled = selectedVerticals.length < 1

  return (
    <>
      <QuestionnaireLayout.Header
        pageName="Sélection des dépistages"
        onBack={onBack}
        progress={20}
      />
      <QuestionnaireLayout.Title
        title={{
          text: "Sélectionnez votre dépistage",
          highlight: "votre dépistage",
        }}
      />
      <VSpacer size={20} />
      <VStack gap={4} alignItems="center">
        {allVerticalNameList.map((verticalName) => (
          <VerticalSelectionButton
            key={verticalName}
            isSelected={selectedVerticals.includes(verticalName)}
            variant={verticalName}
            onClick={() => handleVerticalSelection(verticalName)}
          >
            {VerticalsLongNames[verticalName]}
          </VerticalSelectionButton>
        ))}
      </VStack>
      <QuestionnaireLayout.CTA onClick={onNext} isDisabled={isDisabled}>
        Continuer
      </QuestionnaireLayout.CTA>
    </>
  )
}
