import "./TipTap.css"
import { ButtonGroup, IconButton } from "@chakra-ui/react"
import { IconBold, IconItalic, IconList } from "@tabler/icons-react"
import BulletList from "@tiptap/extension-bullet-list"
import Document from "@tiptap/extension-document"
import ListItem from "@tiptap/extension-list-item"
import OrderedList from "@tiptap/extension-ordered-list"
import Paragraph from "@tiptap/extension-paragraph"
import Text from "@tiptap/extension-text"
import { EditorContent, JSONContent, useEditor, Editor } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import React, { useCallback, useMemo } from "react"

interface TiptapEditorProps {
  onUpdate: (content: JSONContent) => void
  onEditorReady: (editor: Editor) => void
}

const listConfig = {
  HTMLAttributes: {
    class: "ml-4",
  },
}

/**
 * TiptapEditor component for rich text editing
 * @param onUpdate - Callback function to handle content updates
 * @param onEditorReady - Callback function to handle editor initialization
 */
export const TiptapEditor: React.FC<TiptapEditorProps> = React.memo(
  ({ onUpdate, onEditorReady }) => {
    const extensions = useMemo(
      () => [
        Document,
        Paragraph,
        Text,
        BulletList.configure({
          ...listConfig,
          HTMLAttributes: {
            ...listConfig.HTMLAttributes,
            class: "list-disc ml-4",
          },
        }),
        OrderedList.configure({
          ...listConfig,
          HTMLAttributes: {
            ...listConfig.HTMLAttributes,
            class: "list-decimal ml-4",
          },
        }),
        ListItem,
        StarterKit.configure({
          heading: false,
          bulletList: false,
          orderedList: false,
        }),
      ],
      [],
    )

    const editor = useEditor({
      extensions,
      onUpdate: ({ editor }) => onUpdate(editor.getJSON()),
      editorProps: {
        attributes: {
          class: "prose-mirror-content",
        },
      },
      onCreate: ({ editor }) => {
        onEditorReady(editor)
      },
    })

    const EditorButton = useCallback(
      ({
        label,
        icon,
        action,
        isActive,
      }: {
        label: string
        icon: React.ReactNode
        action: () => void
        isActive: boolean
      }) => (
        <IconButton
          aria-label={label}
          icon={icon as React.ReactElement}
          onClick={action}
          isActive={isActive}
        />
      ),
      [],
    )

    const buttons = useMemo(
      () => [
        {
          label: "Bold",
          icon: <IconBold size={16} />,
          action: () => editor?.chain().focus().toggleBold().run(),
          isActive: editor?.isActive("bold") || false,
        },
        {
          label: "Italic",
          icon: <IconItalic size={16} />,
          action: () => editor?.chain().focus().toggleItalic().run(),
          isActive: editor?.isActive("italic") || false,
        },
        {
          label: "Bullet List",
          icon: <IconList size={16} />,
          action: () => editor?.chain().focus().toggleBulletList().run(),
          isActive: editor?.isActive("bulletList") || false,
        },
        {
          label: "Ordered List",
          icon: <IconList size={16} />,
          action: () => editor?.chain().focus().toggleOrderedList().run(),
          isActive: editor?.isActive("orderedList") || false,
        },
      ],
      [editor],
    )

    return (
      <div className="tiptap-editor-wrapper">
        <ButtonGroup size="sm" isAttached variant="outline" className="mb-2">
          {buttons.map((button) => (
            <EditorButton key={button.label} {...button} />
          ))}
        </ButtonGroup>
        <div className="tiptap-editor-content-wrapper">
          <EditorContent
            placeholder="Entrez votre message ici..."
            editor={editor}
            className="tiptap-editor-content"
          />
        </div>
      </div>
    )
  },
)

TiptapEditor.displayName = "TiptapEditor"
