import {
  Icon,
  IconButton,
  forwardRef,
  type IconButtonProps,
} from "@chakra-ui/react"
import { IconChevronLeft } from "@tabler/icons-react"

export const BackButton = forwardRef<
  Omit<IconButtonProps, "aria-label">,
  "button"
>((props, ref) => (
  <IconButton
    {...props}
    variant="outline"
    ref={ref}
    icon={<Icon as={IconChevronLeft} />}
    aria-label="Retour"
  />
))
