export * from "./enums"
export * from "./types"
export * from "./profile"
export * from "./sti"

import { z } from "zod"

import { score2Questionnaire } from "@bleu/shared/questionnaires/score2"
import { stiQuestionnaire } from "@bleu/shared/questionnaires/sti"

import { abdominalAorticAneurysmQuestionnaire } from "./abdominalAorticAneurysm"
import { b9SupplementsQuestionnaire } from "./b9Supplements"
import { breastCancerQuestionnaire } from "./breastCancer"
import { cervicalCancerQuestionnaire } from "./cervicalCancer"
import { colorectalCancerQuestionnaire } from "./colorectalCancer"
import { diabetesQuestionnaire } from "./diabetes"
import { dyslipidemiaQuestionnaire } from "./dyslipidemia"
import type { QuestionnaireName } from "./enums"
import { nicotineSubstitutionQuestionnaire } from "./nicotineSubstitution"
import { profileQuestionnaire, type ProfileQuestionnaireShape } from "./profile"
import type { AllInitialFormValues, QuestionnaireShape } from "./types"

export const questionnaires: Record<QuestionnaireName, QuestionnaireShape> & {
  profile: ProfileQuestionnaireShape
} = {
  abdominalAorticAneurysm: abdominalAorticAneurysmQuestionnaire,
  breastCancer: breastCancerQuestionnaire,
  cervicalCancer: cervicalCancerQuestionnaire,
  colorectalCancer: colorectalCancerQuestionnaire,
  diabetes: diabetesQuestionnaire,
  b9Supplements: b9SupplementsQuestionnaire,
  dyslipidemia: dyslipidemiaQuestionnaire,
  nicotineSubstitution: nicotineSubstitutionQuestionnaire,
  profile: profileQuestionnaire,
  score2: score2Questionnaire,
  sti: stiQuestionnaire,
} as const

const allQuestionnaires = Object.keys(questionnaires) as QuestionnaireName[]

/**
 * {profile: profileQuestionnaire.schema, ...}
 */
export const generalSchema = z.object(
  allQuestionnaires.reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: questionnaires[curr].schema.optional().nullable(),
    }),
    {},
  ),
)

/**
 * {profile: profileQuestionnaire.initialValues, ...}
 */
export const generalInitialValues = allQuestionnaires.reduce(
  (prev, curr) => ({ ...prev, [curr]: questionnaires[curr].initialValues }),
  {},
) as AllInitialFormValues
