import { VStack } from "@chakra-ui/react"
import { Link, Outlet, useLocation } from "react-router-dom"

import { BackButton } from "@bleu/front/components/layout/BackButton"
import { VSpacer } from "@bleu/front/components/layout/Spacer"

export const EditPreferenceLayout = () => {
  const { pathname } = useLocation()

  const to = pathname === "/account/preferences" ? "/" : "/account/preferences"

  return (
    <>
      <BackButton as={Link} to={to} />
      <VSpacer size={4} />
      <VStack minH={500} alignItems="stretch">
        <Outlet />
      </VStack>
    </>
  )
}
