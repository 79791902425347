import { Image, Text, VStack } from "@chakra-ui/react"
import _ from "lodash"
import { useMemo } from "react"

import { type DepistageName } from "@bleu/shared/depistages"
import { computeAllPrescriptions } from "@bleu/shared/prescriptions"
import { PrescriptionType } from "@bleu/shared/prescriptions/types"
import {
  type AllQuestionnairesAnswers,
  type ProfileData,
} from "@bleu/shared/questionnaires"

import { InfoBox } from "@bleu/front/components/InfoBox"
import { PrescriptionRecapItem } from "@bleu/front/components/funnelSteps/PrescriptionRecapItem"
import QuestionnaireLayout from "@bleu/front/components/layout/QuestionnaireLayout"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { useFunnelStore } from "@bleu/front/lib/stores"
import type { StepProps } from "@bleu/front/pages/types"

interface Props extends StepProps {
  depistagesNames: DepistageName[]
  allAnswers: AllQuestionnairesAnswers
  profile: ProfileData
  onSubmit: (prescribedDepistages: DepistageName[]) => void
  onRestart: () => void
}

export const PrescriptionRecap = ({
  depistagesNames,
  allAnswers,
  profile,
  onNext,
  onBack,
  onSubmit,
  onRestart,
}: Props) => {
  const { setPrescribedDepistages } = useFunnelStore()
  const prescriptionRecap = useMemo(() => {
    const allPrescriptions = computeAllPrescriptions(
      depistagesNames,
      allAnswers,
      profile!,
    )

    // If only addressing prescriptions then, deliver nothing
    const hasOnlyAddressing = Object.values(allPrescriptions)
      .flat()
      .every(
        (p) => p.prescriptionType === PrescriptionType.PRESCRIPTION_ADDRESSING,
      )

    return _.mapValues(allPrescriptions, (prescriptions) => {
      if (hasOnlyAddressing) return false
      return prescriptions && prescriptions.length > 0
    })
  }, [allAnswers, depistagesNames, profile])

  const showInfoBox = Object.values(prescriptionRecap).some((value) => !value)

  const handleClick = () => {
    const prescribedDepistages = (
      Object.keys(prescriptionRecap) as DepistageName[]
    ).filter((depistageName) => prescriptionRecap[depistageName])

    setPrescribedDepistages(prescribedDepistages)

    onSubmit(prescribedDepistages)
    onNext()
  }

  if (
    Object.values(prescriptionRecap).every((value) => !value) ||
    depistagesNames.length === 0
  ) {
    return <EmptyPrescriptionRecap onRestart={onRestart} />
  }
  return (
    <>
      <QuestionnaireLayout.Header onBack={onBack} progress={80} />
      <QuestionnaireLayout.Title
        title={{
          text: "Récapitulatif de vos prescriptions",
          highlight: "vos prescriptions",
        }}
      />
      <VSpacer size={10} />
      <VStack gap={8} alignItems="flex-start">
        {
          // TODO pierrick wants to check type casting here
          (
            Object.keys(prescriptionRecap) as (keyof typeof prescriptionRecap)[]
          ).map((depistageName) => (
            <PrescriptionRecapItem
              key={depistageName}
              despistageName={depistageName}
              isPrescribed={!!prescriptionRecap[depistageName]}
            />
          ))
        }
      </VStack>
      {showInfoBox && (
        <>
          <VSpacer size={10} />
          <InfoBox
            isCollapsible={true}
            title="Explications"
            message="D'après vos réponses, certains dépistages ne sont finalement par recommandés pour vous à ce jour. Vous pouvez refaire une demande dans le futur."
          />
        </>
      )}
      <VSpacer size={16} />
      <Text fontSize="sm" mb={-8}>
        Nous avons besoin de vos coordonnées pour l&apos;ordonnance. En créant
        un compte sur l&apos;écran suivant, vous pourrez accéder à votre espace
        sécurisé et accéder à tous vos documents et échanges avec l&apos;équipe
        médicale
      </Text>
      <QuestionnaireLayout.CTA onClick={handleClick}>
        Finaliser et payer
      </QuestionnaireLayout.CTA>
    </>
  )
}

const EmptyPrescriptionRecap = ({ onRestart }: { onRestart: () => void }) => {
  return (
    <>
      <QuestionnaireLayout.Header
        pageName="Récapitulatif de vos ordonnances"
        onBack={onRestart}
        progress={100}
      />
      <QuestionnaireLayout.Title title="Pas de dépistage recommandé" />
      <VSpacer size={10} />
      <Image
        src="/img/monster.png"
        alt="Mascotte"
        maxW={200}
        alignSelf="center"
      />
      <VSpacer size={10} />
      <VStack gap={8} alignItems="stretch">
        <Text fontWeight={600} textAlign="center">
          D&apos;après vos réponses au questionnaire, vous n&apos;êtes
          actuellement concerné par aucun des dépistages proposés par Bleu, ou
          vous n&apos;avez pas souhaité les réaliser pour l&apos;instant.
        </Text>
      </VStack>
      <QuestionnaireLayout.CTA onClick={onRestart}>
        Refaire le questionnaire
      </QuestionnaireLayout.CTA>
    </>
  )
}
