import {
  InputGroup,
  Input,
  InputRightElement,
  type InputProps,
} from "@chakra-ui/react"
import { IconEyeClosed, IconEye } from "@tabler/icons-react"
import { useState } from "react"

import { colors } from "@bleu/front/assets/theme/colors"

export const PasswordInput = (props: InputProps) => {
  const [show, setShow] = useState(false)

  const handleEyeClick = () => {
    setShow((show) => !show)
  }

  return (
    <InputGroup>
      <Input type={show ? "text" : "password"} {...props} />
      <InputRightElement>
        {show ? (
          <IconEyeClosed onClick={handleEyeClick} color={colors.blue[400]} />
        ) : (
          <IconEye onClick={handleEyeClick} color={colors.blue[400]} />
        )}
      </InputRightElement>
    </InputGroup>
  )
}
