import { z } from "zod"

import type { FormValues } from "@bleu/types/form"

import { DiabetesRiskFactorOption } from "@bleu/shared/questionnaires"

import { QuestionTypes, QuestionnairesNames } from "./enums"
import type { BaseQuestionnaireShape } from "./types"
import { computeBodyMassIndexFromProfile } from "./utils"

export const diabetesSchema = z.object({
  riskFactors: z.nativeEnum(DiabetesRiskFactorOption).array().min(0),
})
export type DiabetesData = z.infer<typeof diabetesSchema>
type DiabetesQuestionnaireFormValues = FormValues<DiabetesData>

const DiabetesQuestionsNames = {
  riskFactors: "riskFactors",
} as const
type DiabetesQuestionName = keyof typeof DiabetesQuestionsNames

export type DiabetesQuestionnaireShape = BaseQuestionnaireShape<
  DiabetesQuestionName,
  DiabetesData,
  DiabetesQuestionnaireFormValues
>

const initialValues: DiabetesQuestionnaireFormValues = {
  riskFactors: new Array<DiabetesRiskFactorOption>(),
}

export const diabetesQuestionnaire: DiabetesQuestionnaireShape = {
  name: QuestionnairesNames.diabetes,
  questions: [
    {
      id: DiabetesQuestionsNames.riskFactors,
      type: QuestionTypes.MultiSelect,
      title: "L'un des facteurs de risque suivants s'applique-t-il à vous ?",
      shouldShow: (_, { age, ...profile }) => {
        const BMI = computeBodyMassIndexFromProfile(profile)
        return !(age >= 45 && BMI >= 28)
      },
      options: [
        {
          label: "Origine géographique non caucasienne et/ou migrante",
          value: DiabetesRiskFactorOption.ORIGIN,
        },
        {
          label:
            "Sédentarité, définie par l’absence d’activité physique régulière (30 minutes, 3 fois par semaine)",
          value: DiabetesRiskFactorOption.SEDENTARITY,
        },
        {
          label:
            "Antécédent personnel de diabète gestationnel ou d’accouchement d’un ou plusieurs enfants de poids de naissance > 4 kg",
          value: DiabetesRiskFactorOption.PERSONAL_HISTORY,
        },
        {
          label:
            "Antécédent familial de diabète chez un apparenté du 1er degré (père, mère, fratrie)",
          value: DiabetesRiskFactorOption.FAMILY_HISTORY,
        },
        {
          label:
            "Traitement en cours pouvant induire un diabète ou antécédent de diabète induit par un traitement (corticoïdes, etc.)",
          value: DiabetesRiskFactorOption.TREATEMENT,
        },
        {
          label:
            "Hypertension artérielle traitée ou non traitée, définie par une pression artérielle systolique > 140 mmHg et/ou une pression artérielle diastolique > 90 mmHg et/ou une hypertension artérielle connue traitée",
          value: DiabetesRiskFactorOption.HYPERTENSION,
        },
        {
          label:
            "Dyslipidémie (niveau de lipides dans le sang trop élevé) traitée ou non traitée, définie par une HDL-cholestérolémie  2 g/l (2,3 mmol/l)",
          value: DiabetesRiskFactorOption.DYSLIPIDEMIA,
        },
        {
          label: "Aucune des situations ci-dessus",
          value: DiabetesRiskFactorOption.NONE,
          unique: true,
        },
      ],
    },
  ],
  schema: diabetesSchema,
  initialValues,
} as const
