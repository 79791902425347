import { Button, HStack, Icon, Text } from "@chakra-ui/react"
import { useMemo } from "react"

import {
  DepistageName,
  getVerticalNameForDepistageName,
} from "@bleu/shared/depistages/index"
import { VerticalName } from "@bleu/shared/verticals/index"

import { verticalsTheme } from "@bleu/front/assets/theme/verticalsTheme"

type Props = {
  label: string
  isSelected: boolean
  prescribedDepistages: DepistageName[]
}

export const MedicalRecordButton = ({
  label,
  isSelected,
  prescribedDepistages,
}: Props) => {
  const vertical = useMemo(() => {
    const verticalNames = [
      ...new Set(
        prescribedDepistages.map((depistage) =>
          getVerticalNameForDepistageName(depistage),
        ),
      ),
    ]
    const verticalName =
      verticalNames.length === 1 ? verticalNames[0] : VerticalName.GENERAL
    return verticalsTheme[verticalName]
  }, [prescribedDepistages])

  return (
    <Button
      w="100%"
      variant="unstyled"
      borderRadius={8}
      borderWidth={1}
      borderColor={isSelected ? "blue.400" : "blue.300"}
      bgColor={isSelected ? "blue.200" : "blue.50"}
      _hover={{ bgColor: "blue.200" }}
      px={4}
      py={2}
    >
      <HStack gap={2}>
        <Icon boxSize={5} as={vertical.icon} color={vertical.color} />
        <Text fontWeight="bold" fontSize="sm">
          {label}
        </Text>
      </HStack>
    </Button>
  )
}
