import {
  Button,
  Input,
  InputGroup,
  InputRightAddon,
  VStack,
  useToast,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Modal,
  ModalContent,
  ModalBody,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react"
import { useForm, zodResolver } from "@mantine/form"

import type { PatientResponses } from "@bleu/types/endpoints/patients"

import { score2ConstantsSchema } from "@bleu/shared/questionnaires/score2"

import { InfoBox } from "@bleu/front/components/InfoBox"
import { useUpdateScore2Mutation } from "@bleu/front/queries/medicalRecords"

type Props = {
  isOpen: boolean
  onClose: () => void
  medicalRecord: PatientResponses.PatientMedicalRecord
}

const score2CalculationSchema = score2ConstantsSchema.refine(
  (data) => {
    const nonHdlCholesterol = data.totalCholesterol - data.hdlCholesterol
    return nonHdlCholesterol >= 3 && nonHdlCholesterol < 7
  },
  {
    path: ["hdlCholesterol"],
    message:
      "Le taux de cholestérol non-HDL doit être compris entre 3 et 7 mmol/L",
  },
)

export const Score2CalculationModal = ({
  medicalRecord,
  isOpen,
  onClose,
}: Props) => {
  const score2Data = medicalRecord.questionnaireAnswer.score2
  const toast = useToast()

  const form = useForm({
    initialValues: {
      tension: score2Data?.tension ?? "",
      totalCholesterol: score2Data?.totalCholesterol ?? "",
      hdlCholesterol: score2Data?.hdlCholesterol ?? "",
    },
    validate: zodResolver(score2CalculationSchema),
    validateInputOnChange: true,
  })

  const {
    mutateAsync: updateScore2,
    isPending,
    isError,
  } = useUpdateScore2Mutation(medicalRecord.id)

  const handleSubmit = async () => {
    const { tension, totalCholesterol, hdlCholesterol } =
      score2CalculationSchema.parse(form.values)
    const score2 = await updateScore2({
      tension,
      hdlCholesterol,
      totalCholesterol,
    })
    toast(
      score2 == null
        ? { title: "Erreur lors de la mise à jour du score 2", status: "error" }
        : { title: "Score2 mis à jour", status: "success" },
    )
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Calcul du Score2</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            {isError && (
              <InfoBox
                title="Erreur de calcul du Score2"
                message={
                  "Nous n'avons pas trouvé de valeur du Score2 dans la table de correspondance. Vérifiez que les valeurs rentrées sont correctes et réessayez"
                }
              />
            )}
            <FormControl isInvalid={!!form.errors.tension}>
              <InputGroup>
                <Input
                  type="number"
                  placeholder="Tension artérielle systolique"
                  onChange={(e) => {
                    form
                      .getInputProps("tension")
                      .onChange(parseFloat(e.target.value))
                  }}
                  value={form.values.tension}
                />
                <InputRightAddon>mmHg</InputRightAddon>
              </InputGroup>
              <FormErrorMessage>{form.errors.tension}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!form.errors.totalCholesterol}>
              <InputGroup>
                <Input
                  type="number"
                  placeholder="Cholestérol total"
                  value={form.values.totalCholesterol}
                  onChange={(e) => {
                    form
                      .getInputProps("totalCholesterol")
                      .onChange(parseFloat(e.target.value))
                  }}
                />
                <InputRightAddon>mmol/L</InputRightAddon>
              </InputGroup>
              <FormErrorMessage>
                {form.errors.totalCholesterol}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={!!form.errors.hdlCholesterol}>
              <InputGroup>
                <Input
                  type="number"
                  placeholder="HDL cholestérol"
                  value={form.values.hdlCholesterol}
                  onChange={(e) => {
                    form
                      .getInputProps("hdlCholesterol")
                      .onChange(parseFloat(e.target.value))
                  }}
                />
                <InputRightAddon>mmol/L</InputRightAddon>
              </InputGroup>
              <FormErrorMessage>{form.errors.hdlCholesterol}</FormErrorMessage>
            </FormControl>
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={!form.isValid()}
            onClick={handleSubmit}
            isLoading={isPending}
          >
            Mettre à jour
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
