import { Stack, Text } from "@chakra-ui/react"
import { RedirectToSignUp } from "@clerk/clerk-react"
import * as Sentry from "@sentry/react"
import { useMutation } from "@tanstack/react-query"
import { useEffect, useRef } from "react"

import { MedicalRecord } from "@bleu/types/prisma-client"

import { VerticalName } from "@bleu/shared/verticals"

import { FullPageLoader } from "@bleu/front/components/layout/FullPageLoader"
import { Loader } from "@bleu/front/components/layout/Loader"
import QuestionnaireLayout from "@bleu/front/components/layout/QuestionnaireLayout"
import { apiClient } from "@bleu/front/lib/apiClient"
import { useAuthState } from "@bleu/front/queries/auth"
import { logger } from "@bleu/front/utils/logger"

type Props = {
  medicalRecordCode: string
  onSaveAnswers: (medicalRecordCode: string) => Promise<MedicalRecord>
  onBack: () => void
  verticalSelection: VerticalName[]
}

export const Payment = ({
  medicalRecordCode,
  onSaveAnswers,
  onBack,
  verticalSelection: selectedVerticals,
}: Props) => {
  const { isLoading, isSignedIn } = useAuthState()
  const hasInitiatedCheckout = useRef(false)

  logger.info("Payment component rendered", {
    medicalRecordCode,
    selectedVerticals,
  })

  const { mutateAsync: createCheckoutSession, isPending } = useMutation({
    mutationFn: async () => {
      logger.info("Creating checkout session", {
        medicalRecordCode,
        selectedVerticals,
      })
      const verticalToUse =
        selectedVerticals.length > 1
          ? VerticalName.GENERAL
          : selectedVerticals[0]

      const response = await apiClient.post<{ url: string }>(
        "/stripe/create-checkout-session",
        { medicalRecordCode, vertical: verticalToUse },
      )
      logger.info("Checkout session created", { medicalRecordCode })
      return response.data
    },
    onSuccess: (data) => {
      logger.info("Redirecting to checkout", {
        medicalRecordCode,
        url: data.url,
      })
      window.location.href = data.url
    },
    onError: (error) => {
      logger.error("Error creating checkout session", {
        medicalRecordCode,
        error,
      })
      Sentry.captureException(error, { extra: { medicalRecordCode } })
      onBack()
    },
  })

  useEffect(() => {
    const initiateCheckout = async () => {
      if (
        !isLoading &&
        isSignedIn &&
        !isPending &&
        !hasInitiatedCheckout.current
      ) {
        logger.info("Initiating checkout process", { medicalRecordCode })
        hasInitiatedCheckout.current = true
        try {
          logger.info("Saving answers", { medicalRecordCode })
          await onSaveAnswers(medicalRecordCode)
          logger.info("Answers saved successfully", { medicalRecordCode })
          await createCheckoutSession()
        } catch (e) {
          logger.error("Error during checkout process", {
            medicalRecordCode,
            error: e,
          })
          Sentry.captureException(e, { extra: { medicalRecordCode } })
          onBack()
        }
      }
    }

    initiateCheckout()
  }, [
    isLoading,
    isSignedIn,
    isPending,
    medicalRecordCode,
    createCheckoutSession,
    onSaveAnswers,
    onBack,
  ])

  if (isLoading) {
    logger.info("Rendering FullPageLoader", { medicalRecordCode })
    return <FullPageLoader />
  }
  if (!isSignedIn) {
    logger.info("User not signed in, redirecting to sign up", {
      medicalRecordCode,
    })
    return <RedirectToSignUp redirectUrl="/questionnaire?step=payment" />
  }

  logger.info("Rendering Payment component content", { medicalRecordCode })
  return (
    <Stack gap={6}>
      <QuestionnaireLayout.Header onBack={onBack} progress={90} />
      <QuestionnaireLayout.Title title="Paiement" />
      <Text size="lg" fontWeight={600} my={2} display={["none", "block"]}>
        Dernière étape
      </Text>
      <Text display={["none", "block"]}>
        Pour finaliser votre demande d&apos;ordonnance, vous allez être redirigé
        vers notre page de paiement sécurisée. Votre paiement ne sera confirmé
        qu&apos;après validation de votre profil par notre équipe médicale et
        délivrance de votre ordonnance.
      </Text>
      {isPending ? (
        <Loader />
      ) : (
        <Text>Préparation de votre session de paiement sécurisée...</Text>
      )}
    </Stack>
  )
}
