import {
  Text,
  VStack,
  HStack,
  Flex,
  type SystemStyleObject,
  type StackProps,
} from "@chakra-ui/react"
import type { ReactNode } from "react"

export interface TimelineItem {
  disabled: boolean
  title: string
  subtitle?: string
  content?: ReactNode
  hidden?: boolean
}

interface TimelineItemProps extends TimelineItem {
  number: number
}

export const TimelineItem = ({
  number,
  title,
  subtitle,
  content,
  disabled,
  hidden = false,
}: TimelineItemProps) => {
  if (hidden) return null
  return (
    <VStack
      gap={3}
      px={4}
      py={5}
      borderRadius={8}
      boxShadow="md"
      bgColor="white"
      border="1px solid bleu.200"
      alignItems="stretch"
    >
      <HStack alignItems="flex-start">
        <Flex
          marginTop={1}
          w={4}
          h={4}
          borderRadius={99}
          bgColor={disabled ? "gray.400" : "blue.400"}
          flexShrink={0}
          justifyContent="center"
          alignItems="center"
        >
          <Text size="sm" color="white" fontWeight={600}>
            {number}
          </Text>
        </Flex>
        <VStack alignItems="start" gap={1}>
          <Text
            size="lg"
            fontWeight={600}
            {...(disabled && { color: "gray.400" })}
          >
            {title}
          </Text>
          {subtitle && (
            <Text size="sm" {...(disabled && { color: "gray.400" })}>
              {subtitle}
            </Text>
          )}
        </VStack>
      </HStack>
      {content}
    </VStack>
  )
}

interface TimelineProps extends StackProps {
  items: TimelineItem[]
}
export const Timeline = ({ items, ...props }: TimelineProps) => {
  const blueLineInBackground: SystemStyleObject = {
    zIndex: -1,
    content: "''",
    position: "absolute",
    backgroundColor: "blue.400",
    width: "2px",
    top: 0,
    bottom: 0,
    left: "23px",
  }

  return (
    <VStack
      alignItems="stretch"
      position="relative"
      gap={4}
      _after={blueLineInBackground}
      {...props}
    >
      {items.map((item, index) => {
        return <TimelineItem key={index} {...item} number={index + 1} />
      })}
    </VStack>
  )
}
