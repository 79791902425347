import {
  DyslipidemiaDiagnosedChronicPathologyOption,
  BiologicalSexOption,
} from "@bleu/shared/questionnaires"
import { computeBodyMassIndexFromProfile } from "@bleu/shared/questionnaires/utils"

import type { ComputePrescription } from "./types"

export const DYSLIPIDEMIA_PRESCRIPTIONS = {
  eal: {
    shortName: "EAL",
    title: "Faire réaliser un bilan lipidique",
    description: "(le matin à jeun depuis 12h, eau possible, pas de tabac)",
    prescriptionType: "PRESCRIPTION_BIOLOGY",
  },
} as const

export const dyslipidemiaComputePrescription: ComputePrescription = (
  { dyslipidemia },
  { age, biologicalSex, isSmoker, ...profile },
) => {
  const { familyHistory, diagnosedChronicPathology } = dyslipidemia!
  const bmi = computeBodyMassIndexFromProfile(profile)

  if (age >= 40 && biologicalSex === BiologicalSexOption.MALE) {
    return [DYSLIPIDEMIA_PRESCRIPTIONS.eal]
  }
  if (age >= 50 && biologicalSex === BiologicalSexOption.FEMALE) {
    return [DYSLIPIDEMIA_PRESCRIPTIONS.eal]
  }
  if (bmi >= 25 || isSmoker || familyHistory) {
    return [DYSLIPIDEMIA_PRESCRIPTIONS.eal]
  }
  if (
    !diagnosedChronicPathology.includes(
      DyslipidemiaDiagnosedChronicPathologyOption.NONE,
    )
  ) {
    return [DYSLIPIDEMIA_PRESCRIPTIONS.eal] // VRAIBIS
  }

  return []
}
