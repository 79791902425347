import { Divider, HStack, Heading, Icon, VStack } from "@chakra-ui/react"
import type { UseFormReturnType } from "@mantine/form"
import _ from "lodash"
import { useEffect, useMemo } from "react"

import {
  DepistagesLongNames,
  getVerticalNameForDepistageName,
} from "@bleu/shared/depistages"
import {
  type QuestionnaireShape,
  type ProfileQuestionnaireShape,
  type ProfileData,
  AllQuestionnairesAnswers,
  QuestionnaireName,
  QuestionTypes,
} from "@bleu/shared/questionnaires"

import { verticalsTheme } from "@bleu/front/assets/theme/verticalsTheme"
import { InfoBox } from "@bleu/front/components/InfoBox"
import { DateQuestion } from "@bleu/front/components/forms/DateQuestion"
import { FreeTextQuestion } from "@bleu/front/components/forms/FreeTextQuestion"
import { MultiSelectQuestion } from "@bleu/front/components/forms/MultiSelectQuestion"
import { NumberQuestion } from "@bleu/front/components/forms/NumberQuestion"
import {
  SingleSelectOption,
  SingleSelectQuestion,
} from "@bleu/front/components/forms/SingleSelectQuestion"
import QuestionnaireLayout from "@bleu/front/components/layout/QuestionnaireLayout"
import { VSpacer } from "@bleu/front/components/layout/Spacer"
import { HighlightText } from "@bleu/front/components/layout/components/LayoutTitle"
import type { StepProps } from "@bleu/front/pages/types"

interface Props {
  question:
    | QuestionnaireShape["questions"][number]
    | ProfileQuestionnaireShape["questions"][number]
  questionnaireName: QuestionnaireName | "profile"
  form: UseFormReturnType<AllQuestionnairesAnswers>
  profile: ProfileData
}
export const Question = ({
  question,
  questionnaireName,
  form,
  profile,
}: Props) => {
  const pathToQuestion = `${questionnaireName}.${question.id}`

  const uniqueValues = useMemo(
    () =>
      "options" in question
        ? question.options
            .filter((option) => option.unique)
            .map((option) => option.value)
        : undefined,
    [question],
  )

  switch (question.type) {
    case QuestionTypes.SingleSelect: {
      return (
        <SingleSelectQuestion
          title={question.title}
          subtitle={question.subtitle}
          options={question.options as SingleSelectOption[]}
          key={pathToQuestion}
          {...form.getInputProps(pathToQuestion)}
        />
      )
    }
    case QuestionTypes.MultiSelect: {
      const options = question.options.filter(
        (option) =>
          option.shouldShow === undefined ||
          option.shouldShow(
            // @ts-expect-error too much generics. I'm lost TODO
            form.values[questionnaireName],
            profile,
          ),
      )
      return (
        <MultiSelectQuestion
          title={question.title}
          subtitle={question.subtitle}
          options={options}
          key={pathToQuestion}
          values={form.getInputProps(pathToQuestion).value}
          onChange={(value, isUnique) => {
            // @ts-expect-error too much generics. I'm lost
            const values = form.values[questionnaireName][
              question.id
            ] as string[]

            if (values.includes(value)) {
              _.pull(values, value)
            } else if (
              // toggle unique values
              isUnique ||
              (values.length === 1 && uniqueValues?.includes(values[0]))
            ) {
              form.setFieldValue(pathToQuestion, [value])
              return
            } else {
              values.push(value)
            }

            form.setFieldValue(pathToQuestion, [...values])
          }}
        />
      )
    }
    case QuestionTypes.FreeText: {
      return (
        <>
          <FreeTextQuestion
            title={question.title}
            key={pathToQuestion}
            {...form.getInputProps(pathToQuestion)}
          />
        </>
      )
    }
    case QuestionTypes.Number: {
      return (
        <NumberQuestion
          key={pathToQuestion}
          title={question.title}
          unit={question.unit}
          {...form.getInputProps(pathToQuestion)}
        />
      )
    }
    case QuestionTypes.Date: {
      return (
        <DateQuestion
          key={pathToQuestion}
          title={question.title}
          {...form.getInputProps(pathToQuestion)}
        />
      )
    }
  }
}

export interface QuestionnaireProps extends Omit<StepProps, "onNext"> {
  questionnaire: QuestionnaireShape
  onEmptyQuestionnaire: () => void
  form: UseFormReturnType<{ profile?: ProfileData } & AllQuestionnairesAnswers>
  profile: ProfileData
  title?: HighlightText
  progress: number
}

const defaultQuestionnaireTitle: HighlightText = {
  text: "Questions relatives à votre dépistage",
  highlight: "Questions relatives",
}

export function Questionnaire({
  questionnaire,
  profile,
  form,
  title = defaultQuestionnaireTitle,
  onBack,
  onEmptyQuestionnaire,
  progress,
}: QuestionnaireProps) {
  const questions = useMemo(() => {
    const filteredQuestions = questionnaire.questions.filter((question) =>
      question.shouldShow(
        // @ts-expect-error too much generics. I'm lost TODO
        form.values[questionnaire.name as QuestionnaireName],
        profile,
      ),
    )
    return filteredQuestions
  }, [form.values, profile, questionnaire.name, questionnaire.questions])

  useEffect(() => {
    if (questions.length === 0) {
      onEmptyQuestionnaire()
    }
  }, [onEmptyQuestionnaire, questions.length])

  const verticalName = getVerticalNameForDepistageName(questionnaire.name)
  const depistageLongName = DepistagesLongNames[questionnaire.name]
  const verticalTheme = verticalsTheme[verticalName]

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [questionnaire])

  return (
    <>
      <QuestionnaireLayout.Header
        pageName={depistageLongName}
        onBack={onBack}
        progress={progress}
      />
      <QuestionnaireLayout.Title title={title} />
      <VSpacer size={10} />
      <HStack gap={4}>
        <Icon as={verticalTheme.icon} color={verticalTheme.color} boxSize={5} />
        <Heading size="xl">{depistageLongName}</Heading>
      </HStack>
      <VSpacer size={8} />
      <VStack gap={8} alignItems="stretch">
        {questions.map((question) => (
          <>
            <Divider borderColor="gray.300" />
            <Question
              key={question.id}
              question={question}
              questionnaireName={questionnaire.name}
              form={form}
              profile={profile}
            />
            {question.infoBox && (
              <>
                <InfoBox
                  title={question.infoBox.title}
                  message={question.infoBox.message}
                />
              </>
            )}
          </>
        ))}
      </VStack>
    </>
  )
}
