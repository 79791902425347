import { Box } from "@chakra-ui/react"

export const RedDot = () => (
  <Box
    bg="red.400"
    width={2.5}
    h={2.5}
    borderRadius={10}
    top={-0.5}
    right={-0.5}
    position="absolute"
  />
)
