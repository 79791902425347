import { inputAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
  field: {
    bg: "white",
    background: "white",
  },
})

export const Input = defineMultiStyleConfig({
  baseStyle,
  variants: {
    outline: {
      field: {
        border: "1px solid",
        borderColor: "blue.300",
        _hover: {
          borderColor: "blue.400",
        },
      },
    },
  },
})
