import {
  Flex,
  Center,
  Heading,
  type FlexProps,
  VStack,
  Text,
} from "@chakra-ui/react"

import { SelectBox } from "@bleu/front/components/forms/SelectBox"
import type {
  QuestionProps,
  SelectOption,
} from "@bleu/front/components/forms/types"

export type SingleSelectOption = SelectOption<string | boolean>

export interface SingleSelectQuestionProps
  extends QuestionProps<string | boolean> {
  options: SingleSelectOption[]
}
export function SingleSelectQuestion({
  title,
  subtitle,
  options,
  value,
  onChange,
}: SingleSelectQuestionProps) {
  const propsFor2Items: FlexProps = {
    justifyContent: "center",
    alignItems: "stretch",
    flexDirection: "row",
  }
  const propsForMoreThan2Items: FlexProps = { flexDirection: "column" }

  return (
    <Flex gap={6} direction="column">
      <Center>
        {title && (
          <VStack gap={2}>
            <Heading size="lg" textAlign="center">
              {title}
            </Heading>
            {subtitle && (
              <Text textAlign="center" fontSize="sm">
                {subtitle}
              </Text>
            )}
          </VStack>
        )}
      </Center>
      <Flex
        gap={5}
        alignItems="center"
        {...(options.length === 2 ? propsFor2Items : propsForMoreThan2Items)}
      >
        {options.map((option) => (
          <SelectBox
            variant={value === option.value ? "select" : "default"}
            key={option.label}
            onClick={() => onChange(option.value)}
          >
            {option.label}
          </SelectBox>
        ))}
      </Flex>
    </Flex>
  )
}
