import {
  Box,
  BoxProps,
  Container,
  Heading,
  HeadingProps,
  Stack,
} from "@chakra-ui/react"
import { PropsWithChildren } from "react"
import { Outlet } from "react-router-dom"

import { Header } from "@bleu/front/components/layout/Header"

// TODO: Looks like 3Xl heading are the main ones. Let's implement that in the design system
const DoctorLayoutTitle = ({ children, ...props }: HeadingProps) => (
  <Heading {...props} size="3xl">
    {children}
  </Heading>
)

const DoctorLayoutBody = ({ children }: PropsWithChildren<BoxProps>) => (
  <Box w="100%" h="100%" bgColor="white" borderRadius={8} px={5} py={6}>
    {children}
  </Box>
)

export const DoctorLayout = () => (
  <Box bg="blue.200">
    <Stack
      w="100vw"
      minH="100vh"
      bgRepeat="no-repeat"
      bgImage="/img/dots-background.png"
      bgSize="cover"
    >
      <Header />
      <Container maxW="1440px" h="100%" mb={5}>
        <Outlet />
      </Container>
    </Stack>
  </Box>
)

DoctorLayout.Title = DoctorLayoutTitle
DoctorLayout.Body = DoctorLayoutBody
