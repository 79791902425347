import { useParams } from "react-router-dom"

import { DoctorNotes } from "@bleu/front/components/doctor/DoctorNotes"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { usePatientMedicalRecordsQuery } from "@bleu/front/queries/patients"

export const MedicalRecordNotesPage = () => {
  const { patientId, recordId } = useParams()
  const medicalRecordQuery = usePatientMedicalRecordsQuery(
    patientId as string,
    recordId as string,
  )

  return (
    <QueryWrapper query={medicalRecordQuery}>
      {({ data }) => (
        <DoctorNotes medicalRecordId={data.id} notes={data.notes} />
      )}
    </QueryWrapper>
  )
}
