import { Button } from "@chakra-ui/react"

import { Conversation } from "@bleu/types/prisma-client"

import { ConversationChat } from "@bleu/front/components/ConversationChat"
import { ConversationProvider } from "@bleu/front/components/seald/ConversationProvider"
import { SealdProvider } from "@bleu/front/components/seald/SealdProvider"
import { useCreateConversationMutation } from "@bleu/front/queries/seald"

type Props = {
  medicalRecordId: string
  notes: Conversation | null
}

export const DoctorNotes = ({ medicalRecordId, notes }: Props) => {
  const { mutateAsync: createConversation, isPending: isCreatingConversation } =
    useCreateConversationMutation(medicalRecordId, "notes")

  if (!notes) {
    return (
      <SealdProvider>
        <Button
          onClick={() => createConversation()}
          isLoading={isCreatingConversation}
        >
          Ouvrir une session chiffrée
        </Button>
      </SealdProvider>
    )
  }

  return (
    <ConversationProvider conversationId={notes.id}>
      {({ conversation, sealdSession }) => (
        <ConversationChat
          conversation={conversation}
          sealdSession={sealdSession}
        />
      )}
    </ConversationProvider>
  )
}
