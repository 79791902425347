import { extendTheme, withDefaultProps } from "@chakra-ui/react"
import "@fontsource/manrope"

import Heading from "@bleu/front/assets/theme/components/Heading"
import { Input } from "@bleu/front/assets/theme/components/Input"
import { Link } from "@bleu/front/assets/theme/components/Link"
import { Table } from "@bleu/front/assets/theme/components/Table"
import { Tabs } from "@bleu/front/assets/theme/components/Tabs"
import { Tag } from "@bleu/front/assets/theme/components/Tag"
import Text from "@bleu/front/assets/theme/components/Text"

import { colors } from "./colors"
import { Button } from "./components/Button"

export const FONT_FAMILY = "'Manrope', system-ui, sans-serif"

export const theme = extendTheme(
  {
    colors,
    primary: colors.blue[400],
    fonts: {
      body: FONT_FAMILY,
      heading: FONT_FAMILY,
    },
    components: {
      Button,
      Heading,
      Text,
      Link,
      Input,
      Table,
      Tabs,
      Tag,
    },
  },
  withDefaultProps({
    defaultProps: {
      gap: 0,
      align: "stretch",
    },
    components: ["VStack", "HStack", "Stack"],
  }),
  withDefaultProps({
    defaultProps: {
      borderColor: colors.gray[200],
    },
    components: ["Divider"],
  }),
  withDefaultProps({
    defaultProps: { bg: "white" },
    components: ["Input"],
  }),
)
