import { useParams } from "react-router-dom"

import { PatientDocuments } from "@bleu/front/components/doctor/PatientDocuments"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { usePatientMedicalRecordsQuery } from "@bleu/front/queries/patients"

export const MedicalRecordDocumentsPage = () => {
  const { patientId, recordId } = useParams()
  const medicalRecordQuery = usePatientMedicalRecordsQuery(
    patientId as string,
    recordId as string,
  )

  return (
    <QueryWrapper query={medicalRecordQuery}>
      {({ data }) => <PatientDocuments documents={data.documents} />}
    </QueryWrapper>
  )
}
