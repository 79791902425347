import {
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useBreakpoint,
  VStack,
  Icon,
  HStack,
  Button,
  Box,
} from "@chakra-ui/react"
import dayjs from "dayjs"
import _ from "lodash"
import { useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"

import { MedicalRecord } from "@bleu/types/endpoints/account"
import { MedicalRecordStatus } from "@bleu/types/prisma-client"

import { getVerticalNameForDepistageName } from "@bleu/shared/depistages"
import { VerticalsLongNames, VerticalName } from "@bleu/shared/verticals"

import { colors } from "@bleu/front/assets/theme/colors"
import { verticalsTheme } from "@bleu/front/assets/theme/verticalsTheme"
import { ChatButton } from "@bleu/front/components/patientSpace/ChatButton"
import {
  StatusBadgeStatuses,
  StatusBadge,
  type StatusBadgeStatus,
} from "@bleu/front/components/patientSpace/StatusBadge"

const recordStatusToBadgeStatus: Record<
  MedicalRecordStatus,
  StatusBadgeStatus
> = {
  PENDING_PRESCRIPTION: StatusBadgeStatuses.verification,
  PENDING_PAYMENT: StatusBadgeStatuses.verification,
  CLOSED: StatusBadgeStatuses.archived,
  PENDING_LAB_RESULTS: StatusBadgeStatuses.analyze,
  PENDING_LAB_RESULTS_ANALYSIS: StatusBadgeStatuses.analyze,
  LAB_RESULTS_ANALYZED: StatusBadgeStatuses.analyzed,
}

interface Props {
  records: MedicalRecord[]
}

export const RecordsHistory = ({ records }: Props) => {
  const breakpoint = useBreakpoint()

  const sortedRecords = useMemo(
    () =>
      records.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
      ),
    [records],
  )
  const onGoingRecords = useMemo(
    () => sortedRecords.filter((record) => record.status !== "CLOSED"),
    [sortedRecords],
  )
  const archivedRecords = useMemo(
    () => sortedRecords.filter((record) => record.status === "CLOSED"),
    [sortedRecords],
  )

  return (
    <Tabs isFitted={breakpoint === "base"}>
      <TabList display={{ base: "flex", md: "inline-flex" }} marginBottom={4}>
        <Tab>En cours</Tab>
        <Tab>Archivées</Tab>
      </TabList>
      <TabPanels width="100%">
        <TabPanel padding={0}>
          <RecordsList
            records={onGoingRecords}
            emptyListMessage="Vous n'avez pas de dossier en cours"
          />
        </TabPanel>
        <TabPanel padding={0}>
          <RecordsList
            records={archivedRecords}
            emptyListMessage="Vous n'avez aucune ordonnance archivée"
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

/**
 * RECORDSLIST
 */

const RecordsListItemMobile = ({ record }: { record: MedicalRecord }) => {
  const {
    status: medicalRecordStatus,
    depistageNames,
    date,
    id: recordId,
    code,
  } = record
  const navigate = useNavigate()

  const verticalNames = _.uniq(
    depistageNames.map(getVerticalNameForDepistageName),
  )

  const verticalName =
    verticalNames.length > 1
      ? VerticalName.GENERAL
      : getVerticalNameForDepistageName(depistageNames[0])

  const badgeStatus = recordStatusToBadgeStatus[medicalRecordStatus]
  const { icon, color } = verticalsTheme[verticalName]
  const handleClick = () => {
    navigate(`/prescriptions/${recordId}`)
  }

  return (
    <Button
      onClick={handleClick}
      position="relative"
      flexDir="column"
      alignItems="stretch"
      justifyContent="space-between"
      h={161}
      padding={3}
      borderWidth={1}
      borderColor="blue.200"
      borderRadius={8}
      boxShadow="sm"
      bg="white"
      overflow="hidden"
      _hover={{ bg: "white" }}
    >
      <ChatButton
        hasNewMessage={record.hasNewMesssage}
        href={`/prescriptions/${recordId}/conversation`}
        position="absolute"
        top={2}
        right={2}
      />
      <VStack padding={2.5} alignItems="stretch">
        <VStack alignItems="stretch" gap={4}>
          <HStack alignItems="flex-start">
            <StatusBadge status={badgeStatus} />
          </HStack>
          <HStack gap={3} alignItems="center">
            <Icon as={icon} boxSize={5} color={color} />
            <Text
              size="lg"
              fontWeight={600}
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {VerticalsLongNames[verticalName]}
            </Text>
          </HStack>
        </VStack>
        <HStack justifyContent="space-between">
          <Text size="sm" color={colors.navy[300]}>
            {dayjs(date).format("DD/MM/YYYY")}
          </Text>
          <Text size="sm" color={colors.navy[300]}>
            N°{code}
          </Text>
        </HStack>
      </VStack>
    </Button>
  )
}

const RecordsListItemDesktop = ({ record }: { record: MedicalRecord }) => {
  const {
    status: medicalRecordStatus,
    depistageNames,
    date,
    id: recordId,
    code,
  } = record

  const verticalNames = _.uniq(
    depistageNames.map(getVerticalNameForDepistageName),
  )

  const verticalName =
    verticalNames.length > 1
      ? VerticalName.GENERAL
      : getVerticalNameForDepistageName(depistageNames[0])

  const badgeStatus = recordStatusToBadgeStatus[medicalRecordStatus]
  return (
    <Button
      as={Link}
      to={`/prescriptions/${recordId}`}
      _hover={{ bg: "gray.100" }}
      h={70}
      paddingX={4}
      borderWidth={1}
      borderColor="blue.200"
      borderRadius={8}
      boxShadow="sm"
      alignItems="stretch"
      justifyContent="space-between"
      bg="white"
    >
      <HStack gap={4}>
        <Icon
          as={verticalsTheme[verticalName].icon}
          boxSize={5}
          color={verticalsTheme[verticalName].color}
        />
        <Text size="lg" fontWeight={600} textOverflow="ellipsis" noOfLines={1}>
          {VerticalsLongNames[verticalName]}
        </Text>
      </HStack>
      <HStack gap={4} flex="1" justifyContent="flex-end" alignItems="center">
        <Text size="sm" color={colors.navy[300]}>
          {dayjs(date).format("DD/MM/YYYY")}
        </Text>
        <Box minW="100px">
          <Text
            size="sm"
            color={colors.navy[300]}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            N°{code}
          </Text>
        </Box>
        <StatusBadge status={badgeStatus} />
        <ChatButton
          hasNewMessage={record.hasNewMesssage}
          href={`/prescriptions/${recordId}`}
        />
      </HStack>
    </Button>
  )
}

interface RecordsListProps {
  records: MedicalRecord[]
  emptyListMessage: string
}

const RecordsList = ({ records, emptyListMessage }: RecordsListProps) => {
  const breakpoint = useBreakpoint()

  if (records.length === 0) {
    return (
      <VStack padding={4} alignItems="center">
        <Text color={colors.navy[300]}>{emptyListMessage}</Text>
      </VStack>
    )
  }

  return (
    <VStack gap={3} alignItems="stretch">
      {records.map((record) =>
        breakpoint === "base" ? (
          <RecordsListItemMobile key={record.id} record={record} />
        ) : (
          <RecordsListItemDesktop key={record.id} record={record} />
        ),
      )}
    </VStack>
  )
}

export default RecordsHistory
