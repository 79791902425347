import { z } from "zod"

import type { FormValues } from "@bleu/types/form"

import { B9SupplementsCurrentSituationOption } from "@bleu/shared/questionnaires"
import { shouldShow } from "@bleu/shared/utils/shouldShow"

import { QuestionTypes, QuestionnairesNames } from "./enums"
import type { BaseQuestionnaireShape } from "./types"

export const b9SupplementsSchema = z.object({
  currentSituation: z
    .nativeEnum(B9SupplementsCurrentSituationOption)
    .array()
    .min(1),
})

const initialValues: B9SupplementsFormValues = {
  currentSituation: new Array<B9SupplementsCurrentSituationOption>(),
}

const QuestionNames = {
  currentSituation: "currentSituation",
} as const

type QuestionName = keyof typeof QuestionNames

export type B9SupplementsData = z.infer<typeof b9SupplementsSchema>
type B9SupplementsFormValues = FormValues<B9SupplementsData>

export type B9SupplementsQuestionnaireShape = BaseQuestionnaireShape<
  QuestionName,
  B9SupplementsData,
  B9SupplementsFormValues
>
export const b9SupplementsQuestionnaire: B9SupplementsQuestionnaireShape = {
  name: QuestionnairesNames.b9Supplements,
  questions: [
    {
      id: QuestionNames.currentSituation,
      shouldShow,
      type: QuestionTypes.MultiSelect,
      title: "Sélectionnez la ou les situation qui vous correspondent",
      //         description: `➤
      // ➤ `,
      options: [
        {
          label:
            "Antécédent familial ou personnel de spina bifida ou d'autre malformation du tube neural",
          value: B9SupplementsCurrentSituationOption.SPINA_BIFIDA,
        },
        {
          label:
            "Epilepsie traitée par valproate de sodium (Dépakine et génériques)",
          value: B9SupplementsCurrentSituationOption.SODIUM_VALPROATE,
        },
        {
          label: "Je ne sais pas",
          value: B9SupplementsCurrentSituationOption.DOESNT_KNOW,
        },
        {
          label: "Non, aucune des situations ci-dessus",
          value: B9SupplementsCurrentSituationOption.NONE,
          unique: true,
        },
      ],
    },
  ],
  schema: b9SupplementsSchema,
  initialValues,
} as const
