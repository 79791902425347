import { createIcon } from "@chakra-ui/react"

export const IconVerticalesOnco = createIcon({
  displayName: "IconVerticalesOnco",
  viewBox: "0 0 32 32",
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M26.938 20.226c-3.173-3.003-6.777-3.946-9.008-4.243 2.22-.332 5.755-1.317 8.885-4.275C31.703 7.089 31.948 1.113 31.975 0c-1.04.012-7.061.177-11.658 5.16-3.845 4.166-4.246 9.048-4.288 10.686h-.012c-.078-1.668-.578-6.462-4.4-10.568C7.037.352 1.105.103 0 .076c.012 1.05.176 7.116 5.12 11.75 3.157 2.959 6.727 3.894 8.962 4.19-2.225.329-5.726 1.304-8.84 4.22C.331 24.829.055 30.804.023 31.918c1.039-.006 7.06-.143 11.683-5.102C15.572 22.668 16 17.79 16.05 16.152h.012c.07 1.668.546 6.466 4.348 10.59C24.968 31.69 30.897 31.968 32 32c-.006-1.047-.142-7.115-5.062-11.774Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  ),
})
