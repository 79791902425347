import { ColorHues } from "@chakra-ui/react"

export const colors = {
  white: "#FFFFFF",
  black: "#19154E",
  blue: {
    50: "#F9FCFF",
    200: "#E6F4FD",
    300: "#B3DDFA",
    400: "#008DEF",
    500: "#008DEF",
  },
  navy: {
    300: "#6A678C",
    400: "#19154E",
  },
  purple: {
    200: "#F4E6FA",
    300: "#D8A5EE",
    400: "#B14CDC",
  },
  pink: {
    200: "#FFE5F5",
    300: "#FEA2DB",
    400: "#FC46B7",
  },
  indigo: {
    200: "#EAE7FF",
    300: "#B5A8FF",
    400: "#6B52FE",
  },
  green: {
    200: "#E6F5E5",
    400: "#4EB845",
    500: "#4EB845",
    600: "#3E9337",
  },
  yellow: {
    200: "#FDF8E6",
    400: "#F1CE50",
  },
  orange: {
    200: "#FDECE6",
    400: "#F17750",
  },
  red: {
    200: "#FDE6E8",
    400: "#F1505A",
    500: "#F1505A",
    600: "#C14048",
  },
  gray: {
    50: "#F9FCFF",
    100: "#F4F8FB",
    200: "#E7EEF1",
    300: "#D5DAE2",
    400: "#A9B4BC",
    500: "#6E7D86",
    600: "#44545F",
    700: "#304551",
    800: "#1A202C",
  },
} as const

export type Color = keyof typeof colors
export type ColorHue = `${Color}.${keyof ColorHues}`
