import * as Sentry from "@sentry/react"

interface LoggerOptions {
  level: "debug" | "info" | "warn" | "error"
  message: string
  context?: Record<string, unknown>
}

const isDevelopment = import.meta.env.VITE_ENVIRONMENT === "development"
const isScalingo = !!import.meta.env.VITE_WORKSPACE

const shouldEnableExternalLogging = !isDevelopment || isScalingo

const consoleLog = ({ level, message, context }: LoggerOptions) => {
  console[level](message, context)
}

const sentryLog = ({ level, message, context }: LoggerOptions) => {
  Sentry.captureMessage(message, {
    level: level === "warn" ? "warning" : level,
    extra: context,
  })
}

const betterStackLog = async ({ level, message, context }: LoggerOptions) => {
  try {
    await fetch("https://in.logs.betterstack.com", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${import.meta.env.VITE_BETTER_STACK_LOGS_TOKEN}`,
      },
      body: JSON.stringify({
        dt: new Date().toISOString(),
        level,
        message,
        context: {
          ...context,
          environment: import.meta.env.VITE_ENVIRONMENT,
        },
      }),
    })
  } catch (error) {
    console.error("Failed to send log to BetterStack:", error)
  }
}

export const logger = {
  debug: (message: string, context?: Record<string, unknown>) => {
    const logOptions = { level: "debug" as const, message, context }
    consoleLog(logOptions)
    if (shouldEnableExternalLogging) {
      sentryLog(logOptions)
      betterStackLog(logOptions)
    }
  },
  info: (message: string, context?: Record<string, unknown>) => {
    const logOptions = { level: "info" as const, message, context }
    consoleLog(logOptions)
    if (shouldEnableExternalLogging) {
      sentryLog(logOptions)
      betterStackLog(logOptions)
    }
  },
  warn: (message: string, context?: Record<string, unknown>) => {
    const logOptions = { level: "warn" as const, message, context }
    consoleLog(logOptions)
    if (shouldEnableExternalLogging) {
      sentryLog(logOptions)
      betterStackLog(logOptions)
    }
  },
  error: (message: string, context?: Record<string, unknown>) => {
    const logOptions = { level: "error" as const, message, context }
    consoleLog(logOptions)
    if (shouldEnableExternalLogging) {
      sentryLog(logOptions)
      betterStackLog(logOptions)
    }
  },
}
