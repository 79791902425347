import { Outlet } from "react-router-dom"

import { DoctorContext } from "@bleu/front/components/auth/DoctorContext"
import { QueryWrapper } from "@bleu/front/components/layout/QueryWrapper"
import { useDoctorInfoQuery } from "@bleu/front/queries/doctor"

export const DoctorGuard = () => {
  const doctorInfoQuery = useDoctorInfoQuery()

  return (
    <QueryWrapper query={doctorInfoQuery}>
      {({ data }) => (
        <DoctorContext.Provider value={{ doctorInfo: data }}>
          <Outlet />
        </DoctorContext.Provider>
      )}
    </QueryWrapper>
  )
}
