import { z } from "zod"

import type { FormValues } from "@bleu/types/form"

import {
  CervicalCancerCheckTypeOption,
  CervicalCancerCurrentSituationOption,
  CervicalCancerLastCheckOption,
} from "@bleu/shared/questionnaires"
import { shouldShow } from "@bleu/shared/utils/shouldShow"

import { QuestionTypes, QuestionnairesNames } from "./enums"
import { type BaseQuestionnaireShape } from "./types"

export const cervicalCancerSchema = z
  .object({
    lastCheck: z.nativeEnum(CervicalCancerLastCheckOption),
    isFirstCheck: z.boolean().optional().nullable(),
    checkType: z
      .nativeEnum(CervicalCancerCheckTypeOption)
      .optional()
      .nullable(),
    currentSituation: z
      .array(z.nativeEnum(CervicalCancerCurrentSituationOption))
      .min(1),
  })
  .refine((data) => {
    if (data.lastCheck !== CervicalCancerLastCheckOption.NEVER) {
      return data.isFirstCheck !== undefined && data.checkType !== undefined
    }
    data.isFirstCheck = undefined
    data.checkType = undefined
    return true
  })

const QuestionNames = {
  lastCheck: "lastCheck",
  isFirstCheck: "isFirstCheck",
  checkType: "checkType",
  currentSituation: "currentSituation",
} as const

type QuestionName = keyof typeof QuestionNames

const initialValues: CervicalCancerFormValues = {
  lastCheck: undefined,
  isFirstCheck: undefined,
  checkType: undefined,
  currentSituation: new Array<CervicalCancerCurrentSituationOption>(),
}

export type CervicalCancerData = z.infer<typeof cervicalCancerSchema>
type CervicalCancerFormValues = FormValues<CervicalCancerData>

export type CervicalCancerQuestionnaireShape = BaseQuestionnaireShape<
  QuestionName,
  CervicalCancerData,
  CervicalCancerFormValues
>

export const cervicalCancerQuestionnaire: CervicalCancerQuestionnaireShape = {
  name: QuestionnairesNames.cervicalCancer,
  questions: [
    {
      id: QuestionNames.lastCheck,
      type: QuestionTypes.SingleSelect,
      shouldShow,
      title: "De quand date votre dernier dépistage ?",
      options: [
        {
          label: "Moins d'un an",
          value: CervicalCancerLastCheckOption.LESS_THAN_A_YEAR,
        },
        {
          label: "Entre 1 et 3 ans",
          value: CervicalCancerLastCheckOption.BETWEEN_1_AND_3_YEARS,
        },
        {
          label: "Entre 3 et 5 ans",
          value: CervicalCancerLastCheckOption.BETWEEN_3_AND_5_YEARS,
        },
        {
          label: "Plus de 5 ans",
          value: CervicalCancerLastCheckOption.MORE_THAN_5_YEARS,
        },
        {
          label: "Je n'ai jamais fait ce dépistage",
          value: CervicalCancerLastCheckOption.NEVER,
        },
      ],
    },
    {
      id: QuestionNames.isFirstCheck,
      type: QuestionTypes.SingleSelect,
      shouldShow: ({ lastCheck }) =>
        !!lastCheck && lastCheck !== CervicalCancerLastCheckOption.NEVER,
      title: "Etait-ce votre premier dépistage ?",
      options: [
        { label: "Oui", value: true },
        { label: "Non", value: false },
      ],
    },
    {
      id: QuestionNames.checkType,
      type: QuestionTypes.SingleSelect,
      shouldShow: ({ lastCheck }) =>
        !!lastCheck && lastCheck !== CervicalCancerLastCheckOption.NEVER,
      title: "Quelle était la nature de votre dernier dépistage ?",
      options: [
        {
          label: "Examen cytologique",
          value: CervicalCancerCheckTypeOption.CYTOLOGY,
        },
        {
          label: "Recherche d'HPV-HR",
          value: CervicalCancerCheckTypeOption.HPV,
        },
      ],
    },
    {
      id: QuestionNames.currentSituation,
      type: QuestionTypes.MultiSelect,
      title: "Etes-vous dans une ou plusieurs des situations suivantes ?",
      shouldShow,
      options: [
        {
          label:
            "Signes fonctionnels (notamment saignements inhabituels c’est à dire en dehors des règles, après un rapport sexuel ou après la ménopause et/ou pertes vaginales persistantes en dépit d’un traitement syndromique des IST)",
          value: CervicalCancerCurrentSituationOption.FUNCTIONAL_SIGNS,
        },
        {
          label:
            "Signes cliniques (plus difficiles à apprécier en dehors d’un examen gynécologique par un professionnel de santé exercé, mais pouvant par exemple s’apparenter à un changement d’aspect, taille, consistance…)",
          value: CervicalCancerCurrentSituationOption.CLINICAL_SIGNS,
        },
        {
          label:
            "Antécédent de lésion précancéreuse ou cancéreuse du col de l’utérus",
          value: CervicalCancerCurrentSituationOption.LESION_ANTECEDENTS,
        },
        {
          label: "Hystérectomie (ablation de l'utérus)",
          value: CervicalCancerCurrentSituationOption.HYSTERECTOMY,
        },
        {
          label: "Enceinte de plus de 14 semaines",
          value: CervicalCancerCurrentSituationOption.PREGNANCY,
        },
        {
          label: "Non, aucune des situations ci-dessus",
          value: CervicalCancerCurrentSituationOption.NONE,
          unique: true,
        },
      ],
    },
  ],
  schema: cervicalCancerSchema,
  initialValues,
} as const
