import {
  Skeleton,
  HStack,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react"
import { useClerk } from "@clerk/clerk-react"
import * as Sentry from "@sentry/react"
import {
  IconBrandGoogleHome,
  IconUser,
  IconHeart,
  IconStethoscope,
  IconLogout,
  IconMenu2,
  IconMailForward,
} from "@tabler/icons-react"
import { Link } from "react-router-dom"

import { UserRole } from "@bleu/types/user"

import { checkUserAuthorization } from "@bleu/shared/utils/auth"

import { colors } from "@bleu/front/assets/theme/colors"
import { initiateSealdClient, sealdClient } from "@bleu/front/lib/seald"
import { useAuthState } from "@bleu/front/queries/auth"

export const DesktopUserMenu = () => {
  const { signOut } = useClerk()
  const { user, isSignedIn, isLoading } = useAuthState()

  const handleLogout = async () => {
    if (!sealdClient && user?.sealdDatabaseKey) {
      await initiateSealdClient(user.sealdDatabaseKey)
    }
    if (sealdClient) {
      await sealdClient.dropDatabase()
    } else {
      Sentry.captureException(new Error("Seald client not found"))
    }
    await signOut()
  }

  if (isLoading)
    return (
      <Skeleton w={150} height={10} startColor="blue.100" endColor="blue.200" />
    )
  if (!isSignedIn) {
    return (
      <HStack>
        <Button variant="outline" size="sm" as={Link} to="/sign-in">
          Se connecter
        </Button>
        <Button size="sm" as={Link} to="/sign-up">
          S&apos;inscrire
        </Button>
      </HStack>
    )
  }
  return (
    <HStack>
      {user && checkUserAuthorization(user, UserRole.DOCTOR, {}) && (
        <IconButton
          css={{ padding: "8px !important" }}
          w={12}
          h={12}
          as={Link}
          to="/doctor"
          borderRadius={8}
          icon={<IconBrandGoogleHome size={24} color={colors.blue[400]} />}
          bg="blue.200"
          aria-label="Espace médecin"
        />
      )}
      <Menu>
        <MenuButton>
          <IconButton
            css={{ padding: "8px !important" }}
            w={12}
            h={12}
            borderRadius={8}
            icon={<IconMenu2 size={24} color={colors.blue[400]} />}
            name={user.fullName}
            bg="blue.200"
            aria-label="Menu"
          />
        </MenuButton>
        <MenuList>
          <MenuItem
            fontSize="sm"
            icon={<IconHeart size={20} />}
            as={Link}
            to="/"
          >
            Mon espace patient
          </MenuItem>
          <MenuItem
            fontSize="sm"
            icon={<IconUser size={20} />}
            as={Link}
            to="/account/preferences"
          >
            Mon compte
          </MenuItem>
          {[UserRole.DOCTOR, UserRole.ADMIN].includes(user.role) && (
            <MenuItem
              fontSize="sm"
              icon={<IconStethoscope size={20} />}
              as={Link}
              to="/doctor"
            >
              Espace docteur
            </MenuItem>
          )}
          {user.role === UserRole.ADMIN && (
            <MenuItem
              fontSize="sm"
              icon={<IconMailForward size={20} />}
              as={Link}
              to="/doctor/email-extraction"
            >
              Extraction d‘email
            </MenuItem>
          )}
          <MenuItem
            color="red.500"
            fontSize="sm"
            icon={<IconLogout size={20} />}
            onClick={handleLogout}
          >
            Se déconnecter
          </MenuItem>
        </MenuList>
      </Menu>
    </HStack>
  )
}
