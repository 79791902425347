import {
  Button,
  forwardRef,
  ButtonProps,
  Stack,
  StackProps,
  VStack,
} from "@chakra-ui/react"
import type { PropsWithChildren } from "react"

import { LayoutHeader } from "@bleu/front/components/layout/components/LayoutHeader"
import { LayoutTitle } from "@bleu/front/components/layout/components/LayoutTitle"

const QuestionnaireLayout = ({ children, ...props }: StackProps) => (
  <Stack gap={0} alignItems="center" h="100%" {...props}>
    {children}
  </Stack>
)

const Content = ({ children }: PropsWithChildren) => (
  <Stack
    gap={0}
    w={{ base: "100%", sm: "70%", lg: "50%" }}
    maxW={450}
    pb={[20, 0]}
  >
    {children}
  </Stack>
)

const CTA = forwardRef<ButtonProps, "button">(({ children, ...props }) => {
  return (
    <VStack
      align="stretch"
      justifyContent="center"
      position={{ base: "fixed", md: "unset" }}
      bottom={0}
      left={0}
      right={0}
      h={20}
      paddingX={4}
      marginTop={{ base: "unset", md: 10 }}
      boxShadow={{ base: "0 -4px 8px rgba(0, 0, 0, 0.1)", md: "unset" }}
      bg="white"
    >
      <Button {...props} size="lg">
        {children}
      </Button>
    </VStack>
  )
})

QuestionnaireLayout.Header = LayoutHeader
QuestionnaireLayout.Title = LayoutTitle
QuestionnaireLayout.Content = Content
QuestionnaireLayout.CTA = CTA

export default QuestionnaireLayout
