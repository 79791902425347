import { Text, Heading, Flex, VStack } from "@chakra-ui/react"

import { Blue } from "@bleu/shared/utils/Blue"

interface Step {
  index: number
  title: string
  description: string
}

const steps: Step[] = [
  {
    index: 1,
    title: "Sélectionnez un type de dépistage",
    description: "Obtenez votre ordonnance en quelques questions (3 à 6 min)",
  },
  {
    index: 2,
    title: "Réalisez vos examens",
    description:
      "Effectuez vos examens en laboratoire en présentant cette ordonnance délivrée par un médecin inscrit à l’Ordre des médecins",
  },
  {
    index: 3,
    title: "Bleu analyse vos résultats",
    description:
      "Sur votre espace patient, chargez vos résultats afin de recevoir une analyse personnalisée réalisée par les médecins de Bleu",
  },
  {
    index: 4,
    title: "L’équipe Bleu vous accompagne",
    description:
      "Si le résultat de dépistage se révèle positif (maladies, infections) un médecin de l’équipe Bleu vous contacte et vous propose une prise en charge",
  },
] as const

const StepCard = ({ index, title, description }: Step) => (
  <Flex
    flexDirection={["row", "column"]}
    bg="white"
    w={["unset", 246]}
    flexGrow={0}
    flexShrink={0}
    alignItems={["stretch", "flex-start"]}
    borderWidth={1}
    borderColor="blue.200"
    borderRadius={8}
    padding={6}
    gap={2}
    boxShadow="md"
  >
    <Heading
      size="3xl"
      justifyContent="flex-start"
      textAlign="center"
      marginRight={4}
      marginTop={1}
    >
      <Blue>{index}</Blue>
    </Heading>
    <VStack alignItems="flex-start">
      <Text size="lg" fontWeight={600}>
        {title}
      </Text>
      <Text fontSize="sm" color="navy.300">
        {description}
      </Text>
    </VStack>
  </Flex>
)

export const StepsCardsList = () => {
  return (
    <Flex
      flexDirection={["column", "row"]}
      gap={5}
      alignItems="stretch"
      flexWrap="wrap"
    >
      {steps.map((step) => (
        <StepCard key={step.title} {...step} />
      ))}
    </Flex>
  )
}
