import { Text, HStack, VStack } from "@chakra-ui/react"
import { IconCircleFilled } from "@tabler/icons-react"

import { colors } from "@bleu/front/assets/theme/colors"
import { VSpacer } from "@bleu/front/components/layout/Spacer"

const getScore2Sentence = (score2: number) => {
  if (score2 <= 5) {
    return "Risque CV faible à modéré"
  }
  if (score2 <= 10) {
    return "Risque CV élevé"
  }
  return "Risque CV très élevé"
}

// TODO : Manque la gestion de l'age par rapport au calcul total / risque.

const getScore2Labels = (score2: number) => [
  {
    color: colors.green[400],
    label: "< 5%",
    isCurrent: score2 <= 5,
  },
  {
    color: colors.yellow[400],
    label: "5 à 10%",
    isCurrent: score2 > 5 && score2 <= 10,
  },
  {
    color: colors.red[400],
    label: "> à 10%",
    isCurrent: score2 > 10,
  },
]

type Score2Props = {
  score2: number
}

export const Score2Card = ({ score2 }: Score2Props) => (
  <HStack
    borderRadius={8}
    borderWidth={1}
    borderColor="blue.300"
    bg="white"
    p={4}
    justify="space-between"
  >
    <VStack gap={0} align="start" flex={2}>
      <Text fontSize="2xl">Votre Score2</Text>
      <VSpacer size={2} />
      <Text size="sm">{getScore2Sentence(score2)}</Text>
      <VSpacer size={4} />
      <HStack justify="space-between" w="100%">
        {getScore2Labels(score2).map(({ color, label, isCurrent }) => (
          <HStack gap={0.5} key={color}>
            <IconCircleFilled
              size={16}
              color={`${color}${isCurrent ? "ff" : "a0"}`}
            />
            <Text size="sm" fontWeight={isCurrent ? 600 : 400}>
              {label}
            </Text>
          </HStack>
        ))}
      </HStack>
    </VStack>
    <VStack flex={1} minWidth="20%">
      <Text fontSize="5xl">{score2}</Text>
    </VStack>
  </HStack>
)
