import { HStack, Icon, Text } from "@chakra-ui/react"
import { IconCircleCheckFilled, IconClipboard } from "@tabler/icons-react"

type Props = {
  label: string
  isCompleted: boolean
  onClick: () => void
}

export const DoctorActionItem = ({ label, isCompleted, onClick }: Props) => (
  <HStack
    onClick={!isCompleted ? onClick : undefined}
    cursor={!isCompleted ? "pointer" : "not-allowed"}
  >
    <Icon as={IconClipboard} color="blue.400" />
    <Text
      size="sm"
      fontWeight="bold"
      color={!isCompleted ? "inherit" : "gray.500"}
    >
      {label}
    </Text>
    {isCompleted && (
      <Icon boxSize={5} as={IconCircleCheckFilled} color="green.400" />
    )}
  </HStack>
)
