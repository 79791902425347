import * as Sentry from "@sentry/react"
import _ from "lodash"
import { useEffect, useMemo, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { getVerticalNameForDepistageName } from "@bleu/shared/depistages"
import { ProfileData } from "@bleu/shared/questionnaires"
import { createRandomMedicalRecordCode } from "@bleu/shared/utils/random"
import {
  VerticalName,
  getDepistagesForVertical,
  isVerticalName,
} from "@bleu/shared/verticals"

import { DepistagesSelection } from "@bleu/front/components/funnelSteps/DepistagesSelection"
import { Payment } from "@bleu/front/components/funnelSteps/Payment"
import { PrescriptionRecap } from "@bleu/front/components/funnelSteps/PrescriptionsRecap"
import { ProfileQuestionnaire } from "@bleu/front/components/funnelSteps/ProfileQuestionnaire"
import { Questionnaires } from "@bleu/front/components/funnelSteps/Questionnaires"
import { Start } from "@bleu/front/components/funnelSteps/Start"
import { VerticalsSelection } from "@bleu/front/components/funnelSteps/VerticalsSelection"
import QuestionnaireLayout from "@bleu/front/components/layout/QuestionnaireLayout"
import { useAuthState } from "@bleu/front/queries/auth"
import { useCreateMedicalRecordMutation } from "@bleu/front/queries/medicalRecords"
import { amplitudeTrack, gtmTrack } from "@bleu/front/utils/tracking"

import {
  useFunnelStore,
  useProfileStore,
  useVerticalsStore,
} from "../lib/stores"

const Steps = {
  start: 0,
  verticalSelection: 1,
  profile: 2,
  depistagesSelection: 3,
  questionnaires: 4,
  recap: 5,
  payment: 6,
} as const

type Step = (typeof Steps)[keyof typeof Steps]

export const FunnelPage = () => {
  const { isSignedIn } = useAuthState()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const medicalRecordCode = useMemo(createRandomMedicalRecordCode, [])

  const verticalParam = useMemo(() => {
    const param = searchParams.get("vertical")
    return isVerticalName(param) ? param : undefined
  }, [searchParams])

  const stepParam = useMemo(() => {
    const param = searchParams.get("step")
    return param ? _.get(Steps, param) : undefined
  }, [searchParams])

  const { profile, setProfile, acceptsMarketing, setAcceptsMarketing } =
    useProfileStore()

  const [currentStep, setCurrentStep] = useState<Step>(stepParam ?? Steps.start)
  const [stepHistory, setStepHistory] = useState<Step[]>([])

  const {
    excludedDepistages,
    answers: allQuestionnairesAnswers,
    setAnswers: setAllQuestionnairesAnswers,
    prescribedDepistages,
    setPrescribedDepistages,
    reset: resetFunnelStore,
  } = useFunnelStore()

  const { selectedVerticals, setSelectedVerticals } = useVerticalsStore()

  useEffect(() => {
    if (verticalParam) {
      setSelectedVerticals([verticalParam])
    }
  }, [verticalParam, setSelectedVerticals])

  const onProfileSubmit = (
    profileData: ProfileData,
    acceptsMarketing: boolean,
  ) => {
    setProfile(profileData)
    setAcceptsMarketing(acceptsMarketing)
  }

  const selectedDepistages = useMemo(() => {
    if (currentStep < Steps.depistagesSelection || !profile) {
      return []
    }

    const depistages = selectedVerticals
      .flatMap(getDepistagesForVertical)
      .map((depistage) => ({
        ...depistage,
        vertical: getVerticalNameForDepistageName(depistage.name),
      }))
      .filter((depistage) => depistage.isRelevantToProfile(profile))
      .filter((depistage) => !excludedDepistages.includes(depistage.name))

    return _.orderBy(depistages, (depistage) => depistage.vertical)
  }, [currentStep, excludedDepistages, profile, selectedVerticals])

  const questionnairesToTake = useMemo(() => {
    return selectedDepistages
      .filter((depistage) => depistage.questionnaire !== undefined)
      .map((depistage) => depistage.questionnaire!)
  }, [selectedDepistages])

  useEffect(() => {
    if (currentStep === Steps.verticalSelection) {
      resetFunnelStore()
    }
  }, [currentStep, resetFunnelStore])

  const { mutateAsync: saveAnswers } = useCreateMedicalRecordMutation()
  const handleSaveAnswers = async (medicalRecordCode: string) => {
    if (_.isEmpty(allQuestionnairesAnswers)) {
      setCurrentStep(Steps.start)
      throw new Error("Trying to save without answers")
    }
    try {
      const medicalRecord = await saveAnswers({
        medicalRecordCode,
        answers: allQuestionnairesAnswers,
        profile: profile!,
        prescribedDepistages,
        acceptsMarketing,
      })

      resetFunnelStore()
      return medicalRecord
    } catch (e) {
      Sentry.captureException(e, { extra: { medicalRecordCode } })
      throw e
    }
  }

  const handleNext = () => {
    if (
      currentStep === Steps.verticalSelection &&
      selectedVerticals.length === 0
    ) {
      return
    }

    if (
      currentStep === Steps.questionnaires &&
      questionnairesToTake.length === 0
    ) {
      setCurrentStep(Steps.recap)
      return
    }

    setStepHistory((prevStepHistory) => {
      const newStepHistory = [...prevStepHistory]
      newStepHistory.push(currentStep)
      return newStepHistory
    })
    switch (currentStep) {
      case Steps.start:
        return setCurrentStep(Steps.verticalSelection)
      case Steps.verticalSelection: {
        amplitudeTrack("Funnel - Verticals selected", {
          selectedVerticals,
          needsToSignUp: !isSignedIn,
        })
        return setCurrentStep(Steps.profile)
      }
      case Steps.profile:
        amplitudeTrack("Funnel - Profile completed", {
          profile,
          needsToSignUp: !isSignedIn,
        })
        if (selectedVerticals.includes(VerticalName.GENERAL)) {
          return setCurrentStep(Steps.depistagesSelection)
        } else {
          return setCurrentStep(Steps.questionnaires)
        }
      case Steps.depistagesSelection: {
        if (questionnairesToTake!.length === 0) {
          return setCurrentStep(Steps.recap)
        }
        return setCurrentStep(Steps.questionnaires)
      }
      case Steps.questionnaires:
        return setCurrentStep(Steps.recap)
      case Steps.recap: {
        amplitudeTrack("Funnel - Check Logged In Status", {
          needsToSignUp: !isSignedIn,
        })
        gtmTrack("recap_prescription")
        return setCurrentStep(Steps.payment)
      }
    }
  }

  const handleBack = () => {
    if (stepHistory.length === 0) {
      return navigate("/")
    }
    setCurrentStep(stepHistory.pop()!)
  }

  return (
    <QuestionnaireLayout>
      <QuestionnaireLayout.Content>
        {(() => {
          switch (currentStep) {
            case Steps.start:
              return <Start onNext={handleNext} onBack={handleBack} />
            case Steps.verticalSelection:
              return (
                <VerticalsSelection onNext={handleNext} onBack={handleBack} />
              )
            case Steps.profile:
              return (
                <ProfileQuestionnaire
                  profile={profile!}
                  onSubmit={onProfileSubmit}
                  onNext={handleNext}
                  onBack={handleBack}
                />
              )
            case Steps.depistagesSelection:
              return (
                <DepistagesSelection
                  profile={profile!}
                  onNext={handleNext}
                  onBack={handleBack}
                />
              )
            case Steps.questionnaires: {
              return (
                <Questionnaires
                  questionnaires={questionnairesToTake!}
                  profile={profile!}
                  onSubmit={setAllQuestionnairesAnswers}
                  onNext={handleNext}
                  onBack={handleBack}
                />
              )
            }
            case Steps.recap: {
              return (
                <PrescriptionRecap
                  depistagesNames={selectedDepistages.map((d) => d.name)}
                  allAnswers={allQuestionnairesAnswers!}
                  profile={profile!}
                  onSubmit={setPrescribedDepistages}
                  onNext={handleNext}
                  onBack={handleBack}
                  onRestart={() => {
                    setCurrentStep(Steps.verticalSelection)
                    setStepHistory([])
                  }}
                />
              )
            }
            case Steps.payment:
              return (
                <Payment
                  medicalRecordCode={medicalRecordCode}
                  onSaveAnswers={handleSaveAnswers}
                  onBack={handleBack}
                  verticalSelection={selectedVerticals}
                />
              )
          }
        })()}
      </QuestionnaireLayout.Content>
    </QuestionnaireLayout>
  )
}
