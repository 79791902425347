import { z } from "zod"

import type { FormValues } from "@bleu/types/form"

import { ColorectalCancerCurrentSituationOption } from "@bleu/shared/questionnaires"
import { shouldShow } from "@bleu/shared/utils/shouldShow"

import { QuestionTypes, QuestionnairesNames } from "./enums"
import type { BaseQuestionnaireShape } from "./types"

export const colorectalCancerSchema = z.object({
  currentSituation: z
    .nativeEnum(ColorectalCancerCurrentSituationOption)
    .array()
    .min(1),
})

const initialValues: ColorectalCancerFormValues = {
  currentSituation: new Array<ColorectalCancerCurrentSituationOption>(),
}

const QuestionNames = {
  currentSituation: "currentSituation",
} as const

type QuestionName = keyof typeof QuestionNames

export type ColorectalCancerData = z.infer<typeof colorectalCancerSchema>
type ColorectalCancerFormValues = FormValues<ColorectalCancerData>

export type ColorectalCancerQuestionnaireShape = BaseQuestionnaireShape<
  QuestionName,
  ColorectalCancerData,
  ColorectalCancerFormValues
>
export const colorectalCancerQuestionnaire: ColorectalCancerQuestionnaireShape =
  {
    name: QuestionnairesNames.colorectalCancer,
    questions: [
      {
        id: QuestionNames.currentSituation,
        shouldShow,
        type: QuestionTypes.MultiSelect,
        title: "Etes-vous dans l'une des situations suivantes ?",
        options: [
          {
            label:
              "Présence de sang rouge ou noir dans les selles (rectorragies)",
            value: ColorectalCancerCurrentSituationOption.BLOOD_IN_STOOL,
          },
          {
            label:
              "Douleurs abdominales persistantes et d'apparition récente, surtout après 50 ans",
            value: ColorectalCancerCurrentSituationOption.ABDOMINAL_PAIN,
          },
          {
            label:
              "Troubles du transit persistants et d'apparition récente, comme une diarrhée ou une constipation inhabituelle, ou une alternance de ces états",
            value: ColorectalCancerCurrentSituationOption.TRANSIT_DISORDER,
          },
          {
            label:
              "Antécédent personnel de cancer colo-rectal ou d'un (ou plusieurs) adénomes",
            value: ColorectalCancerCurrentSituationOption.PERSONAL_HISTORY,
          },
          {
            label:
              "Un parent au premier degré (père, mère, frère, sœur, enfant) a été atteint d'un cancer colo-rectal ou d'un adénome de plus de 1 cm de diamètre avant 65 ans",
            value: ColorectalCancerCurrentSituationOption.PARENT_HISTORY,
          },
          {
            label: "Familles atteintes de certaines mutations génétiques",
            value: ColorectalCancerCurrentSituationOption.FAMILY_HISTORY,
          },
          {
            label: "Aucune des situations ci-dessus",
            value: ColorectalCancerCurrentSituationOption.NONE,
            unique: true,
          },
        ],
      },
    ],
    schema: colorectalCancerSchema,
    initialValues,
  } as const
