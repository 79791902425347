export const MedicalRecordStatuses = {
  PENDING_PAYMENT: "PENDING_PAYMENT",
  PENDING_PRESCRIPTION: "PENDING_PRESCRIPTION",
  PENDING_LAB_RESULTS: "PENDING_LAB_RESULTS",
  PENDING_LAB_RESULTS_ANALYSIS: "PENDING_LAB_RESULTS_ANALYSIS",
  LAB_RESULTS_ANALYZED: "LAB_RESULTS_ANALYZED",
  CLOSED: "CLOSED",
} as const

export type MedicalRecordStatus = keyof typeof MedicalRecordStatuses

type MedicalRecordStatusInfo = {
  label: string
  order: number
}

export const MEDICAL_RECORD_STATUSES: Record<
  MedicalRecordStatus,
  MedicalRecordStatusInfo
> = {
  PENDING_PAYMENT: {
    label: "Attente paiement",
    order: 1,
  },
  PENDING_PRESCRIPTION: {
    label: "Attente prescription",
    order: 2,
  },
  PENDING_LAB_RESULTS: {
    label: "Attente résultats d'examen",
    order: 3,
  },
  PENDING_LAB_RESULTS_ANALYSIS: {
    label: "Attente analyse résultats",
    order: 4,
  },
  LAB_RESULTS_ANALYZED: {
    label: "Résultats analysés",
    order: 5,
  },
  CLOSED: {
    label: "Archivé",
    order: 6,
  },
}

export const isMedicalRecordStatusBefore = (
  status1: MedicalRecordStatus,
  status2: MedicalRecordStatus,
  strictCheck = false,
) => {
  const parsedStatus1 = MEDICAL_RECORD_STATUSES[status1].order
  const parsedStatus2 = MEDICAL_RECORD_STATUSES[status2].order

  if (strictCheck) {
    return parsedStatus1 < parsedStatus2
  }
  return parsedStatus1 <= parsedStatus2
}
